import React from 'react';
import { connect } from 'react-redux';
import { RootState } from 'reducers';
import { RouteComponentProps, Link } from 'react-router-dom';
import { DeleteIcon, CleanIcon, AddIcon } from 'assets/icons';
import { Globals, Constants, Colors, Socket, SocketEvents, checkPermission, Permissions } from 'utils';
import { ReportService } from 'services';
import { WhiteImage } from 'assets/img';
import moment from 'moment';
import { Tooltip, Pagination, Table, Button, DatePicker, Input } from 'components';

interface Props extends RootState, RouteComponentProps {
	dispatch: any
}

const INITIAL_STATE = {
	since: '',
	until: '',
	search: ''
}

class AdminReportPets extends React.Component<Props> {

	timer: any = undefined;

	state = {
		data: [],
		page: 1,
		last_page: 1,
		header: [
			'Fecha',
			'Mascota',
			'Microchip',
			'Tutor',
			'Documento',
			'Teléfono',
			'Correo',
			'Acción'
		],
		form: INITIAL_STATE
	}

	componentDidMount() {
		this.props.dispatch({
			type: 'SET_HEADER',
			payload: {
				title: 'Mascotas Registradas',
				onBack: null
			}
		});

		this.props.dispatch({
			type: 'SET_SUBHEADER',
			payload: {
				title: ''
			}
		});

		this.load();
	}

	load = async (withoutLoading: boolean = false) => {
		const res: any = await ReportService.admin.getPets({
			withoutLoading,
			page: this.state.page,
			...this.state.form,
			since: this.state.form.since && moment(this.state.form.since).format('YYYY-MM-DD'),
			until: this.state.form.until && moment(this.state.form.until).format('YYYY-MM-DD'),
		});
		this.setState({
			data: res.pets.rows,
			last_page: res.pets.count
		});
	}

	reset = () => {
		this.setState({
			form: INITIAL_STATE,
			page: 1
		},() => this.load(true));
	}

	change = (e: any,callback = () => {}) => {
		this.setState({
			page: 1,
			form: {
				...this.state.form,
				[e.target.name]: e.target.value
			}
		},callback);
	}

	delete = async (e: any, item: any) => {
		e.preventDefault();
		Globals.confirm('¿Desea eliminar la mascota?',async () => {
			await ReportService.admin.deletePets({
				pet_id: item.id
			});
			this.load(true);
		});
	}
	
	render() {
		return (
			<div id="admin-report-patients">
				<div className="row row-filter">
					<div className="col-md-4">
						<Input
							value={ this.state.form.search }
							name="search"
							label="Filtrar"
							placeholder="Buscar por mascota/tutor/cédula/microchip"
							className="input-table"
							onChange={ (e: any) => {
								clearTimeout(this.timer);
								this.timer = setTimeout(() => {
									this.load(true);
								},1000);
								this.change(e); 
							} } />
					</div>
					<div className="col-md-3">
						<DatePicker
							label="Desde"
							placeholder="Desde"
							onChange={ (text: string) => {
								this.change({
									target: {
										value: text,
										name: 'since'
									}
								},() => this.load(true));
							} }
							value={ this.state.form.since }
						/>
					</div>
					<div className="col-md-3">
						<DatePicker
							label="Hasta"
							placeholder="Hasta"
							onChange={ (text: string) => {
								this.change({
									target: {
										value: text,
										name: 'until'
									}
								},() => this.load(true));
							} }
							value={ this.state.form.until }
						/>
					</div>
					<div className="col-md-2">
						{
							this.state.form != INITIAL_STATE && (
								<button className="btn btn-clean" onClick={ () => this.reset() }>
									<img src={ CleanIcon } />
								</button>
							)
						}
					</div>
				</div>

				<Table title="Practicantes Registrados" data={ this.state.data.length } rightAlign={[ 7 ]} header={ this.state.header }>
					{ this.state.data.map((i: any,index: number) => {
						return (
							<tr key={ index }>
								<td>{ moment(i.created_at).format('DD-MM-YYYY') }</td>
								<td>{ i.name }</td>
								<td>{ i.chip_code || '-' }</td>
								<td>{ i.user?.person?.fullName }</td>
								<td>{ i.user?.person?.document }</td>
								<td>{ i.user?.person?.phone }</td>
								<td>{ i.user?.email }</td>
								<td className="vertical-center text-right">
									{
										checkPermission(Constants.MODULES.REPORTS,'delete',this.props.user) && (
											<Tooltip title="Eliminar">
												<a className="link-icon" href="#" onClick={ (e: any) => this.delete(e,i) }>
													<img src={ DeleteIcon } />
												</a>
											</Tooltip>
										)
									}
								</td>
							</tr>
						)
					}) }
				</Table>

				<Pagination 
					pages={ this.state.last_page } 
					active={ this.state.page }
					onChange={ async (page: number) => {
						await this.setState({
							page: page
						});
						this.load();
					} } />
			</div>
		)
	}
}

export default connect((state: RootState) => {
	return {
		user: state.user
	}
})(AdminReportPets);