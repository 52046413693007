import { Api } from 'utils';

class ServiceManagementService {

	static admin = {
		get: async (params?: any) => Api.createResponse('admin/service-management',params),
		getHealthCenters: async (params?: any) => Api.createResponse('admin/service-management/health-centers',params),
		getPetsDocument: async (params?: any) => Api.createResponse('admin/service-management/pets-document',params),
		getServices: async (params?: any) => Api.createResponse('admin/service-management/services',params),
		getServicesPet: async (params?: any) => Api.createResponse('admin/service-management/services-pet',params),
		delete: async (params?: any) => Api.createResponse('admin/service-management/delete',params),
		save: async (params?: any) => Api.createResponse('admin/service-management/save',params),
		saveUses: async (params?: any) => Api.createResponse('admin/service-management/save-uses',params),
	}

	static practicing = {
		get: async (params?: any) => Api.createResponse('practicing/service-management',params),
		changeStatus: async (params?: any) => Api.createResponse('practicing/service-management/change-status',params),
		print: async (params?: any) => Api.createResponse('practicing/service-management/print',params),
	}

}

export default ServiceManagementService;