import React from 'react';
import { Input, Button, PaperClip, Select, CheckboxTable, DatePicker, Modal, Textarea, SelectSearch } from 'components';
import { RootState } from 'reducers';
import { RouteComponentProps } from 'react-router-dom';
import { Globals, Constants, Socket, SocketEvents } from 'utils';
import { WhiteImage2 } from 'assets/img';
import moment from 'moment';
import { EditBlueIcon } from 'assets/icons';
import { ServiceManagementService } from 'services';

interface Props {
	onClose?: any;
	setService?: any;
	service: any;
}

class ModalSearchPatient extends React.Component<Props> {

	state = {
		form: {
			pet_id: '',
			service_id: '',
			comments: '',
			consultation_type_id: '',
			health_center_id: '',
			practicing_id: ''
		},
		pets: [],
		services: [],
		user: null,
		consultation_types: [],
		health_centers: [],
		practicing: [],
		plan_user: null,
		plans: []
	}

	componentDidMount() {
		if (this.props.service?.service?.id) {
			this.loadHealthCenters(this.props.service?.service?.id);
			this.setState({
				form: {
					...this.state.form,
					practicing_id: this.props.service?.practicing_id || '',
					health_center_id: this.props.service?.health_center_id || '',
					consultation_type_id: this.props.service?.consultation_type_id || '',
					comments: this.props.service?.comments
				}
			});
		}
		else {
			this.load();
		}
	}

	load = async () => {
		const res: any = await ServiceManagementService.admin.getPetsDocument();
		this.setState({
			pets: res.pets
		});
	}

	loadServices = async () => {
		const res: any = await ServiceManagementService.admin.getServicesPet({
			pet_id: this.state.form.pet_id
		});
		this.setState({
			services: res.services || [],
			user: res.user,
			plan_user: res.plan_user || null,
			plans: res.plans || []
		});
	}

	selectService = (e: any, item: any) => {
		e.preventDefault();
		this.props.setService(item);
		this.loadHealthCenters(item?.service?.id);
	}

	loadHealthCenters = async (service_id: number) => {
		const res: any = await ServiceManagementService.admin.getHealthCenters({
			service_id
		});
		this.setState({
			health_centers: res.health_centers,
			practicing: res.practicings,
			consultation_types: res.consultation_types
		});
	}

	submit = async (e: any) => {
		e.preventDefault();
		await ServiceManagementService.admin.save({
			purchased_service_id: this.props.service?.id,
			health_center_id: this.state.form.health_center_id,
			consultation_type_id: this.state.form.consultation_type_id,
			practicing_id: this.state.form.practicing_id,
			comments: this.state.form.comments
		});
		this.props.onClose();
		Globals.showSuccess("Se ha guardado correctamente el servicio");
	}

	change = (e: any,callback = () => {}) => {
		this.setState({
			page: 1,
			form: {
				...this.state.form,
				[e.target.name]: e.target.value
			}
		},callback);
	}

	saveUses = async () => {
		if (this.state.services.find((i: any) => i.quantity_used === null || i.quantity_used === '')) {
			Globals.showError("Debe ingresar la cantidad utilizada");
			return false;
		}

		if (this.state.services.find((i: any) => i.quantity_used < 0)) {
			Globals.showError("La cantidad utilizada no puede ser menor a 0");
			return false;
		}

		if (this.state.services.find((i: any) => i.quantity_used > i.quantity_of_uses)) {
			Globals.showError("La cantidad utilizada no puede ser mayor a la cantidad de usos");
			return false;
		}

		await ServiceManagementService.admin.saveUses({
			services: JSON.stringify(this.state.services.map((i: any) => {
				return {
					id: i.id,
					quantity_used: i.quantity_used || 0
				}
			}))
		});
		Globals.showSuccess("Se han guardado correctamente los cambios");
	}
	
	render() {
		const pet: any = this.state.pets.find((i: any) => i.id == this.state.form.pet_id);
		const user: any = this.state.user;
		const service: any = this.props.service;
		const plan_user: any = this.state.plan_user;

		return (
			<div id="modal-search-patient">
				{
					this.props.service != null ? (
						<form className="row" onSubmit={ this.submit }>
							<div className="col-md-6">
								<Select
									placeholder="Seleccionar"
									label="Servicio Solicitado"
									name="service_id"
									onChange={ (e: any) => {} }
									value={ service?.service?.id }
									disabled={ true }
									options={ [{
										value: service?.service?.id,
										label: service?.service?.name
									}] } />
							</div>
							<div className="col-md-6">
								<Select
									placeholder="Seleccionar"
									label="Nombre del Practicante"
									name="practicing_id"
									onChange={ (e: any) => this.change(e) }
									value={ this.state.form.practicing_id }
									options={ this.state.practicing.map((item: any) => {
										return {
											value: item.id,
											label: item.fullName
										}
									}) } />
							</div>
							{
								service?.service_speciality_id != null && (
									<div className="col-md-6">
										<Select
											disabled={ true }
											placeholder="Seleccionar"
											label="Especialidad o Estudio Solicitado"
											name="health_center_id"
											onChange={ (e: any) => {} }
											value={ service?.service_speciality_id }
											options={ [{
												value: service?.service_speciality_id,
												label: service?.service_speciality?.treatment?.name || service?.service_speciality?.medical_study?.name || service?.service_speciality?.medical_image?.name
											}] } />
									</div>
								)
							}
							<div className="col-md-6">
								<Select
									placeholder="Seleccionar"
									label="Unidad o Especialidad (Opcional)"
									name="health_center_id"
									onChange={ (e: any) => this.change(e) }
									value={ this.state.form.health_center_id }
									options={ this.state.health_centers.map((item: any) => {
										return {
											value: item.id,
											label: item.name
										}
									}) } />
							</div>
							{/*<div className="col-md-6">
								<Select
									placeholder="Seleccionar"
									label="Tipo de Consulta (Opcional)"
									name="consultation_type_id"
									onChange={ (e: any) => this.change(e) }
									value={ this.state.form.consultation_type_id }
									options={ this.state.consultation_types.map((item: any) => {
										return {
											value: item.id,
											label: item.name
										}
									}) } />
							</div>*/}
							<div className="col-md-12">
								<Textarea
									rows={ 3 }
									name="comments" 
									label="Observaciones (Opcional)"
									onChange={ this.change }
				                    value={ this.state.form.comments } />
							</div>
							<div className="col-md-12">
								<div className="text-center">
									<Button type="submit">
										Asignar
									</Button>
								</div>
							</div>
						</form>
					) : (
						<React.Fragment>
							<SelectSearch
								label="Mascota"
								onChange={ (pet: any) => {
									this.setState({
										form: {
											...this.state.form,
											pet_id: pet?.value
										}
									},() => this.loadServices());
								} }
								color="white"
								searchRight
								value={ pet ? {
									value: pet.id,
									label: pet.name
								} : null }
								options={ this.state.pets.map((i: any) => {
									return {
										value: i.id,
										label: i.name + (i.chip_code? (' - ' + i.chip_code) : '') + (i.user ? (' - ' + i.user?.person?.name + ' ' + i.user?.person?.lastname) : '')
									}
								}) } />
							{
								user != null && (
									<React.Fragment>
										<p className="label">Tutor</p>
										<p className="value">{ user?.fullName }</p>
										{
											[plan_user?.plan?.name || null,...this.state.plans?.map((i: any) => i.plan?.name || null)].filter((i: any) => i != null).length > 0 && (
												<React.Fragment>
													<p className="label">Planes suscritos</p>
													<p className="value">{ [plan_user?.plan?.name || null,...this.state.plans?.map((i: any) => i.plan?.name || null)].filter((i: any) => i != null).join(', ') }</p>
												</React.Fragment>
											)
										}
										<p className="label">Servicios Activos</p>
										{
											this.state.services.length > 0 && (
												<div className="row row-service">
													<div className="col-md-4"></div>
													<div className="col-md-8 row">
														<div className="col-4">
															<p className="quantity-label">Cantidad de Usos</p>
														</div>
														<div className="col-4">
															<p className="quantity-label">Cantidad Utilizada</p>
														</div>
														<div className="col-4">
															<p className="quantity-label">Cantidad Disponible</p>
														</div>
													</div>
													{/*<div className="col-md-1 text-right"></div>*/}
												</div>
											)
										}
										{
											this.state.services.map((item: any,index: number) => {
												return (
													<div className="row row-service">
														<div className="col-md-4">
															<p className="service">{ item?.service?.name }</p>
														</div>
														<div className="col-md-8 row">
															<div className="col-4">
																<p className="quantity-item">{ item?.unlimited ? 'Ilimitado' : item?.quantity_of_uses }</p>
															</div>
															<div className="col-4">
																<Input
																	disabled={ item?.unlimited }
																	type="number"
																	className="input-quantity-uses"
																	color="white"
																	value={ item?.unlimited ? item.quantity_used_unlimited : item.quantity_used }
																	name="quantity"
																	onChange={ (e: any) => {
																		let services: any = [...this.state.services];
																		services[index].quantity_used = e.target.value;
																		this.setState({
																			services
																		});
																	} } />
															</div>
															<div className="col-4">
																<p className="quantity-item">{ item?.unlimited ? 'Ilimitado' : (item?.quantity_of_uses - item?.quantity_used) }</p>
															</div>
														</div>
													</div>
												)
											})
										}
										{
											this.state.services.length == 0 && (
												<div className="no-items">
													<p>El paciente no ha solicitado servicios</p>
												</div>
											)
										}

										<div className="text-center">
											<Button type="button" className="btn-save-uses" onClick={ () => this.saveUses() }>
												Guardar
											</Button>
										</div>
									</React.Fragment>
								)
							}
						</React.Fragment>
					)
				}
			</div>
		)
	}
}

export default ModalSearchPatient;