import React from 'react';
import { Input, Button, PaperClip, Select, CheckboxTable, DatePicker, Modal, SelectSearch, Textarea } from 'components';
import { HealthCenterService } from 'services';
import { RootState } from 'reducers';
import { RouteComponentProps } from 'react-router-dom';
import { Globals, Constants, Socket, SocketEvents } from 'utils';
import { WhiteImage2 } from 'assets/img';
import moment from 'moment';
import { AddIcon, CloseIcon, TimeIcon } from 'assets/icons';

interface Props {
	onClose?: any;
	item?: any;
}

class ModalCreate extends React.Component<Props> {

	state = {
		form: {
			name: '',
			description: '',
			address: ''
		},
		users: [],
		schedules: [{
			since: '',
			until: ''
		}],
		days: [],
		health_users: [],
		services: [],
		health_services: []
	}

	componentDidMount() {
		if (this.props.item) {
			this.setState({
				form: {
					...this.state.form,
					name: this.props.item?.name || '',
					address: this.props.item?.address || '',
					description: this.props.item?.description || ''
				},
				health_users: this.props.item?.center_users?.map((i: any) => i.user_id) || [],
				health_services: this.props.item?.center_services?.map((i: any) => i.service_id) || [],
				schedules: this.props.item?.schedules?.map((i: any) => {
					return {
						since: moment(i.since,'HH:mm:ss').toDate(),
						until: moment(i.until,'HH:mm:ss').toDate()
					}
				}) || [],
			});
		}

		let days = [];
		for (let i = 1; i <= 7; i++) {
			let value = false;
			const _item = this.props.item?.days?.map((item: any) => item.day).indexOf(i);
			if (_item != null && _item != -1) {
				value = true;
			}
			days.push(value);
		}
		this.setState({
			days
		});

		this.loadUsers();
	}

	resetDays = () => {
		let days = [];
		for (let i = 1; i <= 7; i++) {
			days.push(false);
		}
		this.setState({
			days
		});
	}

	loadUsers = async () => {
		const res: any = await HealthCenterService.admin.getUsers();
		this.setState({
			users: res.users || [],
			services: res.services || []
		});
	}

	submit = async (e: any) => {
		e.preventDefault();
		if (this.props.item != null) {
			await HealthCenterService.admin.update({
				health_center_id: this.props.item?.id,
				...this.state.form,
				users: JSON.stringify(this.state.health_users),
				services: JSON.stringify(this.state.health_services),
				days: JSON.stringify(this.state.days.map((i: any,index: number) => {
					return {
						value: i,
						index: index + 1
					}
				}).filter((i: any) => i.value == true).map((i: any) => i.index)),
				schedules: JSON.stringify(this.state.schedules.map((item: any) => {
					return {
						since: moment(item.since,'HH:mm').format('HH:mm:ss'),
						until: moment(item.until,'HH:mm').format('HH:mm:ss'),
					}
				})),
			});
			Globals.showSuccess("Se ha modificado la unidad/centro de salud correctamente");
			this.props.onClose();
		}
		else {
			await HealthCenterService.admin.create({
				...this.state.form,
				users: JSON.stringify(this.state.health_users),
				services: JSON.stringify(this.state.health_services),
				days: JSON.stringify(this.state.days.map((i: any,index: number) => {
					return {
						value: i,
						index: index + 1
					}
				}).filter((i: any) => i.value == true).map((i: any) => i.index)),
				schedules: JSON.stringify(this.state.schedules.map((item: any) => {
					return {
						since: moment(item.since,'HH:mm').format('HH:mm:ss'),
						until: moment(item.until,'HH:mm').format('HH:mm:ss'),
					}
				})),
			});
			Globals.showSuccess("Se ha creado la unidad/centro de salud correctamente");
			this.props.onClose();
		}
	}

	change = (e: any,callback = () => {}) => {
		this.setState({
			form: {
				...this.state.form,
				[e.target.name]: e.target.value
			}
		},callback);
	}

	deleteUser = (index: number) => {
		let health_users: any = [...this.state.health_users];
		health_users.splice(index,1);
		this.setState({
			health_users
		});
	}

	deleteService = (index: number) => {
		let health_services: any = [...this.state.health_services];
		health_services.splice(index,1);
		this.setState({
			health_services
		});
	}

	addSchedule = () => {
		let schedules: any = [...this.state.schedules];
		schedules.push({
			since: '',
			until: ''
		})
		this.setState({
			schedules
		});
	}

	changeSchedule = (index: number, e: any) => {
		let schedules: any = [...this.state.schedules];
		schedules[index][e.target.name] = e.target.value;
		this.setState({
			schedules
		});
	}

	deleteSchedule = (index: number) => {
		let schedules: any = [...this.state.schedules];
		schedules.splice(index,1);
		this.setState({
			schedules
		});
	}
	
	render() {
		const health_users: any = this.state.health_users;
		const health_services: any = this.state.health_services;

		return (
			<div id="modal-create-health-center">
      			<form className="row" onSubmit={ this.submit }>
      				<div className="col-12">
      					<div className="row row-form">
		      				<div className="col-md-6">
		      					<Input
									color="white"
									value={ this.state.form.name }
									name="name" 
									label="Nombre"
									onChange={ this.change } />
		      				</div>
		      				<div className="col-md-6">
		      					<Textarea
		      						rows={ 3 }
									color="white"
									value={ this.state.form.description }
									name="description" 
									label="Descripción"
									onChange={ this.change } />
		      				</div>
		      				<div className="col-md-6">
		      					<SelectSearch
									label="Practicantes asociados (Opcional)"
									onChange={ (user: any) => {
										let health_users: any = [...this.state.health_users];
										health_users.push(user?.value);
										this.setState({
											health_users,
											schedules: []
										},() => this.resetDays());
									} }
									color="white"
									searchRight
									value={ null }
									options={ this.state.users.filter((i: any) => health_users.indexOf(i.id) == -1).map((i: any) => {
										return {
											value: i.id,
											label: i.fullName
										}
									}) } />
								{
			      					this.state.health_users.length > 0 && (
										<div className="col-md-12">
					      					<div className="row row-schedules">
												{
													this.state.health_users.map((item: any, index: number) => {
														const user: any = this.state.users.find((i: any) => i.id == item);

														return (
															<div className="col-md-12">
																<div className="item-service">
																	<a href="#" className="trash-container" onClick={ (e: any) => {
																		e.preventDefault();
																		this.deleteUser(index);
																	} }>
																		<img src={ CloseIcon } />
																	</a>
																	<p>{ user?.fullName }</p>
																</div>
															</div>
														)
													})
												}
											</div>
					      				</div>
			      					)
			      				}
		      				</div>
		      				<div className="col-md-6">
		      					<SelectSearch
									label="Servicios que presta (Opcional)"
									onChange={ (user: any) => {
										let health_services: any = [...this.state.health_services];
										health_services.push(user?.value);
										this.setState({
											health_services
										});
									} }
									color="white"
									searchRight
									value={ null }
									options={ this.state.services.filter((i: any) => health_services.indexOf(i.id) == -1).map((i: any) => {
										return {
											value: i.id,
											label: i.name
										}
									}) } />
								{
			      					this.state.health_services.length > 0 && (
										<div className="col-md-12">
					      					<div className="row row-schedules">
												{
													this.state.health_services.map((item: any, index: number) => {
														const service: any = this.state.services.find((i: any) => i.id == item);

														return (
															<div className="col-md-12">
																<div className="item-service">
																	<a href="#" className="trash-container" onClick={ (e: any) => {
																		e.preventDefault();
																		this.deleteService(index);
																	} }>
																		<img src={ CloseIcon } />
																	</a>
																	<p>{ service?.name }</p>
																</div>
															</div>
														)
													})
												}
											</div>
					      				</div>
			      					)
			      				}
		      				</div>
		      				<div className="col-md-12">
		      					<Textarea
		      						rows={ 3 }
									color="white"
									value={ this.state.form.address }
									name="address" 
									label="Dirección"
									onChange={ this.change } />
		      				</div>
      					</div>
      				</div>
      				{
      					this.state.health_users.length == 0 && (
      						<div className="col-md-12">
		      					<div className="row-form">
			      					<h3>Horario Laboral (En caso de no asignarse practicantes a esta unidad)</h3>
			      					<div className="container-add-schedule">
			      						<h4>Días Laborales</h4>
			      					</div>
			      					<div className="container-days">
				      					{
				      						this.state.days.map((item: any, index: number) => {
				      							return (
				      								<div className="item-day" onClick={ () => {
				      									let days: any = [...this.state.days];
				      									days[index] = !days[index];
				      									this.setState({
				      										days
				      									});
				      								} }>
				      									<div className={ `check-day ${ item ? 'active' : '' }` }></div>
				      									<p>{ moment().weekday(index).format('dddd') }</p>
				      								</div>
				      							)
				      						})
				      					}
				      				</div>
			      					<div className="container-add-schedule">
			      						<h4>Horario Laboral</h4>
			      						<Button type="button" onClick={ () => this.addSchedule() }>
			      							<img src={ AddIcon } />
			      						</Button>
			      					</div>
			      					{
			      						this.state.schedules.map((item: any, index: number) => {
			      							return (
			      								<div className="row">
			      									<div className="col-md-5">
														<DatePicker
															label="Desde"
															showTimeSelect={ true }
															showTimeSelectOnly={ true }
															timeCaption="Hora"
															calendarIcon={ TimeIcon }
															dateFormat="h:mm aa"
															onChange={ (text: string) => {
																this.changeSchedule(index,{
																	target: {
																		name: 'since',
																		value: text
																	}
																});
															} }
															value={ item.since }
														/>
													</div>
													<div className="col-md-5">
														<DatePicker
															label="Hasta"
															showTimeSelect={ true }
															showTimeSelectOnly={ true }
															timeCaption="Hora"
															calendarIcon={ TimeIcon }
															dateFormat="h:mm aa"
															onChange={ (text: string) => {
																this.changeSchedule(index,{
																	target: {
																		name: 'until',
																		value: text
																	}
																});
															} }
															value={ item.until }
														/>
													</div>
													<div className="col-md-2">
														<a href="#" className="trash-container trash-schedule" onClick={ (e: any) => {
															e.preventDefault();
															this.deleteSchedule(index);
														} }>
															<img src={ CloseIcon } />
														</a>
													</div>
			      								</div>
			      							)
			      						})
			      					}
			      				</div>
		      				</div>
      					)
      				}
					<div className="col-12">
						<div className="row row-buttons">
							<div className="col-md-12 text-center">
								<Button type="submit">
									Guardar
								</Button>
							</div>
						</div>
					</div>
				</form>
			</div>
		)
	}
}

export default ModalCreate;