import React from 'react';
import { Input, Button, PaperClip, Select, CheckboxTable, DatePicker, Modal, Textarea, SelectSearch } from 'components';
import { RememberService } from 'services';
import { RootState } from 'reducers';
import { RouteComponentProps } from 'react-router-dom';
import { Globals, Constants, Socket, SocketEvents } from 'utils';
import moment from 'moment';
import { connect } from 'react-redux';

interface Props extends RootState {
	onClose?: any;
	item?: any;
	remember_types?: any;
}

class ModalCreate extends React.Component<Props> {

	state = {
		form: {
			remember_type_id: '',
		    comments: '',
		    date: '',
		    next_date: '',
		    lot: '',
		    medicine: '',
		    pet_id: ''
		},
		pet: null,
		pets: []
	}

	componentDidMount() {
		if (this.props.item) {
			const item = this.props.item;

			this.setState({
				form: {
					...this.state.form,
					remember_type_id: item?.remember_type_id || '',
				    comments: item?.comments || '',
				    date: item?.date ? moment(item?.date).toDate() : '',
				    next_date: item?.next_date ? moment(item?.next_date).toDate() : '',
				    lot: item?.lot || '',
				    medicine: item?.medicine || '',
				    pet_id: item?.pet_id || ''
				}
			});
		}

		this.loadPets();
	}

	loadPets = async () => {
		const res: any = await RememberService.practicing.getPets();
		this.setState({
			pets: res.pets || []
		},() => {
			if (this.props.item) {
				const pet = res.pets.find((i: any) => i.id == this.props.item?.pet_id);

				if (pet) {
					this.setState({
						pet: {
							label: `${ pet?.name } - ${ pet?.user?.person?.name || '' } ${ pet?.user?.person?.lastname || '' }`,
							value: pet.id
						}
					});
				}
			}
		});
	}

	submit = async (e: any) => {
		e.preventDefault();

		if (!this.state.form.remember_type_id) {
	      Globals.showError("El campo tipo de cuidado es requerido");
	      return;
	    }

	    if (!this.state.pet) {
	      Globals.showError("El campo mascota es requerido");
	      return;
	    }

	    if (!this.state.form.date) {
	      Globals.showError("El campo fecha es requerido");
	      return;
	    }

	    const res = await RememberService.practicing[this.props.item?.id ? 'update' : 'create']({
	      remember_id: this.props.item?.id,
	      practicing_id: this.props.user?.id,
	      ...this.state.form,
	      // @ts-ignore
	      pet_id: this.state.pet?.value,
	      date: this.state.form.date && moment(this.state.form.date,'DD-MM-YYYY').format('YYYY-MM-DD'),
	      next_date: this.state.form.next_date && moment(this.state.form.next_date,'DD-MM-YYYY').format('YYYY-MM-DD'),
	    });
	    Globals.showSuccess("Se ha guardado el recordatorio correctamente");
	    this.props.onClose();
	}

	change = (e: any,callback = () => {}) => {
		this.setState({
			page: 1,
			form: {
				...this.state.form,
				[e.target.name]: e.target.value
			}
		},callback);
	}
	
	render() {
		const pet: any = this.state.pet;

		return (
			<form id="modal-create-remember" className="row" onSubmit={ this.submit }>
      			<div className="col-md-6">
					<Select
						label="Tipo de Cuidado"
						name="remember_type_id"
						placeholder="Seleccionar"
						onChange={ this.change }
						value={ this.state.form.remember_type_id }
						options={ this.props.remember_types.map((item: any) => {
							return {
								value: item.id,
								label: item.name
							}
						}) } />
				</div>
				<div className="col-md-6">
					<SelectSearch
						placeholder="Seleccione"
						label="Mascota"
						onChange={ (pet: any) => {
							this.setState({
								pet
							});
						} }
						color="white"
						searchRight
						value={ pet ? {
							value: pet?.value,
							label: pet?.label
						} : null }
						options={ (this.state.pets || []).map((item: any) => {
							return {
								label: `${ item?.name } - ${ item?.user?.person?.name || '' } ${ item?.user?.person?.lastname || '' }`,
								value: item.id
							}
						}) } />
				</div>
				<div className="col-md-6">
					<DatePicker
						label="Fecha"
						placeholder="Fecha"
						onChange={ (text: string) => {
							this.change({
								target: {
									value: text,
									name: 'date'
								}
							});
						} }
						maxDate={ moment().toDate() }
						value={ this.state.form.date }
					/>
				</div>
				<div className="col-md-6">
					<DatePicker
						label="Próxima"
						placeholder="Próxima"
						onChange={ (text: string) => {
							this.change({
								target: {
									value: text,
									name: 'next_date'
								}
							});
						} }
						minDate={ moment().add(1,'day').toDate() }
						value={ this.state.form.next_date }
					/>
				</div>
				<div className="col-md-6">
					<Input
						color="white"
						value={ this.state.form.medicine }
						name="medicine" 
						label="Medicamento"
						onChange={ this.change } />
				</div>
				<div className="col-md-6">
					<Input
						color="white"
						value={ this.state.form.lot }
						name="lot" 
						label="Lote"
						onChange={ this.change } />
				</div>
				<div className="col-md-12">
					<Textarea
						rows={ 3 }
						color="white"
						value={ this.state.form.comments }
						name="comments" 
						placeholder="Comentarios"
						onChange={ this.change } />
				</div>
				<div className="col-md-12 text-center">
					<Button type="submit">
						Guardar
					</Button>
				</div>
			</form>
		)
	}
}

export default connect((state: RootState) => {
	return {
		user: state.user
	}
})(ModalCreate);