import React from 'react';
import { connect } from 'react-redux';
import { RootState } from 'reducers';
import { RouteComponentProps } from 'react-router-dom';
import { Table, Pagination, Button } from 'components';
import Moderator from './moderator/moderator';
import Practicing from './practicing/practicing';
import Sellers from './sellers/sellers';
import { ModeratorIcon, PracticingIcon, SellerIcon } from 'assets/icons';
import { ProfileService } from 'services';

interface Props extends RootState, RouteComponentProps {
	dispatch: any
}

const TABS = {
	MODERATOR: 1,
	PRACTICING: 2,
	SELLER: 3
}

class Users extends React.Component<Props> {

	state = {
		tab: TABS.MODERATOR
	}

	componentDidMount() {
		this.props.dispatch({
			type: 'SET_HEADER',
			payload: {
				title: 'Usuarios',
				onBack: null
			}
		});

		this.props.dispatch({
			type: 'SET_SUBHEADER',
			payload: {
				title: ''
			}
		});
	}

	setTab = (tab: number) => {
		this.setState({
			tab
		});
	}
	
	render() {
		return (
			<div id="admin-users">
				<div className="row row-tabs">
					<div className="col-md-4 text-center">
						<Button onClick={ () => this.setTab(TABS.MODERATOR) } className={ this.state.tab == TABS.MODERATOR ? 'active' : '' }>
							<img src={ ModeratorIcon } />
							<div>
								Moderadores
							</div>
						</Button>
					</div>
					<div className="col-md-4 text-center">
						<Button onClick={ () => this.setTab(TABS.PRACTICING) } className={ this.state.tab == TABS.PRACTICING ? 'active' : '' }>
							<img src={ PracticingIcon } />
							<div>
								Practicantes
							</div>
						</Button>
					</div>
					<div className="col-md-4 text-center">
						<Button onClick={ () => this.setTab(TABS.SELLER) } className={ this.state.tab == TABS.SELLER ? 'active' : '' }>
							<img src={ SellerIcon } />
							<div>
								Vendedores
							</div>
						</Button>
					</div>
				</div>

				{
					this.state.tab == TABS.MODERATOR && (
						<React.Fragment>
							{/*
							// @ts-ignore */}
							<Moderator
								history={ this.props.history }
							/>
						</React.Fragment>
					)
				}

				{
					this.state.tab == TABS.PRACTICING && (
						<React.Fragment>
							{/*
							// @ts-ignore */}
							<Practicing
								history={ this.props.history }
							/>
						</React.Fragment>
					)
				}

				{
					this.state.tab == TABS.SELLER && (
						<React.Fragment>
							{/*
							// @ts-ignore */}
							<Sellers
								history={ this.props.history }
							/>
						</React.Fragment>
					)
				}
			</div>
		)
	}
}

export default connect()(Users);