import React from 'react';
import { connect } from 'react-redux';
import { RootState } from 'reducers';
import { RouteComponentProps, Link } from 'react-router-dom';
import { CleanIcon, PrintIcon } from 'assets/icons';
import { Globals, Constants, Colors, Print } from 'utils';
import { ReportService } from 'services';
import { WhiteImage } from 'assets/img';
import { Input, Pagination, Table, DatePicker, Tooltip } from 'components';
import moment from 'moment';

interface Props extends RootState, RouteComponentProps {
	dispatch: any
}

const INITIAL_STATE = {
	since: '',
	until: '',
	search: ''
}

class AdminReportFinancing extends React.Component<Props> {

	timer: any = undefined;

	state = {
		data: [],
		page: 1,
		last_page: 1,
		header: [
			'Fecha pago inicial',
			'Cirugía',
			'Paciente',
			'Costo Total Cirugía',
			'Monto Financiado',
			'Monto Pagado de cuotas',
			'Acciones'
		],
		form: INITIAL_STATE,
		plans: []
	}

	componentDidMount() {
		this.props.dispatch({
			type: 'SET_HEADER',
			payload: {
				title: 'Reporte de Pago por cuotas',
				onBack: null
			}
		});

		this.props.dispatch({
			type: 'SET_SUBHEADER',
			payload: {
				title: ''
			}
		});

		this.load();
	}

	load = async (withoutLoading: boolean = false) => {
		const res: any = await ReportService.admin.getFinancing({
			withoutLoading,
			page: this.state.page,
			...this.state.form,
			since: this.state.form.since && moment(this.state.form.since).format('YYYY-MM-DD'),
			until: this.state.form.until && moment(this.state.form.until).format('YYYY-MM-DD'),
		});
		this.setState({
			data: res.financing.rows,
			last_page: res.financing.count
		});
	}

	reset = () => {
		this.setState({
			form: INITIAL_STATE,
			page: 1
		},() => this.load(true));
	}

	change = (e: any,callback = () => {}) => {
		this.setState({
			page: 1,
			form: {
				...this.state.form,
				[e.target.name]: e.target.value
			}
		},callback);
	}

	print = async (e: any, item: any) => {
		e.preventDefault();
		const res: any = await ReportService.admin.printFinancing({
			financing_id: item.id
		});
		Print(Constants.BASE_STORAGE + res.url);
	}
	
	render() {
		return (
			<div id="admin-report-financing">
				<div className="row row-filter">
					<div className="col-md-3">
						<Input
							value={ this.state.form.search }
							name="search"
							label="Nombre del Usuario"
							placeholder="Buscar"
							className="input-table"
							onChange={ (e: any) => {
								clearTimeout(this.timer);
								this.timer = setTimeout(() => {
									this.load(true);
								},1000);
								this.change(e); 
							} } />
					</div>
					<div className="col-md-3">
						<DatePicker
							label="Desde"
							placeholder="Desde"
							onChange={ (text: string) => {
								this.change({
									target: {
										value: text,
										name: 'since'
									}
								},() => this.load(true));
							} }
							value={ this.state.form.since }
						/>
					</div>
					<div className="col-md-3">
						<DatePicker
							label="Hasta"
							placeholder="Hasta"
							onChange={ (text: string) => {
								this.change({
									target: {
										value: text,
										name: 'until'
									}
								},() => this.load(true));
							} }
							value={ this.state.form.until }
						/>
					</div>
					<div className="col-md-3">
						{
							this.state.form != INITIAL_STATE && (
								<button className="btn btn-clean" onClick={ () => this.reset() }>
									<img src={ CleanIcon } />
								</button>
							)
						}
					</div>
				</div>

				<Table title="Reporte de Pago por cuotas" data={ this.state.data.length } rightAlign={[ 6 ]} header={ this.state.header }>
					{ this.state.data.map((i: any,index: number) => {
						return (
							<tr key={ index }>
								<td>{ i.created_at ? moment(i.created_at).format('DD/MM/YYYY') : '' }</td>
								<td>{ i.surgerie?.name }</td>
								<td>{ i.user?.person?.name + ' ' + i.user?.person?.lastname }</td>
								<td>{ Globals.formatMiles(i.amount) }</td>
								<td>{ Globals.formatMiles(i.amount - i.initial_payment) }</td>
								<td>{ Globals.formatMiles(i.paid - i.initial_payment) }</td>
								<td className="vertical-center text-right">
									<Tooltip title="Descargar">
										<a className="link-icon" href="#" onClick={ (e: any) => this.print(e,i) }>
											<img src={ PrintIcon } />
										</a>
									</Tooltip>
								</td>
							</tr>
						)
					}) }
				</Table>

				<Pagination 
					pages={ this.state.last_page } 
					active={ this.state.page }
					onChange={ async (page: number) => {
						await this.setState({
							page: page
						});
						this.load();
					} } />
			</div>
		)
	}
}

export default connect((state: RootState) => {
	return {
		user: state.user
	}
})(AdminReportFinancing);