import { Api } from 'utils';

class RememberService {
	
	static practicing = {
		get: async (params?: any) => Api.createResponse('practicing/remember/',params),
		create: async (params?: any) => Api.createResponse('practicing/remember/create',params),
		update: async (params?: any) => Api.createResponse('practicing/remember/update',params),
		delete: async (params?: any) => Api.createResponse('practicing/remember/delete',params),
		getTypes: async (params?: any) => Api.createResponse('practicing/remember/types',params),
		getPets: async (params?: any) => Api.createResponse('practicing/remember/pets',params)
	}

}

export default RememberService;