import { Api } from 'utils';

class MedicalImageService {
	
	static admin = {
		get: async (params?: any) => Api.createResponse('admin/medical-images/get',params),
		delete: async (params?: any) => Api.createResponse('admin/medical-images/delete',params),
		update: async (params?: any) => Api.createResponse('admin/medical-images/update',params),
		create: async (params?: any) => Api.createResponse('admin/medical-images/create',params)
	}

}

export default MedicalImageService;