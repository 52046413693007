import React from 'react';
import { Input, Button, PaperClip, Select, CheckboxTable, DatePicker, Modal, Textarea } from 'components';
import { ServiceService } from 'services';
import { RootState } from 'reducers';
import { RouteComponentProps } from 'react-router-dom';
import { Globals, Constants, Socket, SocketEvents } from 'utils';
import { WhiteImage2 } from 'assets/img';
import moment from 'moment';
import { AddIcon, TrashIcon } from 'assets/icons';
import ModalSpecialities from './modal-specialities';
import ModalTreatments from '../financing/modal-treatment';
import ModalStudies from './modal-medical-studies';
import ModalImages from './modal-medical-images';
import ModalCategories from './modal-categories';

interface Props {
	onClose?: any;
	item?: any;
}

class ModalCreate extends React.Component<Props> {

	state = {
		form: {
			name: '',
			description: '',
			photo: '',
			speciality_id: '',
			consultation_type_id: '',
			emergency_phone: '',
			medical_study_id: '',
			medical_image_id: '',
			treatment_id: '',
			require_form: false,
			require_image: false,
			require_document: false,
			service_category_id: '',
			price: '',
			price_app: '',
			percentage: '',
			use_price: false,
			future_use: false,
			not_require_practicing: false,
			service_speciality_id: ''
		},
		specialities: [],
		treatments: [],
		medical_images: [],
		medical_studies: [],
		visible: false,
		visible_treatments: false,
		medical_images_selected: [],
		medical_studies_selected: [],
		treatments_selected: [],
		specialities_selected: [],
		visible_medical_images: false,
		visible_medical_studies: false,
		service_categories: [],
		visible_categories: false
	}

	componentDidMount() {
		if (this.props.item) {
			this.setState({
				form: {
					...this.state.form,
					name: this.props.item?.name || '',
					description: this.props.item?.description || '',
					speciality_id: '',
					consultation_type_id: this.props.item?.consultation_type_id || '',
					emergency_phone: this.props.item?.emergency_phone || '',
					require_form: this.props.item?.require_form || false,
					require_image: this.props.item?.require_image || false,
					require_document: this.props.item?.require_document || false,
					service_category_id: this.props.item?.service_category_id || '',
					not_require_practicing: this.props.item?.not_require_practicing || false,
					price_app: this.props.item?.price_app || '',
					percentage: this.props.item?.percentage || '',
					price: this.props.item?.price || '',
					use_price: this.props.item?.use_price || false,
					future_use: this.props.item?.future_use || false,
					service_speciality_id: this.props.item?.speciality_id || ''
				},
				specialities_selected: this.props.item?.specialities || []
			});
		}

		this.loadSpecialities();
	}

	loadSpecialities = async (withoutLoading: boolean = false) => {
		const res: any = await ServiceService.admin.getSpecialities({
			withoutLoading
		});
		this.setState({
			specialities: res.specialities,
			treatments: res.treatments,
			medical_studies: res.medical_studies,
			medical_images: res.medical_images,
			service_categories: res.service_categories
		});
	}

	submit = async (e: any) => {
		e.preventDefault();

		if (this.state.specialities_selected.find((i: any) => i.price == '' || i.price_app == '' || i.percentage == '')) {
			Globals.showError("Debe ingresar todos los valores de precios");
			return;
		}

		if ((this.state.form.price == '' || this.state.form.price_app == '' || this.state.form.percentage == '') && this.state.form.use_price) {
			Globals.showError("Debe ingresar todos los valores de precios");
			return;
		}

		if (this.props.item != null) {
			await ServiceService.admin.update({
				service_id: this.props.item?.id,
				...this.state.form,
				hasFile: true,
				// @ts-ignore,
				photo: this.state.form.photo?.value || '',
				specialities: JSON.stringify(this.state.specialities_selected),
				not_require_practicing: this.state.form?.not_require_practicing ? 1 : 0,
				use_price: this.state.form?.use_price ? 1 : 0,
				future_use: this.state.form?.future_use ? 1 : 0
			});
			Globals.showSuccess("Se ha modificado el servicio correctamente");
			this.props.onClose();
		}
		else {
			await ServiceService.admin.create({
				...this.state.form,
				hasFile: true,
				// @ts-ignore,
				photo: this.state.form.photo?.value || '',
				specialities: JSON.stringify(this.state.specialities_selected),
				not_require_practicing: this.state.form?.not_require_practicing ? 1 : 0,
				use_price: this.state.form?.use_price ? 1 : 0,
				future_use: this.state.form?.future_use ? 1 : 0
			});
			Globals.showSuccess("Se ha creado el servicio correctamente");
			this.props.onClose();
		}
	}

	change = (e: any,callback = () => {}) => {
		this.setState({
			form: {
				...this.state.form,
				[e.target.name]: e.target.value
			}
		},callback);
	}

	onClose = () => {
		this.setState({
			visible: false
		},() => this.loadSpecialities(true));
	}

	onCloseImages = () => {
		this.setState({
			visible_medical_images: false
		},() => this.loadSpecialities(true));
	}

	onCloseStudies = () => {
		this.setState({
			visible_medical_studies: false
		},() => this.loadSpecialities(true));
	}

	onCloseTreatments = () => {
		this.setState({
			visible_treatments: false
		},() => this.loadSpecialities(true));
	}

	onCloseCategories = () => {
		this.setState({
			visible_categories: false
		},() => this.loadSpecialities(true));
	}

	openSpecialities = () => {
		this.setState({
			visible: true
		});
	}

	openMedicalStudies = () => {
		this.setState({
			visible_medical_studies: true
		});
	}

	openMedicalImages = () => {
		this.setState({
			visible_medical_images: true
		});
	}

	openTreatments = () => {
		this.setState({
			visible_treatments: true
		});
	}

	openCategories = () => {
		this.setState({
			visible_categories: true
		});
	}

	deleteMedicalStudy = (index: number) => {
		let medical_studies_selected: any = [...this.state.medical_studies_selected];
		medical_studies_selected.splice(index,1);
		this.setState({
			medical_studies_selected
		});
	}

	deleteMedicalImages = (index: number) => {
		let medical_images_selected: any = [...this.state.medical_images_selected];
		medical_images_selected.splice(index,1);
		this.setState({
			medical_images_selected
		});
	}

	deleteTreatments = (index: number) => {
		let treatments_selected: any = [...this.state.treatments_selected];
		treatments_selected.splice(index,1);
		this.setState({
			treatments_selected
		});
	}

	add = async () => {
		// if (!this.state.form.speciality_id) {
		// 	Globals.showError("Por favor, ingrese una especialidad");
		// 	return;
		// }

		if (this.state.treatments_selected.length == 0 && this.state.medical_studies_selected.length == 0 && this.state.medical_images_selected.length == 0) {
			Globals.showError("Por favor, ingrese un tratamiento/estudio/imagen");
			return;
		}

		let specialities_selected: any = [...this.state.specialities_selected];

		for (let i = 0; i < this.state.treatments_selected.length; i++) {
			const treatment_id = this.state.treatments_selected[i];

			const item = specialities_selected.find((i: any) => i.speciality_id == this.state.form.speciality_id && i.treatment_id == treatment_id);

			if (!item) {
				specialities_selected.push({
					speciality_id: this.state.form.speciality_id || null,
					treatment_id,
					price: '',
					price_app: '',
					percentage: ''
				});	
			}
		}
 
		for (let i = 0; i < this.state.medical_images_selected.length; i++) {
			const medical_image_id = this.state.medical_images_selected[i];

			const item = specialities_selected.find((i: any) => i.speciality_id == this.state.form.speciality_id && i.medical_image_id == medical_image_id);

			if (!item) {
				specialities_selected.push({
					speciality_id: this.state.form.speciality_id || null,
					medical_image_id,
					price: '',
					price_app: '',
					percentage: ''
				});	
			}
		}

		for (let i = 0; i < this.state.medical_studies_selected.length; i++) {
			const medical_study_id = this.state.medical_studies_selected[i];

			const item = specialities_selected.find((i: any) => i.speciality_id == this.state.form.speciality_id && i.medical_study_id == medical_study_id);

			if (!item) {
				specialities_selected.push({
					speciality_id: this.state.form.speciality_id || null,
					medical_study_id,
					price: '',
					price_app: '',
					percentage: ''
				});	
			}
		}

		await this.setState({
			form: {
				...this.state.form,
				speciality_id: ''
			},
			specialities_selected,
			medical_images_selected: [],
			medical_studies_selected: [],
			treatments_selected: [],
		});
	}

	changeSpeciality = (value: string,target: string,index: number) => {
		let specialities_selected: any = [...this.state.specialities_selected];
		specialities_selected[index][target] = value;
		this.setState({
			specialities_selected
		});
	}

	deleteSpeciality = (index: number) => {
		let specialities_selected: any = [...this.state.specialities_selected];
		specialities_selected.splice(index,1);
		this.setState({
			specialities_selected
		});
	}

	compare = (a: any ,b: any) => {
	  if (a.speciality_id < b.speciality_id) {
	    return -1;
	  }
	  if (a.speciality_id > b.speciality_id) {
	    return 1;
	  }
	  return 0;
	}
	
	render() {
		const { visible, visible_medical_images, visible_medical_studies, visible_treatments, visible_categories } = this.state;
		const medical_images_selected: any = this.state.medical_images_selected;
		const treatments_selected: any = this.state.treatments_selected;
		const medical_studies_selected: any = this.state.medical_studies_selected;
		const category: any = this.state.service_categories.find((item: any) => item.id == this.state.form.service_category_id);
		const specialities_selected: any = this.state.specialities_selected.sort(this.compare);
		// @ts-ignore
		const specialities_list: any = [...new Set(this.state.specialities_selected.map((i: any) => i.speciality_id))].filter((i: any) => i != '');

		return (
			<div id="modal-create-service">
				<Modal
		          className="modal-create-service"
		          visible={ visible }
		          title="Especialidades"
		          animation={ false }
		          onClose={ () => this.onClose() }
		        >
		          {/* 
		          // @ts-ignore */}
		          <ModalSpecialities
		            onClose={ () => this.onClose() } />
		      	</Modal>

		      	<Modal
		          className="modal-create-service"
		          visible={ visible_medical_studies }
		          title="Estudios"
		          animation={ false }
		          onClose={ () => this.onCloseStudies() }
		        >
		          {/* 
		          // @ts-ignore */}
		          <ModalStudies
		            onClose={ () => this.onCloseStudies() } />
		      	</Modal>

		      	<Modal
		          className="modal-create-service"
		          visible={ visible_categories }
		          title="Categorías"
		          animation={ false }
		          onClose={ () => this.onCloseCategories() }
		        >
		          {/* 
		          // @ts-ignore */}
		          <ModalCategories
		            onClose={ () => this.onCloseCategories() } />
		      	</Modal>

		      	<Modal
		          className="modal-create-service"
		          visible={ visible_medical_images }
		          title="Imagenes"
		          animation={ false }
		          onClose={ () => this.onCloseImages() }
		        >
		          {/* 
		          // @ts-ignore */}
		          <ModalImages
		            onClose={ () => this.onCloseImages() } />
		      	</Modal>

		      	<Modal
		          className="modal-create-service"
		          visible={ visible_treatments }
		          title="Tratamientos"
		          animation={ false }
		          onClose={ () => this.onCloseTreatments() }
		        >
		          {/* 
		          // @ts-ignore */}
		          <ModalTreatments
		            onClose={ () => this.onCloseTreatments() } />
		      	</Modal>

		      	{
		      		!visible && !visible_medical_images && !visible_medical_studies && !visible_treatments && !visible_categories && (
		      			<form className="row" onSubmit={ this.submit }>
							<div className="col-md-6 container-select">
								<Select
									placeholder="Seleccionar"
									label="Categoría"
									name="service_category_id"
									onChange={ this.change }
									value={ this.state.form.service_category_id }
									options={ this.state.service_categories.map((item: any) => {
										return {
											value: item.id,
											label: item.name
										}
									}) } />
								<Button className="add-icon" type="button" onClick={ () => this.openCategories() }>
									<img src={ AddIcon } />
								</Button>
							</div>
							<div className="col-md-6 row">
								<div className="col-4">
									<div className="container-image">
										<PaperClip
											className="paperclip-image"
											value={ this.state.form.photo }
											width={ 750 }
											height={ 600 }
											onChange={ (e: any) => {
												this.setState({
													form: {
														...this.state.form,
														photo: e.target
													}
												})
											} }>
											{/*
											// @ts-ignore */}
											<img className={ `selected-image ${ (this.state.form.photo || this.props.item?.photo) ? '' : 'inactive' }` } src={ this.state.form.photo?.dataURL || (this.props.item?.photo ? (Constants.BASE_STORAGE + this.props.item?.photo) : WhiteImage2) } />
										</PaperClip>
									</div>
								</div>
								<div className="col-8 container-text-image">
									<h3>Subir Imagen destacada (Banner)</h3>
									<p>Tamaño máximo 750px por 600px</p>
								</div>
							</div>
							<div className="col-md-6">
								<Input
									color="white"
									value={ this.state.form.name }
									name="name" 
									label="Nombre del Servicio"
									onChange={ this.change } />
							</div>
							<div className="col-md-6 container-select">
								<Select
									placeholder="Seleccionar"
									label="Especialidad del Servicio"
									name="service_speciality_id"
									onChange={ this.change }
									value={ this.state.form.service_speciality_id }
									options={ this.state.specialities.map((item: any) => {
										return {
											value: item.id,
											label: item.name
										}
									}) } />
								<Button className="add-icon" type="button" onClick={ () => this.openSpecialities() }>
									<img src={ AddIcon } />
								</Button>
							</div>
							<div className="col-md-6">
								<Input
									type="number"
									color="white"
									value={ this.state.form.emergency_phone }
									name="emergency_phone" 
									label="Número de emergencia"
									onChange={ this.change } />
								{/*<div className="container-check-service-price" onClick={ () => {
									this.setState({
										form: {
											...this.state.form,
											not_require_practicing: !this.state.form.not_require_practicing
										}
									});
								} }>
									<div className={ `check-service-price ${ this.state.form.not_require_practicing ? 'active' : '' }` }></div>
									<p>El servicio no requiere asignar practicante</p>
								</div>*/}
								{
									!this.state.form.require_form && (
										<React.Fragment>
											<div className="container-check-service-price" onClick={ () => {
												this.setState({
													form: {
														...this.state.form,
														future_use: !this.state.form.future_use
													}
												});
											} }>
												<div className={ `check-service-price ${ this.state.form.future_use ? 'active' : '' }` }></div>
												<p>El servicio puede ser adquirido para uso futuro</p>
											</div>
											<div className="container-check-service-price" onClick={ () => {
												this.setState({
													form: {
														...this.state.form,
														use_price: !this.state.form.use_price
													}
												});
											} }>
												<div className={ `check-service-price ${ this.state.form.use_price ? 'active' : '' }` }></div>
												<p>Ingresar precio único por servicio</p>
											</div>
										</React.Fragment>
									)
								}
							</div>
							<div className="col-md-6">
								<Textarea
									rows={ 3 }
									color="white"
									value={ this.state.form.description }
									name="description" 
									label="Descripción del Banner"
									onChange={ this.change } />
							</div>
							{
								!this.state.form.use_price && (
									<div className="col-12">
										<div className="form-container">
											<div className="item-form" style={{ marginBottom: '20px' }} onClick={ () => this.setState({
												form: {
													...this.state.form,
													require_form: !this.state.form.require_form,
													require_image: false,
													require_document: false,
													use_price: false,
													future_use: false
												}
											}) }>
												<div className={ `form-check ${ this.state.form.require_form ? 'active' : '' }` }></div>
												<div>
													<p>Si este servicio requiere cotización, indique que solicitará al paciente llenar el formulario con sus respectivos soportes opcionales (Imágenes y/o estudios)</p>
													<p className="bold">Requiere Formulario</p>
												</div>
											</div>
										</div>
									</div>
								)
							}
							{
								this.state.form.use_price && (
									<div className="col-md-6">
										<div className="item-service">
											<div className="container-service">
												<Input
													type="decimal"
													color="white"
													value={ this.state.form.price }
													name="price" 
													label={ 'Precio REF' }
													onChange={ this.change } />
												<Input
													type="decimal"
													color="white"
													value={ this.state.form.price_app }
													name="price_app" 
													label={ 'Precio App' }
													onChange={ this.change } />
												<Input
													type="percentage"
													color="white"
													value={ this.state.form.percentage }
													name="percentage" 
													label={ '% App' }
													onChange={ this.change } />
											</div>
										</div>
									</div>
								)
							}
							{
								!this.state.form.use_price && !this.state.form.require_form && (
									<React.Fragment>
										<div className="col-12">
											<h3 className="title-prices">Carga de precios</h3>
										</div>
										<div className="col-md-6">
											<div className="container-select">
												<Select
													placeholder="Seleccionar"
													label="Estudios (Opcional)"
													name="medical_study_id"
													onChange={ (e: any) => {
														let medical_studies_selected: any = [...this.state.medical_studies_selected];
														medical_studies_selected.push(parseInt(e.target.value));
														this.setState({
															medical_studies_selected
														});
													} }
													value={ this.state.form.medical_study_id }
													options={ this.state.medical_studies.filter((i: any) => medical_studies_selected.indexOf(i.id) == -1).map((item: any) => {
														return {
															value: item.id,
															label: item.name
														}
													}) } />
												<Button className="add-icon" type="button" onClick={ () => this.openMedicalStudies() }>
													<img src={ AddIcon } />
												</Button>
											</div>
											<div className="row row-schedules">
												{
													medical_studies_selected.map((item: any, index: number) => {
														const medical_study: any = this.state.medical_studies.find((i: any) => i.id == item);

														return (
															<div className="col-md-12">
																<div className="item-service">
																	<p>{ medical_study?.name }</p>
																	<a href="#" className="trash-container" onClick={ (e: any) => {
																		e.preventDefault();
																		this.deleteMedicalStudy(index);
																	} }>
																		<img src={ TrashIcon } />
																	</a>
																</div>
															</div>
														)
													})
												}
											</div>
										</div>
										<div className="col-md-6">
											<div className="container-select">
												<Select
													placeholder="Seleccionar"
													label="Imagenes (Opcional)"
													name="medical_image_id"
													onChange={ (e: any) => {
														let medical_images_selected: any = [...this.state.medical_images_selected];
														medical_images_selected.push(parseInt(e.target.value));
														this.setState({
															medical_images_selected
														});
													} }
													value={ this.state.form.medical_image_id }
													options={ this.state.medical_images.filter((i: any) => medical_images_selected.indexOf(i.id) == -1).map((item: any) => {
														return {
															value: item.id,
															label: item.name
														}
													}) } />
												<Button className="add-icon" type="button" onClick={ () => this.openMedicalImages() }>
													<img src={ AddIcon } />
												</Button>
											</div>
											<div className="row row-schedules">
												{
													medical_images_selected.map((item: any, index: number) => {
														const medical_image: any = this.state.medical_images.find((i: any) => i.id == item);

														return (
															<div className="col-md-12">
																<div className="item-service">
																	<p>{ medical_image?.name }</p>
																	<a href="#" className="trash-container" onClick={ (e: any) => {
																		e.preventDefault();
																		this.deleteMedicalImages(index);
																	} }>
																		<img src={ TrashIcon } />
																	</a>
																</div>
															</div>
														)
													})
												}
											</div>
										</div>
										<div className="col-md-6 container-select">
											<Select
												placeholder="Seleccionar"
												label="Especialidad (Opcional)"
												name="speciality_id"
												onChange={ this.change }
												value={ this.state.form.speciality_id }
												options={ this.state.specialities.map((item: any) => {
													return {
														value: item.id,
														label: item.name
													}
												}) } />
											<Button className="add-icon" type="button" onClick={ () => this.openSpecialities() }>
												<img src={ AddIcon } />
											</Button>
										</div>
										<div className="col-md-6">
											<div className="container-select">
												<Select
													placeholder="Seleccionar"
													label="Tratamientos (Opcional)"
													name="treatment_id"
													onChange={ (e: any) => {
														let treatments_selected: any = [...this.state.treatments_selected];
														treatments_selected.push(parseInt(e.target.value));
														this.setState({
															treatments_selected
														});
													} }
													value={ this.state.form.treatment_id }
													options={ this.state.treatments.filter((i: any) => treatments_selected.indexOf(i.id) == -1).map((item: any) => {
														return {
															value: item.id,
															label: item.name
														}
													}) } />
												<Button className="add-icon" type="button" onClick={ () => this.openTreatments() }>
													<img src={ AddIcon } />
												</Button>
											</div>
											<div className="row row-schedules">
												{
													treatments_selected.map((item: any, index: number) => {
														const treatment: any = this.state.treatments.find((i: any) => i.id == item);

														return (
															<div className="col-md-12">
																<div className="item-service">
																	<p>{ treatment?.name }</p>
																	<a href="#" className="trash-container" onClick={ (e: any) => {
																		e.preventDefault();
																		this.deleteTreatments(index);
																	} }>
																		<img src={ TrashIcon } />
																	</a>
																</div>
															</div>
														)
													})
												}
											</div>
										</div>
										<div className="col-12 text-center">
											<Button type="button" className="btn-add" onClick={ () => this.add() }>
												Cargar
											</Button>
										</div>
										<div className="row row-schedules">
											{
												specialities_list.filter((item: any) => item != null).map((list_item: any) => {
													const speciality: any = this.state.specialities?.find((i: any) => i.id == parseInt(list_item));

													return (
														<div className="item-service-gray">
															<p className="bold">{ speciality?.name }</p>
															{
																specialities_selected.map((item: any, index: number) => {
																	if (item.speciality_id == parseInt(list_item)) {
																		const speciality: any = this.state.specialities?.find((i: any) => i.id == item.speciality_id);
																		const treatment: any = this.state.treatments?.find((i: any) => i.id == item.treatment_id);
																		const medical_image: any = this.state.medical_images?.find((i: any) => i.id == item.medical_image_id);
																		const medical_study: any = this.state.medical_studies?.find((i: any) => i.id == item.medical_study_id);

																		return (
																			<div className="item-service">
																				<p style={{ marginTop: index == 0 ? '20px' : '0px' }}>{ treatment?.name || medical_image?.name || medical_study?.name || speciality?.name }</p>
																				<div className="container-service">
																					<Input
																						type="decimal"
																						color="white"
																						value={ item.price }
																						name="price" 
																						label={ 'Precio REF' }
																						onChange={ (e: any) => this.changeSpeciality(e.target.value,e.target.name,index) } />
																					<Input
																						type="decimal"
																						color="white"
																						value={ item.price_app }
																						name="price_app" 
																						label={ 'Precio App' }
																						onChange={ (e: any) => this.changeSpeciality(e.target.value,e.target.name,index) } />
																					<Input
																						type="percentage"
																						color="white"
																						value={ item.percentage }
																						name="percentage" 
																						label={ '% App' }
																						onChange={ (e: any) => this.changeSpeciality(e.target.value,e.target.name,index) } />
																				</div>
																				<a href="#" className="trash-container" style={{ marginTop: '20px' }} onClick={ (e: any) => {
																					e.preventDefault();
																					this.deleteSpeciality(index);
																				} }>
																					<img src={ TrashIcon } />
																				</a>
																			</div>
																		)
																	}
																})
															}
														</div>
													)
												})
											}
											{
												specialities_selected.filter((item: any) => item.speciality_id == null).length > 0 && (
													<div className="item-service-gray">
														<p className="bold">{ category?.name || '' }</p>
														{
															specialities_selected.map((item: any, index: number) => {
																if (item.speciality_id == null) {
																	const treatment: any = this.state.treatments?.find((i: any) => i.id == item.treatment_id);
																	const medical_image: any = this.state.medical_images?.find((i: any) => i.id == item.medical_image_id);
																	const medical_study: any = this.state.medical_studies?.find((i: any) => i.id == item.medical_study_id);

																	return (
																		<div className="item-service">
																			<p style={{ marginTop: index == 0 ? '20px' : '0px' }}>{ treatment?.name || medical_image?.name || medical_study?.name }</p>
																			<div className="container-service">
																				<Input
																					type="decimal"
																					color="white"
																					value={ item.price }
																					name="price" 
																					label={ 'Precio REF' }
																					onChange={ (e: any) => this.changeSpeciality(e.target.value,e.target.name,index) } />
																				<Input
																					type="decimal"
																					color="white"
																					value={ item.price_app }
																					name="price_app" 
																					label={ 'Precio App' }
																					onChange={ (e: any) => this.changeSpeciality(e.target.value,e.target.name,index) } />
																				<Input
																					type="percentage"
																					color="white"
																					value={ item.percentage }
																					name="percentage" 
																					label={ '% App' }
																					onChange={ (e: any) => this.changeSpeciality(e.target.value,e.target.name,index) } />
																			</div>
																			<a href="#" className="trash-container" style={{ marginTop: '20px' }} onClick={ (e: any) => {
																				e.preventDefault();
																				this.deleteSpeciality(index);
																			} }>
																				<img src={ TrashIcon } />
																			</a>
																		</div>
																	)
																}
															})
														}
													</div>
												)
											}
										</div>
									</React.Fragment>
								)
							}
							<div className="col-12">
								<div className="row row-buttons">
									<div className="col-md-12 text-center">
										<Button type="submit">
											Guardar
										</Button>
									</div>
								</div>
							</div>
						</form>
		      		)
		      	}
			</div>
		)
	}
}

export default ModalCreate;