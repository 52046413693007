import { Api } from 'utils';

class TicketService {
	
	static admin = {
		updatePrice: async (params?: any) => Api.createResponse('admin/tickets/update-price',params),
		getPrice: async (params?: any) => Api.createResponse('admin/tickets/get-price',params),
		get: async (params?: any) => Api.createResponse('admin/tickets/',params),
		viewTickets: async (params?: any) => Api.createResponse('admin/tickets/view-tickets',params),
	}

}

export default TicketService;