import { Api } from 'utils';

class FinancingService {
	
	static admin = {
		get: async (params?: any) => Api.createResponse('admin/financing/',params),
		print: async (params?: any) => Api.createResponse('admin/financing/print',params),
		changeStatus: async (params?: any) => Api.createResponse('admin/financing/change-status',params),
		view: async (params?: any) => Api.createResponse('admin/financing/view',params),
		getDocuments: async (params?: any) => Api.createResponse('admin/financing/documents',params),
		changeDocument: async (params?: any) => Api.createResponse('admin/financing/change-document',params),
	}

}

export default FinancingService;