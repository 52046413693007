import React from 'react';
import { Input, Button } from 'components';
import { Globals, Constants, SocketEvents } from 'utils';
import { connect } from 'react-redux';
import { AuthService } from 'services';
import { RootState } from 'reducers';
import { RouteComponentProps } from 'react-router';
import { Logo, BgLogin } from 'assets/img';
import { Link } from 'react-router-dom';
import { ProfileIcon, LockIcon, PasswordActive, PasswordInactive } from 'assets/icons';
import { emit } from 'jetemit';

interface Props extends RootState, RouteComponentProps {
	dispatch: any
}

class Login extends React.Component<Props> {

	state = {
		form: {
			email: '',
			password: ''
		},
		activePassword: false
	}

	componentDidMount() {
		if (this.props.user) {
			this.props.history.replace('/');
		}

		this.props.dispatch({
			type: 'SET_HEADER',
			payload: {
				title: 'Iniciar Sesión',
				onBack: null
			}
		});
	}

	submit = async (e: any) => {
		e.preventDefault();
		if (!this.state.form.email) {
			Globals.showError("Debe ingresar el correo electrónico");
			return;
		}
		if (!this.state.form.password) {
			Globals.showError("Debe ingresar la contraseña");
			return;
		}
		const res: any = await AuthService.login(this.state.form);
		await this.props.dispatch({
			type: 'SET_USER',
			payload: res.user
		});
		this.props.history.replace('/');
	}

	change = (e: any) => {
		this.setState({
			form: {
				...this.state.form,
				[e.target.name]: e.target.value
			}
		});
	}
	
	render() {
		return (
			<div id="login">
				<div className="container-black">
					<div className="row container-form">
						<div className="col-lg-6 container-bg" style={{ backgroundImage: `url(${ BgLogin })` }}>

						</div>
						<form className="col-lg-6" onSubmit={ this.submit }>
							<div className="container-logo text-center">
								<img src={ Logo } />
							</div>
							<div className="container-input">
								<div className="input-group">
									<div className="input-group-prepend">
										<img src={ ProfileIcon  } />
									</div>
									<Input 
										placeholder="Correo Electrónico"
										name="email" 
										onChange={ this.change } />
								</div>
								<div className="input-group">
									<div className="input-group-prepend">
										<img src={ LockIcon  } />
									</div>
									<Input
										placeholder="Contraseña"
										type={ this.state.activePassword ? 'text' : 'password' }
										name="password"
										onChange={ this.change } />
									<div className="input-group-prepend password-view" onClick={ () => {
										this.setState({
											activePassword: !this.state.activePassword
										});
									} }>
										<img src={ this.state.activePassword ? PasswordActive : PasswordInactive } />
									</div>
								</div>
								<div className="row">
									<div className="col-md-12 text-center">
										<div className="recover">
											<Link to="/recover">
												¿Contraseña olvidada?
											</Link>
										</div>	
									</div>
								</div>
								<div className="text-center">
									<Button type="submit">
										Iniciar Sesión
									</Button>
								</div>
							</div>			
						</form>
					</div>
				</div>
			</div>
		)
	}
}

export default connect((state: RootState) => {
	return {
		user: state.user
	}
})(Login);