import React from 'react';
import { connect } from 'react-redux';
import { RootState } from 'reducers';
import { RouteComponentProps, Link } from 'react-router-dom';
import { CleanIcon, PrintIcon } from 'assets/icons';
import { Globals, Constants, Colors, Print } from 'utils';
import { ReportService } from 'services';
import { WhiteImage } from 'assets/img';
import { Input, Pagination, Table, DatePicker, Tooltip, Select } from 'components';
import moment from 'moment';

interface Props extends RootState, RouteComponentProps {
	dispatch: any
}

const INITIAL_STATE = {
	since: '',
	until: '',
	plan_id: '',
	search: '',
	status: ''
} 

class AdminReportClub extends React.Component<Props> {

	timer: any = undefined;

	state = {
		data: [],
		page: 1,
		last_page: 1,
		header: [
			'Tutor',
			'Cédula',
			'Mascota',
			'Microchip',
			'Plan',
			'Nº de Afiliación',			
			'Fecha de Vencimiento',
			'Estatus',
			'Servicios pendientes de uso',
			'Servicios consumidos',
			'Acciones'
		],
		form: INITIAL_STATE,
		services: [],
		plans: []
	}

	componentDidMount() {
		this.props.dispatch({
			type: 'SET_HEADER',
			payload: {
				title: 'Reporte Mi Club',
				onBack: null
			}
		});

		this.props.dispatch({
			type: 'SET_SUBHEADER',
			payload: {
				title: ''
			}
		});

		this.load();
		this.loadPlans();
	}

	loadPlans = async () => {
		const res: any = await ReportService.admin.getPlans({
			withoutLoading: true,
			type_id: Constants.PLAN_TYPES.CLUB
		});
		this.setState({
			plans: res.plans || []
		});
	}

	load = async (withoutLoading: boolean = false) => {
		const res: any = await ReportService.admin.getClub({
			withoutLoading,
			page: this.state.page,
			...this.state.form,
		});
		this.setState({
			data: res.plans.rows,
			last_page: res.plans.count
		});
	}

	reset = () => {
		this.setState({
			form: INITIAL_STATE,
			page: 1
		},() => this.load(true));
	}

	change = (e: any,callback = () => {}) => {
		this.setState({
			page: 1,
			form: {
				...this.state.form,
				[e.target.name]: e.target.value
			}
		},callback);
	}

	print = async (e: any, item: any) => {
		e.preventDefault();
		const res: any = await ReportService.admin.printReportClub({
			plan_user_id: item.id
		});
		Print(Constants.BASE_STORAGE + res.url);
	}

	getColor = (i: any) => {
		return i.payment?.next_payment != null ? (moment(i.payment?.next_payment).format('YYYY-MM-DD') >= moment().format('YYYY-MM-DD') ? Colors.green : Colors.danger) : Colors.danger;
	}
	
	render() {
		return (
			<div id="admin-report-club">
				<div className="row row-filter">
					<div className="col-md-11 row">
						<div className="col-md-4">
							<Input
								value={ this.state.form.search }
								name="search"
								label="Buscar"
								placeholder="Tutor/Cédula/Mascota/Microchip"
								className="input-table"
								onChange={ (e: any) => {
									clearTimeout(this.timer);
									this.timer = setTimeout(() => {
										this.load(true);
									},1000);
									this.change(e); 
								} } />
						</div>
						<div className="col-md-4">
							<DatePicker
								label="Desde"
								placeholder="Desde"
								onChange={ (text: string) => {
									this.change({
										target: {
											value: text,
											name: 'since'
										}
									},() => this.load(true));
								} }
								value={ this.state.form.since }
							/>
						</div>
						<div className="col-md-4">
							<DatePicker
								label="Hasta"
								placeholder="Hasta"
								onChange={ (text: string) => {
									this.change({
										target: {
											value: text,
											name: 'until'
										}
									},() => this.load(true));
								} }
								value={ this.state.form.until }
							/>
						</div>
						<div className="col-md-4">
							<Select
								placeholder="Seleccionar"
								label="Tipo de plan"
								name="plan_id"
								onChange={ (e: any) => this.change(e,() => this.load(true)) }
								value={ this.state.form.plan_id }
								options={ this.state.plans.map((item: any) => {
									return {
										value: item.id,
										label: item.name
									}
								}) } />
						</div>
						<div className="col-md-4">
							<Select
								placeholder="Seleccionar"
								label="Estatus"
								name="status"
								onChange={ (e: any) => this.change(e,() => this.load(true)) }
								value={ this.state.form.status }
								options={ [
									{ value: Constants.PLAN_STATUS.ACTIVE.toString(), label: 'Activos' },
									{ value: Constants.PLAN_STATUS.INACTIVE.toString(), label: 'Inactivos' },
								] } />
						</div>
					</div>
					<div className="col-md-1">
						{
							this.state.form != INITIAL_STATE && (
								<button className="btn btn-clean" onClick={ () => this.reset() }>
									<img src={ CleanIcon } />
								</button>
							)
						}
					</div>
				</div>

				<Table title="Reporte Mi Club" data={ this.state.data.length } rightAlign={[ 9 ]} header={ this.state.header }>
					{ this.state.data.map((i: any,index: number) => {
						return (
							<tr key={ index }>
								<td>{ i.user?.person?.name + ' ' + i.user?.person?.lastname }</td>
								<td>{ i.user?.person?.document }</td>
								<td>{ i.pet?.name }</td>
								<td>{ i.pet?.chip_code || '-' }</td>
								<td>{ i.plan?.name }</td>				
								<td style={{ whiteSpace: 'nowrap' }}>{ i?.pet?.gift_card?.format_code }</td>				
								<td>{ i.payment?.next_payment ? moment(i.payment?.next_payment).format('DD/MM/YYYY') : '-' }</td>
								<td className="status" style={{ color: this.getColor(i) }}>{ i.payment?.next_payment != null ? (moment(i.payment?.next_payment).format('YYYY-MM-DD') >= moment().format('YYYY-MM-DD') ? 'Activo' : 'Inactivo') : 'Inactivo' }</td>
								<td>{ i.quantity_availables }</td>
								<td>{ i.quantity_used }</td>
								<td className="vertical-center text-right">
									<Tooltip title="Descargar">
										<a className="link-icon" href="#" onClick={ (e: any) => this.print(e,i) }>
											<img src={ PrintIcon } />
										</a>
									</Tooltip>
								</td>
							</tr>
						)
					}) }
				</Table>

				<Pagination 
					pages={ this.state.last_page } 
					active={ this.state.page }
					onChange={ async (page: number) => {
						await this.setState({
							page: page
						});
						this.load();
					} } />
			</div>
		)
	}
}

export default connect((state: RootState) => {
	return {
		user: state.user
	}
})(AdminReportClub);