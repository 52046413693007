import React from 'react';
import { connect } from 'react-redux';
import { RootState } from 'reducers';
import { RouteComponentProps, Link } from 'react-router-dom';
import { CleanIcon, PrintIcon } from 'assets/icons';
import { Globals, Constants, Colors, Print } from 'utils';
import { ReportService } from 'services';
import { WhiteImage } from 'assets/img';
import moment from 'moment';
import { Table, Pagination, Input, Select, Tooltip } from 'components';

interface Props extends RootState, RouteComponentProps {
	dispatch: any
}

const INITIAL_STATE = {
	plan_id: '',
	search: ''
}

class AdminReportPlans extends React.Component<Props> {

	timer: any = undefined;

	state = {
		data: [],
		page: 1,
		last_page: 1,
		header: [
			'Tutor',
			'Cedula',
			'Tipo de Plan',
			'Mascota',
			'Microchip',
			'Fecha de compra',
			'Monto Pagado',
			'Medio de Pago',
			'Acciones'
		],
		form: INITIAL_STATE,
		plans: []
	}

	componentDidMount() {
		this.props.dispatch({
			type: 'SET_HEADER',
			payload: {
				title: 'Reporte Planes comprados',
				onBack: null
			}
		});

		this.props.dispatch({
			type: 'SET_SUBHEADER',
			payload: {
				title: ''
			}
		});

		this.load();
		this.loadPlans();
	}

	load = async (withoutLoading: boolean = false) => {
		const res: any = await ReportService.admin.getReportPlans({
			withoutLoading,
			page: this.state.page,
			...this.state.form,
		});
		this.setState({
			data: res.plans.rows,
			last_page: res.plans.count
		});
	}

	loadPlans = async () => {
		const res: any = await ReportService.admin.getPlans({
			withoutLoading: true,
			type_id: Constants.PLAN_TYPES.HEALTH
		});
		this.setState({
			plans: res.plans || []
		});
	}

	reset = () => {
		this.setState({
			form: INITIAL_STATE,
			page: 1
		},() => this.load(true));
	}

	change = (e: any,callback = () => {}) => {
		this.setState({
			page: 1,
			form: {
				...this.state.form,
				[e.target.name]: e.target.value
			}
		},callback);
	}

	print = async (e: any, item: any) => {
		e.preventDefault();
		const res: any = await ReportService.admin.printReportPlan({
			plan_user_id: item.id
		});
		Print(Constants.BASE_STORAGE + res.url);
	}
	
	render() {
		return (
			<div id="admin-report-plans">
				<div className="row row-filter">
					<div className="col-md-4">
						<Input
							value={ this.state.form.search }
							name="search"
							label="Filtrar"
							placeholder="Buscar por mascota/tutor/cédula/microchip"
							className="input-table"
							onChange={ (e: any) => {
								clearTimeout(this.timer);
								this.timer = setTimeout(() => {
									this.load(true);
								},1000);
								this.change(e); 
							} } />
					</div>
					<div className="col-md-3">
						<Select
							placeholder="Seleccionar"
							label="Tipo de plan"
							name="plan_id"
							onChange={ (e: any) => this.change(e,() => this.load(true)) }
							value={ this.state.form.plan_id }
							options={ this.state.plans.map((item: any) => {
								return {
									value: item.id,
									label: item.name
								}
							}) } />
					</div>
					<div className="col-md-3">
						{
							this.state.form != INITIAL_STATE && (
								<button className="btn btn-clean" onClick={ () => this.reset() }>
									<img src={ CleanIcon } />
								</button>
							)
						}
					</div>
				</div>

				<Table title="Reporte Planes comprados" data={ this.state.data.length } rightAlign={[ 8 ]} header={ this.state.header }>
					{ this.state.data.map((i: any,index: number) => {
						return (
							<tr key={ index }>
								<td>{ i.user?.person?.name + ' ' + i.user?.person?.lastname }</td>
								<td>{ i.user?.person?.document }</td>
								<td>{ i.plan?.name }</td>
								<td>{ i.pet?.name }</td>
								<td>{ i.pet?.chip_code || '-' }</td>
								<td>{ i.created_at ? moment(i.created_at).format('DD/MM/YYYY') : '' }</td>
								<td>{ Globals.formatMiles(i.payment?.amount) }</td>
								<td>{ i.payment?.payment_method?.name }</td>
								<td className="vertical-center text-right">
									<Tooltip title="Descargar">
										<a className="link-icon" href="#" onClick={ (e: any) => this.print(e,i) }>
											<img src={ PrintIcon } />
										</a>
									</Tooltip>
								</td>
							</tr>
						)
					}) }
				</Table>

				<Pagination 
					pages={ this.state.last_page } 
					active={ this.state.page }
					onChange={ async (page: number) => {
						await this.setState({
							page: page
						});
						this.load();
					} } />
			</div>
		)
	}
}

export default connect((state: RootState) => {
	return {
		user: state.user
	}
})(AdminReportPlans);