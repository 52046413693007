import React from 'react';
import { connect } from 'react-redux';
import { RootState } from 'reducers';
import { RouteComponentProps, Link } from 'react-router-dom';
import { CleanIcon, PrintIcon, PasswordActive, AddIcon, EditIcon } from 'assets/icons';
import { Globals, Constants, Colors, Print, checkPermission } from 'utils';
import { WhiteImage } from 'assets/img';
import { Table, Pagination, Input, Select, Tooltip, Modal, DatePicker, Button } from 'components';
import moment from 'moment';
import { RememberService } from 'services';
import ModalCreate from './modal-create';

interface Props extends RootState, RouteComponentProps {
	dispatch: any
}

const INITIAL_STATE = {
	search: '',
	since: '',
	until: '',
	remember_type_id: ''
}

class PracticingRemembers extends React.Component<Props> {

	timer: any = undefined;

	state = {
		data: [],
		page: 1,
		last_page: 1,
		header: [
			'Tutor',
			'Mascota',
			'Tipo',
			'Medicamento',
			'Fecha',
			'Próxima',
			'Lote',
			'Comentarios',
			'Acciones'
		],
		form: INITIAL_STATE,
		remember_types: [],
		visible: false,
		item: null
	}

	componentDidMount() {
		this.props.dispatch({
			type: 'SET_HEADER',
			payload: {
				title: 'Recordatorios',
				onBack: null
			}
		});

		this.props.dispatch({
			type: 'SET_SUBHEADER',
			payload: {
				title: ''
			}
		});

		this.loadTypes();
		this.load();
	}

	load = async (withoutLoading: boolean = false) => {
		const res: any = await RememberService.practicing.get({
			withoutLoading,
			page: this.state.page,
			...this.state.form,
			since: this.state.form.since && moment(this.state.form.since).format('YYYY-MM-DD'),
			until: this.state.form.until && moment(this.state.form.until).format('YYYY-MM-DD'),
			user_id: this.props.user?.id
		});
		this.setState({
			data: res.remembers.rows,
			last_page: res.remembers.count
		});
	}

	loadTypes = async () => {
		const res: any = await RememberService.practicing.getTypes({
			withoutLoading: true
		});
		this.setState({
			remember_types: res.remember_types || []
		});
	}

	change = (e: any,callback = () => {}) => {
		this.setState({
			page: 1,
			form: {
				...this.state.form,
				[e.target.name]: e.target.value
			}
		},callback);
	}

	reset = () => {
		this.setState({
			form: INITIAL_STATE,
			page: 1
		},() => this.load(true));
	}

	add = () => {
		this.setState({
			visible: true
		});
	}

	onClose = () => {
		this.setState({
			visible: false,
			item: null
		},() => this.load(true));
	}

	edit = (e: any, item: any) => {
		e.preventDefault();
		this.setState({
			item,
			visible: true
		});
	}
	
	render() {
		const { visible } = this.state;

		return (
			<div id="practicing-remembers">
				<Modal
		          className="modal-create-remember"
		          visible={ visible }
		          title={ `${ this.state.item != null ? 'Editar' : 'Crear' } Recordatorio` }
		          onClose={ () => this.onClose() }
		        >
		          {/*
		          // @ts-ignore */}
		          <ModalCreate
		          	item={ this.state.item }
		          	remember_types={ this.state.remember_types }
		            onClose={ () => this.onClose() } />
		      	</Modal>

				<div className="row row-filter">
					<div className="row col-md-11">
						<div className="col-md-3">
							<Input
								value={ this.state.form.search }
								name="search"
								label="Nombre del Tutor o Mascota"
								placeholder="Buscar"
								className="input-table"
								onChange={ (e: any) => {
									clearTimeout(this.timer);
									this.timer = setTimeout(() => {
										this.load(true);
									},1000);
									this.change(e); 
								} } />
						</div>
						<div className="col-md-3">
							<DatePicker
								label="Desde"
								placeholder="Desde"
								onChange={ (text: string) => {
									this.change({
										target: {
											value: text,
											name: 'since'
										}
									},() => this.load(true));
								} }
								value={ this.state.form.since }
							/>
						</div>
						<div className="col-md-3">
							<DatePicker
								label="Hasta"
								placeholder="Hasta"
								onChange={ (text: string) => {
									this.change({
										target: {
											value: text,
											name: 'until'
										}
									},() => this.load(true));
								} }
								value={ this.state.form.until }
							/>
						</div>
						<div className="col-md-3">
							<Select
								placeholder="Seleccionar"
								label="Tipo"
								name="remember_type_id"
								onChange={ (e: any) => this.change(e,() => this.load(true)) }
								value={ this.state.form.remember_type_id }
								options={ this.state.remember_types.map((item: any) => {
									return {
										value: item.id,
										label: item.name
									}
								}) } />
						</div>
					</div>
					<div className="col-md-1">
						{
							this.state.form != INITIAL_STATE && (
								<button className="btn btn-clean" onClick={ () => this.reset() }>
									<img src={ CleanIcon } />
								</button>
							)
						}
					</div>
				</div>

				<Table title="Recordatorios" data={ this.state.data.length } header={ this.state.header } titleRight={
					<Button className="btn-add" onClick={ () => this.add() }>
						<img src={ AddIcon } />
						Nuevo
					</Button>
				}>
					{ this.state.data.map((i: any,index: number) => {
						return (
							<tr key={ index }>
								<td>{ (i?.user?.person?.name || '') + ' ' + (i?.user?.person?.lastname || '') }</td>
								<td>{ i?.pet?.name || '-' }</td>
								<td>{ i.remember_type?.name || '-' }</td>
								<td>{ i.medicine || '-' }</td>
								<td>{ moment(i.date).isValid() ? moment(i.date).format('DD/MM/YYYY') : '' }</td>
								<td>{ moment(i.next_date).isValid() ? moment(i.next_date).format('DD/MM/YYYY') : '' }</td>
								<td>{ i.lot }</td>
								<td>{ i.comments }</td>
								<td className="vertical-center text-right">
									<Tooltip title="Editar">
										<a className="link-icon" href="#" onClick={ (e: any) => this.edit(e,i) }>
											<img src={ EditIcon } />
										</a>
									</Tooltip>
								</td>
							</tr>
						)
					}) }
				</Table>

				<Pagination 
					pages={ this.state.last_page } 
					active={ this.state.page }
					onChange={ async (page: number) => {
						await this.setState({
							page: page
						});
						this.load();
					} } />
			</div>
		)
	}
}

export default connect((state: RootState) => {
	return {
		user: state.user
	}
})(PracticingRemembers);