import React from 'react';
import { Input, Button, PaperClip, Select, CheckboxTable, DatePicker, Modal, Textarea, SelectSearch } from 'components';
import { RootState } from 'reducers';
import { RouteComponentProps } from 'react-router-dom';
import { Globals, Constants, Socket, SocketEvents } from 'utils';
import { WhiteImage2 } from 'assets/img';
import { DownloadIcon } from 'assets/icons';

interface Props {
	onClose?: any;
	item: any;
}

class ModalView extends React.Component<Props> {

	render() {
		const item: any = this.props.item;

		return (
			<div id="modal-view-service-management">
				<p><strong>Nombre:</strong> { item?.form_name }</p>
				<p><strong>Teléfono:</strong> { item?.form_phone }</p>
				<p><strong>Correo:</strong> { item?.form_email }</p>
				{
					item?.medical_report != null && (
						<React.Fragment>
							<h3>Informe Médico</h3>
							<a target="_blank" href={ Constants.BASE_STORAGE + item?.medical_report }>
								<Button>
									Descargar
									<img src={ DownloadIcon } />
								</Button>
							</a>
						</React.Fragment>
					)
				}
				{
					item?.images?.map((item: any) => {
						return (
							<a href={ Constants.BASE_STORAGE + item?.image } target="_blank">
								<img src={ Constants.BASE_STORAGE + item?.image } className="photo-service" />
							</a>
						)
					})
				}
			</div>
		)
	}
}

export default ModalView;