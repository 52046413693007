import { Api } from 'utils';

class CardService {
	
	static admin = {
		get: async (params?: any) => Api.createResponse('admin/cards/',params),
		create: async (params?: any) => Api.createResponse('admin/cards/create',params),
		view: async (params?: any) => Api.createResponse('admin/cards/view',params),
		print: async (params?: any) => Api.createResponse('admin/cards/print',params),
		getPlans: async (params?: any) => Api.createResponse('admin/cards/plans',params),
		getSellers: async (params?: any) => Api.createResponse('admin/cards/sellers',params)
	}

}

export default CardService;