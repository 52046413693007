import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from 'reducers';
import { useHistory, Link, useLocation } from 'react-router-dom';
import { Globals, Constants } from 'utils';
import { 
	ArrowLeft, 
	ProfileIcon,
	LockIcon,
	LogoutIcon,
	LogoutGray,
	UserIcon
} from 'assets/icons';
import { Logo, WhiteImage } from 'assets/img';

interface Props {
	className?: string
}

const logout = (dispatch: any,history: any) => {
	Globals.confirm('¿Desea cerrar sesión?',() => {
		dispatch({
			type: 'SET_USER',
			payload: null
		});
		history.replace('/login');
		Globals.quitLoading();
	});
}

const Header = (props: Props) => {
	const header = useSelector((state: RootState) => state.header);
	const subheader = useSelector((state: RootState) => state.subheader);
	const user = useSelector((state: RootState) => state.user);
	const history = useHistory();
	const dispatch = useDispatch();
	const location = useLocation();

	return (
		<div id="header" className={ props.className }>
			<div className="container-blue-top">
				<div className="container-logo">
					<Link to="/">
						<img src={ Logo } />
					</Link>
					<Link to="/" className="responsive">
						<img src={ Logo } />
					</Link>
				</div>
				<div className="container-back-top">
					{
						header.onBack && (
							<div className="arrow-back" onClick={ header.onBack }>
								<img src={ ArrowLeft } />
							</div>
						)
					}
					<div className="container-titles">
						<h3 className="title-top">{ header.title }</h3>
						<h4 className="subtitle-top">{ subheader.title }</h4>
					</div>	
				</div>
				<div className="container-buttons-top">
					<div className="container-buttons-web">
						<Link to="/profile">
							<button className={ `btn-profile ${ location.pathname == '/profile' ? 'active' : '' }` }>
								<img src={ ProfileIcon } />
								<span>Mi Cuenta</span>
							</button>
						</Link>
						<button className="btn-logout" onClick={ () => logout(dispatch,history) }>
							<img src={ LogoutIcon } />
						</button>
					</div>
				</div>
			</div>		
		</div>
	)	
}

export default Header;