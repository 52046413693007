import React from 'react';
import { Input, Button, PaperClip, Select, CheckboxTable, DatePicker } from 'components';
import { HealthCenterService } from 'services';
import { RootState } from 'reducers';
import { RouteComponentProps } from 'react-router-dom';
import { Globals, Constants, Socket, SocketEvents } from 'utils';
import { WhiteImage } from 'assets/img';

interface Props {
	onClose?: any;
	item?: any;
}

class ModalServices extends React.Component<Props> {

	state = {
		users: [],
		health_users: this.props.item?.center_users?.map((i: any) => i.user_id)
	}

	componentDidMount() {
		this.loadUsers();
	}

	loadUsers = async () => {
		const res: any = await HealthCenterService.admin.getUsers();
		this.setState({
			users: res.users
		});
	}
	
	render() {
		return (
			<div id="modal-create-health-center">
				<div className="row">
					<div className="col-md-12">
      					<div className="row row-schedules row-schedules-modal">
							{
								this.state.health_users.map((item: any, index: number) => {
									const user: any = this.state.users.find((i: any) => i.id == item);

									return (
										<div className="col-md-12">
											<div className="item-service">
												<p>{ user?.fullName }</p>
											</div>
										</div>
									)
								})
							}
						</div>
      				</div>
				</div>
			</div>
		)
	}
}

export default ModalServices;