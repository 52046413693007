import React from 'react';
import { Input, Button, PaperClip, Select, CheckboxTable, DatePicker, Modal, Textarea } from 'components';
import { PlanService } from 'services';
import { RootState } from 'reducers';
import { RouteComponentProps } from 'react-router-dom';
import { Globals, Constants, Socket, SocketEvents } from 'utils';
import { WhiteImage2 } from 'assets/img';
import moment from 'moment';
import { AddIcon, TrashIcon } from 'assets/icons';

interface Props {
	onClose?: any;
	item?: any;
	type_id?: number;
}

class ModalCreate extends React.Component<Props> {

	state = {
		form: {
			name: '',
			description: '',
			photo: '',
			price: '',
			service_id: '',
			gifted_tickets: 0,
			emergency_phone: ''
		},
		services: [],
		plan_services: this.props.item?.services?.map((i: any) => {
			return {
				service_id: i.service_id,
				unlimited: i.unlimited || false,
				quantity: i.quantity || 0
			}
		}) || [],
	}

	componentDidMount() {
		if (this.props.item) {
			this.setState({
				form: {
					...this.state.form,
					name: this.props.item?.name || '',
					description: this.props.item?.description || '',
					price: this.props.item?.price || '',
					gifted_tickets: this.props.item?.gifted_tickets || 0,
					emergency_phone: this.props.item?.emergency_phone || ''
				}
			});
		}

		this.loadServices();
	}

	loadServices = async (withoutLoading: boolean = false) => {
		const res: any = await PlanService.admin.getServices({
			withoutLoading
		});
		this.setState({
			services: res.services
		});
	}

	submit = async (e: any) => {
		e.preventDefault();

		if (this.state.plan_services.find((i: any) => !i.unlimited && !i.quantity)) {
			Globals.showError("Debe ingresar las cantidades de uso de los servicios");
			return false;
		}

		if (this.props.item != null) {
			await PlanService.admin.update({
				plan_id: this.props.item?.id,
				...this.state.form,
				hasFile: true,
				// @ts-ignore,
				photo: this.state.form.photo?.value || '',
				services: JSON.stringify(this.state.plan_services),
				type_id: this.props.item?.type_id
			});
			Globals.showSuccess("Se ha modificado el plan correctamente");
			this.props.onClose();
		}
		else {
			await PlanService.admin.create({
				...this.state.form,
				hasFile: true,
				// @ts-ignore,
				photo: this.state.form.photo?.value || '',
				services: JSON.stringify(this.state.plan_services),
				type_id: this.props.type_id || Constants.PLAN_TYPES.HEALTH
			});
			Globals.showSuccess("Se ha creado el plan correctamente");
			this.props.onClose();
		}
	}

	change = (e: any,callback = () => {}) => {
		this.setState({
			form: {
				...this.state.form,
				[e.target.name]: e.target.value
			}
		},callback);
	}

	deleteService = (index: number) => {
		let plan_services: any = [...this.state.plan_services];
		plan_services.splice(index,1);
		this.setState({
			plan_services
		});
	}
		
	addService = (e: any) => {
		let plan_services: any = [...this.state.plan_services];
		plan_services.push({
			service_id: parseInt(e.target.value),
			unlimited: false,
			quantity: 0
		});
		this.setState({
			plan_services,
			form: {
				...this.state.form,
				service_id: ''
			}
		});
	}

	delete = async () => {
		Globals.confirm('¿Desea eliminar el plan?',async () => {
			await PlanService.admin.delete({
				plan_id: this.props.item?.id
			});
			this.props.onClose();
		});
	}
	
	render() {
		const plan_services: any = this.state.plan_services;

		return (
			<div id="modal-create-service" className="modal-create-plan">
      			<form className="row" onSubmit={ this.submit }>
      				<div className="col-4">
						<div className="container-image">
							<PaperClip
								className="paperclip-image"
								value={ this.state.form.photo }
								width={ 1080 }
								height={ 1080 }
								onChange={ (e: any) => {
									this.setState({
										form: {
											...this.state.form,
											photo: e.target
										}
									})
								} }>
								{/*
								// @ts-ignore */}
								<img className={ `selected-image ${ (this.state.form.photo || this.props.item?.photo) ? '' : 'inactive' }` } src={ this.state.form.photo?.dataURL || (this.props.item?.photo ? (Constants.BASE_STORAGE + this.props.item?.photo) : WhiteImage2) } />
							</PaperClip>
						</div>
					</div>
					<div className="col-8 container-text-image">
						<h3>Subir Imagen</h3>
						<p>Tamaño máximo 1080px por 1080px</p>
					</div>
					<div className="col-md-12">
						<Input
							color="white"
							value={ this.state.form.name }
							name="name" 
							label="Nombre"
							onChange={ this.change } />
					</div>
					<div className={ this.props.type_id != Constants.PLAN_TYPES.CLUB ? 'col-md-6' : 'col-md-12' }>
						<Textarea
							rows={ 3 }
							name="description" 
							label="Descripción de beneficios"
							onChange={ this.change }
		                    value={ this.state.form.description } />
					</div>
					{
						this.props.type_id != Constants.PLAN_TYPES.CLUB && (
							<div className="col-md-6">
								<Input
									type="number"
									color="white"
									value={ this.state.form.emergency_phone }
									name="emergency_phone" 
									label="Número de emergencia"
									onChange={ this.change } />
							</div>
						)
					}
					<div className="col-md-12 container-select-services">
						<Select
							label="Servicios (Opcional)"
							name="service_id"
							placeholder="Seleccione un servicio a la vez"
							onChange={ (e: any) => this.addService(e) }
							value={ this.state.form.service_id }
							options={ this.state.services.filter((i: any) => plan_services.indexOf(i.id) == -1).map((item: any) => {
								return {
									value: item.id,
									label: item.name
								}
							}) } />
					</div>
					<div className="row row-schedules">
						{
							this.state.plan_services.length > 0 && (
								<div className="row col-12">
									<div className="col-md-6">
										<p className="label-plan-service">Servicios</p>
									</div>
									<div className="col-md-5 text-right">
										<p className="label-plan-service">Cant. de uso</p>
									</div>
									<div className="col-md-1"></div>
								</div>
							)
						}
						
						{
							this.state.plan_services.map((item: any, index: number) => {
								const service: any = this.state.services.find((i: any) => i.id == item.service_id);

								return (
									<div className="col-md-12">
										<div className="item-service">
											<div className="col-md-6">
												<p>{ service?.name }</p>
											</div>
											<div className="col-md-5 text-right">
												<div className="container-unlimited">
													<div className="container-check" onClick={ () => {
														let plan_services = [...this.state.plan_services];
														plan_services[index].unlimited = !plan_services[index].unlimited;
														this.setState({
															plan_services
														});
													} }>
														<div className={ `check ${ item.unlimited ? 'active' : '' }` }></div>
														<p>Ilimitado</p>
													</div>
													<div className="container-input-unlimited">
														{
															!item.unlimited && (
																<Input
																	type="number"
																	color="white"
																	value={ item.quantity }
																	name="quantity"
																	onChange={ (e: any) => {
																		let plan_services = [...this.state.plan_services];
																		plan_services[index].quantity = e.target.value;
																		this.setState({
																			plan_services
																		});
																	} } />
															)
														}
													</div>
												</div>
											</div>
											<div className="col-md-1">
												<a href="#" className="trash-container" onClick={ (e: any) => {
													e.preventDefault();
													this.deleteService(index);
												} }>
													<img src={ TrashIcon } />
												</a>
											</div>
										</div>
									</div>
								)
							})
						}
					</div>
					{
						this.props.type_id == Constants.PLAN_TYPES.CLUB && (
							<React.Fragment>
								<div className="col-md-6">
									<p className="gifted-tickets">Cantidad de Tickets a obsequiar</p>
								</div>
								<div className="col-md-6">
									<Input
										type="number"
										color="white"
										value={ this.state.form.gifted_tickets }
										name="gifted_tickets" 
										placeholder="Cantidad"
										onChange={ this.change } />
								</div>
							</React.Fragment>
						)	
					}
					<div className="col-md-12 text-center">
						<Input
							label="Costo (US $)"
							className="input-decimal"
							type="decimal-price"
							color="white"
							value={ this.state.form.price ? ('$' + this.state.form.price) : '' }
							name="price" 
							placeholder="$0"
							onChange={ this.change } />
					</div>
					<div className="col-12">
						<div className={ `row row-buttons ${ this.props.type_id == Constants.PLAN_TYPES.CLUB && this.props.item ? 'button-delete' : '' }` }>
							{
								this.props.type_id == Constants.PLAN_TYPES.CLUB && this.props.item && (
									<div className="col-md-6 text-center">
										<Button type="button" className="btn-delete" onClick={ () => this.delete() }>
											Eliminar
										</Button>
									</div>
								)
							}
							<div className={ `col-md-${ this.props.type_id == Constants.PLAN_TYPES.CLUB && this.props.item ? '6' : '12' } text-center` }>
								<Button type="submit">
									Guardar
								</Button>
							</div>
						</div>
					</div>
				</form>
			</div>
		)
	}
}

export default ModalCreate;