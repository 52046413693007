import { Api } from 'utils';

class HealthCenterService {

	static admin = {
		get: async (params?: any) => Api.createResponse('admin/health-centers/get',params),
		getUsers: async (params?: any) => Api.createResponse('admin/health-centers/users',params),
		create: async (params?: any) => Api.createResponse('admin/health-centers/create',params),
		update: async (params?: any) => Api.createResponse('admin/health-centers/update',params),
		delete: async (params?: any) => Api.createResponse('admin/health-centers/delete',params)
	}

}

export default HealthCenterService;