import React from 'react';
import { connect } from 'react-redux';
import { RootState } from 'reducers';
import { RouteComponentProps, Link } from 'react-router-dom';
import { AddIcon } from 'assets/icons';
import { Globals, Constants, Colors, checkPermission, Permissions } from 'utils';
import { PlanService } from 'services';
import { WhiteImage } from 'assets/img';
import { Button, Modal } from 'components';
import ModalCreate from 'screens/admin/plans/modal-create';

interface Props extends RootState, RouteComponentProps {
	dispatch: any
}

class AdminClub extends React.Component<Props> {

	state = {
		visible: false,
		item: null,
		plans: []
	}

	componentDidMount() {
		this.props.dispatch({
			type: 'SET_HEADER',
			payload: {
				title: 'Mi Club',
				onBack: null
			}
		});

		this.props.dispatch({
			type: 'SET_SUBHEADER',
			payload: {
				title: ''
			}
		});

		this.load();
	}

	load = async (withoutLoading: boolean = false) => {
		const res: any = await PlanService.admin.getAll({
			withoutLoading,
			type_id: Constants.PLAN_TYPES.CLUB
		});
		this.setState({
			plans: res.plans
		});
	}

	add = () => {
		this.setState({
			visible: true
		});
	}	

	onClose = () => {
		this.setState({
			visible: false,
			item: null
		},() => this.load(true));
	}

	edit = (item: any) => {
		this.setState({
			visible: true,
			item
		});	
	}
	
	render() {
		const { visible } = this.state;

		return (
			<div id="admin-club">
				<Modal
		          className="modal-create-service"
		          visible={ visible }
		          title={ `${ this.state.item != null ? 'Editar' : 'Crear' } Plan` }
		          onClose={ () => this.onClose() }
		        >
		          <ModalCreate
		          	type_id={ Constants.PLAN_TYPES.CLUB }
		          	item={ this.state.item }
		            onClose={ () => this.onClose() } />
		      	</Modal>

				<div className="row row-add">
					<div className="col-md-6">
						<h3>Planes Actuales</h3>
					</div>
					<div className="col-md-6 text-right">
						{
							checkPermission(Constants.MODULES.CLUB,'write',this.props.user) && (
								<Button className="btn-add" onClick={ () => this.add() }>
									<img src={ AddIcon } />
									Nuevo
								</Button>
							)
						}
					</div>
					{
						this.state.plans.map((item: any) => {
							return (
								<div className="col-md-4">
									<div className="item-plan" onClick={ () => this.edit(item) }>
										<img src={ item.photo ? (Constants.BASE_STORAGE + item.photo) : WhiteImage } />
										<div>
											<p>{ item.name }</p>
											<p className="price">{ Globals.formatMiles(item.price) }</p>
										</div>
									</div>
								</div>
							)
						})
					}
					{
						this.state.plans.length == 0 && (
							<p className="no-items">No se han encontrado registros</p>
						)
					}
				</div>
			</div>
		)
	}
}

export default connect((state: RootState) => {
	return {
		user: state.user
	}
})(AdminClub);