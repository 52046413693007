import React from 'react';
import { Input, Button, PaperClip, Select, CheckboxTable, DatePicker, Modal, Textarea, SelectSearch } from 'components';
import { RootState } from 'reducers';
import { RouteComponentProps } from 'react-router-dom';
import { Globals, Constants, Socket, SocketEvents, Colors } from 'utils';
import moment from 'moment';
import { CardService } from 'services';

interface Props {
	onClose?: any;
	item?: any;
	sellers?: any;
}

class ModalCreate extends React.Component<Props> {

	state = {
		form: {
			quantity: '',
			plan_id: '',
			seller_id: ''
		},
		plans: []
	}

	componentDidMount() {
		this.load();
	}

	load = async () => {
		const res: any = await CardService.admin.getPlans();
		this.setState({
			plans: res.plans || []
		});
	}

	change = (e: any,callback = () => {}) => {
		this.setState({
			form: {
				...this.state.form,
				[e.target.name]: e.target.value
			}
		},callback);
	}

	submit = async (e: any) => {
		e.preventDefault();
		await CardService.admin.create({
			...this.state.form
		});
		this.props.onClose();
	}
	
	render() {
		const seller = (this.props.sellers || []).find((i: any) => i.id == this.state.form.seller_id);

		return (
			<div id="modal-create-cards">
				<form className="row" onSubmit={ this.submit }>
					<div className="col-md-12">
						<Input
							type="number"
							color="white"
							value={ this.state.form.quantity }
							name="quantity" 
							label="Cantidad a generar (Máx: 100)"
							onChange={ this.change } />
					</div>
					<div className="col-md-12">
						<Select
							label="Plan"
							name="plan_id"
							placeholder="Seleccionar"
							onChange={ this.change }
							value={ this.state.form.plan_id }
							options={ this.state.plans.map((item: any) => {
								return {
									value: item.id,
									label: item.name
								}
							}) } />
					</div>
					<div className="col-md-12">
						<SelectSearch
							placeholder="Seleccione"
							label="Vendedor (Opcional)"
							onChange={ (seller: any) => {
								this.setState({
									form: {
										...this.state.form,
										seller_id: seller?.value
									}
								});
							} }
							color="white"
							searchRight
							value={ seller ? {
								value: seller.id,
								label: seller.fullName
							} : null }
							options={ (this.props.sellers || []).map((item: any) => {
								return {
									label: item.fullName,
									value: item.id
								}
							}) } />
					</div>
					<div className="col-md-12 text-center container-btn-submit">
						<Button type="submit">
							Guardar
						</Button>
					</div>
				</form>
			</div>
		)
	}
}

export default ModalCreate;