import React from 'react';
import { connect } from 'react-redux';
import { RootState } from 'reducers';
import { RouteComponentProps, Link } from 'react-router-dom';
import { ArrowRight, AddIcon, DeleteIcon, EditIcon, IconUsers } from 'assets/icons';
import { Globals, Constants, Colors, checkPermission, Permissions } from 'utils';
import { HomeService } from 'services';
import { WhiteImage } from 'assets/img';
import { Table, Button, Tooltip } from 'components';
import moment from 'moment';

interface Props extends RootState, RouteComponentProps {
	dispatch: any
}

declare const Chart: any;

class Home extends React.Component<Props> {

	state = {
		plans: [],
		pieChart: null,
		barChart: null,
		barBottomChart: null,
		total_club: 0,
		plan_earnings: [],
		services: [],
		expiration_plans: []
	}

	componentDidMount() {
		this.props.dispatch({
			type: 'SET_HEADER',
			payload: {
				title: 'Inicio',
				onBack: null
			}
		});

		this.props.dispatch({
			type: 'SET_SUBHEADER',
			payload: {
				title: ''
			}
		});

		this.load();
	}

	load = async () => {
		const res: any = await HomeService.admin.get();
		let n = 1;
		for (let i = 0; i < res.plan_earnings.length; i++) {
			switch(n) {
				case 1:
					res.plan_earnings[i].color = Colors.chartGreen;
				break;

				case 2:
					res.plan_earnings[i].color = Colors.chartLeaf;
				break;

				case 3:
					res.plan_earnings[i].color = Colors.chartBlue;
				break;
			}

			n++;
			if (n > 3) {
				n = 1;
			}
		}
		this.setState({
			plans: res.plans,
			total_club: res.total_club,
			plan_earnings: res.plan_earnings || [],
			services: res.services || []
		},() => {
			this.loadPieChart();
			this.loadBarChart();
			this.loadBarBottomChart();
		});
	}

	loadPieChart = () => {
		if (this.state.pieChart) {
			// @ts-ignore
			this.state.pieChart.destroy();
		}

		const ctx: any = document.getElementById('pie-chart');

		this.setState({
			pieChart: new Chart(ctx, {
			    type: 'pie',
			    data: {
				  labels: this.state.plan_earnings.map((item: any) => item.name),
				  datasets: [{
				    data: this.state.plan_earnings.map((item: any) => item.quantity),
				    backgroundColor: this.state.plan_earnings.map((item: any) => item.color)
				  }]
				},
				options: {
					responsive: false,
					plugins: {
				      legend: false
				    }
				}
			})
		});
	}

	loadBarChart = () => {
		if (this.state.barChart) {
			// @ts-ignore
			this.state.barChart.destroy();
		}

		const ctx = document.getElementById('bar-chart');

		this.setState({
			pieChart: new Chart(ctx, {
			  type: 'bar',
			  data: {
				  labels: this.state.plan_earnings.map((item: any) => item.name),
				  datasets: [{
				  	data: this.state.plan_earnings.map((item: any) => item.earning),
				  	backgroundColor: this.state.plan_earnings.map((item: any) => item.color)
				  }]
			  },
			  options: {
			    indexAxis: 'y',
			    maintainAspectRatio: false,
    			responsive: true, 
			    elements: {
			      bar: {
			        borderWidth: 0,
			      }
			    },
			    plugins: {
			      legend: false
			    }
			  },
			})
		});
	}

	loadBarBottomChart = () => {
		if (this.state.barBottomChart) {
			// @ts-ignore
			this.state.barBottomChart.destroy();
		}

		const ctx = document.getElementById('bar-bottom-chart');

		this.setState({
			pieChart: new Chart(ctx, {
			  type: 'bar',
			  data: {
				  labels: this.state.services.map((item: any) => item.service?.name),
				  datasets: [{
				  	data: this.state.services.map((item: any) => item.count),
				  	backgroundColor: [
				  		Colors.chartBlue,
				  		Colors.chartLeaf,
				  		Colors.chartYellow,
				  		Colors.chartRed,
				  		Colors.chartBlue,
				  		Colors.chartLeaf,
				  		Colors.chartYellow
				  	]
				  }]
			  },
			  options: {
			    indexAxis: 'x',
			    elements: {
			      bar: {
			        borderWidth: 0,
			      }
			    },
			    plugins: {
			      legend: false
			    }
			  },
			})
		});
	}
	
	render() {
		const total = this.state.plan_earnings.map((item: any) => item.quantity).reduce((a: number, b: number) => a + b,0);

		return (
			<div id="admin-home">
				<div className="row">
					<div className="col-md-3">
						<h3>Suscripciones</h3>
						<div className="container-white">
							<canvas id="pie-chart" width="170" height="170"></canvas>
							<div className="wrapper-legends">
								{
									this.state.plan_earnings.map((item: any) => {
										return (
											<div className="container-legend">
												<div style={{
													backgroundColor: item.color
												}}></div>
												<p>{ item.name } - { Globals.formatMiles((item.quantity * 100) / total,true,'') }%</p>
											</div>
										)
									})
								}
							</div>
						</div>
						<h3>Ingresos por Membresía</h3>
						<div className="container-white container-white-earnings">
							<p>($ Totales - { moment().format('MMM YYYY') })</p>
							<canvas id="bar-chart"></canvas>
						</div>
					</div>
					<div className="col-md-3">
						<h3>Contador de Afiliados</h3>
						<div className="container-afiliados">
							<img src={ IconUsers } />
							<p>{ this.state.total_club }</p>
						</div>
						<h3>Mi Club</h3>
						<h4>Planes Actuales</h4>
						{
							this.state.plans?.map((item: any) => {
								return (
									<div className="container-plan">
										<div className="container-image-plan">
											{ item?.photo && <img src={ Constants.BASE_STORAGE + item?.photo } /> }
										</div>
										<div>
											<p className="name">{ item.name }</p>
											<p>{ Globals.formatMiles(item.price) }</p>
										</div>
									</div>
								)
							})
						}
						<Link to="/admin/club" className="arrow-right-container">
							Ver más
							<img className="arrow-right" src={ ArrowRight } />
						</Link>
					</div>
					<div className="col-md-6">
						<h3>Membresías a vencer próximos 30 días</h3>
						<div className="container-white">
							<div className="row">
								{
									this.state.plan_earnings.map((item: any) => {
										return (
											<div className="col-md-4 text-center">
												<div className="container-title-plan">
													<h4>{ item.name }</h4>
												</div>
												<h5>{ item.expirations }</h5>
											</div>
										)
									})
								}
							</div>
							<Link to="/admin/reports/club" className="arrow-right-container">
								Ver más
								<img className="arrow-right" src={ ArrowRight } />
							</Link>
						</div>
						<h3>Servicios Prestados</h3>
						<div className="container-white">
							<p>Últimos 30 días</p>
							<canvas id="bar-bottom-chart"></canvas>
							<Link to="/admin/reports/services" className="arrow-right-container">
								Ver más
								<img className="arrow-right" src={ ArrowRight } />
							</Link>
						</div>
					</div>
				</div>
			</div>
		)
	}
}

export default connect((state: RootState) => {
	return {
		user: state.user
	}
})(Home);