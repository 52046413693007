import React from 'react';
import { Input, Button, PaperClip, Select, CheckboxTable, DatePicker } from 'components';
import { SellerService } from 'services';
import { RootState } from 'reducers';
import { RouteComponentProps } from 'react-router-dom';
import { Globals, Constants, Socket, SocketEvents } from 'utils';
import { WhiteImage } from 'assets/img';
import moment from 'moment';

interface Props {
	onClose?: any;
	item?: any;
}

class ModalCreate extends React.Component<Props> {

	state = {
		form: {
			name: '',
			lastname: '',
	        email: '',
	        photo: '',
	        password: '',
	        password_confirmation: ''
		}
	}

	componentDidMount() {
		if (this.props.item) {
			this.setState({
				form: {
					...this.state.form,
					name: this.props.item?.person?.name || '',
					lastname: this.props.item?.person?.lastname || '',
			        email: this.props.item?.email || '',
				}
			});
		}
	}

	submit = async (e: any) => {
		e.preventDefault();
		if (this.props.item != null) {
			await SellerService.admin.update({
				user_id: this.props.item?.id,
				...this.state.form,
				hasFile: true,
				// @ts-ignore,
				photo: this.state.form.photo?.value || '',
			});
			Globals.showSuccess("Se ha modificado el vendedor correctamente");
			Socket.emit(SocketEvents.UPDATE_USER,{
				user_id: this.props.item?.id
			});
			this.props.onClose();
		}
		else {
			await SellerService.admin.create({
				...this.state.form,
				hasFile: true,
				// @ts-ignore,
				photo: this.state.form.photo?.value || '',
			});
			Globals.showSuccess("Se ha creado el vendedor correctamente");
			this.props.onClose();
		}
	}

	change = (e: any,callback = () => {}) => {
		this.setState({
			form: {
				...this.state.form,
				[e.target.name]: e.target.value
			}
		},callback);
	}
	
	render() {
		return (
			<div id="modal-create-user">
				<form className="row" onSubmit={ this.submit }>
					<div className="col-12">
						<div className="container-image">
							<PaperClip
								className="paperclip-image"
								value={ this.state.form.photo }
								onChange={ (e: any) => {
									this.setState({
										form: {
											...this.state.form,
											photo: e.target
										}
									})
								} }>
								{/*
								// @ts-ignore */}
								<img className={ `selected-image ${ (this.state.form.photo || this.props.item?.person?.photo) ? '' : 'inactive' }` } src={ this.state.form.photo?.dataURL || (this.props.item?.person?.photo ? (Constants.BASE_STORAGE + this.props.item?.person?.photo) : WhiteImage) } />
							</PaperClip>
						</div>
					</div>
					<div className="col-md-6">
						<Input
							color="white"
							value={ this.state.form.name }
							name="name" 
							placeholder="Nombre"
							onChange={ this.change } />
					</div>
					<div className="col-md-6">
						<Input
							color="white"
							value={ this.state.form.lastname }
							name="lastname" 
							placeholder="Apellido"
							onChange={ this.change } />
					</div>
					<div className="col-md-6">
						<Input
							color="white"
							value={ this.state.form.email }
							name="email" 
							placeholder="Correo Electrónico"
							onChange={ this.change } />
					</div>
					<div className="col-md-6">
						<Input
							type="password"
							color="white"
							value={ this.state.form.password }
							name="password" 
							placeholder={ this.props.item != null ? "Cambiar Contraseña (Opcional)" : "Contraseña" }
							onChange={ this.change } />
					</div>
					<div className="col-md-6">
						<Input
							type="password"
							color="white"
							value={ this.state.form.password_confirmation }
							name="password_confirmation" 
							placeholder="Repetir contraseña"
							onChange={ this.change } />
					</div>
					<div className="col-12">
						<div className="row row-buttons">
							<div className="col-md-12 text-center">
								<Button type="submit">
									Guardar
								</Button>
							</div>
						</div>
					</div>
				</form>
			</div>
		)
	}
}

export default ModalCreate;