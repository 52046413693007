import { Api } from 'utils';

class SellerService {

	static admin = {
		get: async (params?: any) => Api.createResponse('admin/sellers/get',params),
		create: async (params?: any) => Api.createResponse('admin/sellers/create',params),
		update: async (params?: any) => Api.createResponse('admin/sellers/update',params),
		delete: async (params?: any) => Api.createResponse('admin/sellers/delete',params)
	}

}

export default SellerService;