import { Api } from 'utils';

class PaymentManagementService {

	static admin = {
		get: async (params?: any) => Api.createResponse('admin/payment-management',params),
		getPaymentMethods: async (params?: any) => Api.createResponse('admin/payment-management/payment-methods',params),
		save: async (params?: any) => Api.createResponse('admin/payment-management/save',params),
	}

	static practicing = {
		get: async (params?: any) => Api.createResponse('practicing/payment-report',params),
		getServices: async (params?: any) => Api.createResponse('practicing/payment-report/services',params),
		print: async (params?: any) => Api.createResponse('practicing/payment-report/print',params),
	}

}

export default PaymentManagementService;