import React from 'react';
import { Input, Button, PaperClip, Select, CheckboxTable, DatePicker, Modal, Textarea } from 'components';
import { RootState } from 'reducers';
import { RouteComponentProps } from 'react-router-dom';
import { Globals, Constants, Socket, SocketEvents, Colors } from 'utils';
import moment from 'moment';
import { ProductService } from 'services';
import { AddCircleIcon, CancelIcon } from 'assets/icons';

interface Props {
	onClose?: any;
	item?: any;
	categories?: any;
}

class ModalEdit extends React.Component<Props> {

	state = {
		images: this.props.item?.images,
		form: {
			category_id: ''
		}
	}

	componentDidMount() {
		if (this.props.item) {
			this.setState({
				form: {
					...this.state.form,
					category_id: this.props.item?.category_id || ''
				}
			});
		}
	}

	change = (e: any,callback = () => {}) => {
		this.setState({
			page: 1,
			form: {
				...this.state.form,
				[e.target.name]: e.target.value
			}
		},callback);
	}

	submit = async (e: any) => {
		e.preventDefault();
		await ProductService.admin.save({
			product_id: this.props.item?.id,
			...this.state.form,
			hasFile: true,
			images: this.state.images.filter((i: any) => i.dataURL).map((i: any) => i.value),
			images_ids: JSON.stringify(this.state.images.filter((i: any) => !i.dataURL).map((i: any) => i.id))
		});
		Globals.showSuccess("Se ha actualizado el producto");
		this.props.onClose();
	}

	deleteImage = (item: any, index: number) => {
		let images: any = [...this.state.images];
		images.splice(index,1);
		this.setState({
			images
		});
	}
	
	render() {
		return (
			<div id="modal-edit-product">
				<form className="row" onSubmit={ this.submit }>
					<div className="col-md-12">
						<Select
							placeholder="Seleccionar"
							label="Categoría"
							name="category_id"
							onChange={ (e: any) => this.change(e) }
							value={ this.state.form.category_id }
							options={ (this.props.categories || []).map((item: any) => {
								return {
									value: item.id,
									label: item.name
								}
							}) } />
					</div>
					<div className="col-md-12">
						<p className="label-images">Imagenes</p>
						<div className="container-images">
							{
								this.state.images.map((item: any, index: number) => {
									return (
										<div className="container-image">
											<img src={ CancelIcon } className="cancel-icon" onClick={ () => this.deleteImage(item,index) } />
											<img src={ item?.dataURL || (Constants.BASE_STORAGE + item?.file) } />
										</div>
									)
								})
							}
							<PaperClip
								onChange={ (e: any) => {
									let images: any = [...this.state.images];
									images.push(e.target);
									this.setState({
										images
									});
								} }>
								<img src={ AddCircleIcon } className="add-circle" />
							</PaperClip>
						</div>
					</div>

					<div className="col-md-12 text-center">
						<Button type="submit">
							Guardar
						</Button>
					</div>
				</form>
			</div>
		)
	}
}

export default ModalEdit;