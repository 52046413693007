import React from 'react';
import { connect } from 'react-redux';
import { RootState } from 'reducers';
import { RouteComponentProps, Link } from 'react-router-dom';
import { ArrowRight, PhoneIcon, WebIcon, InstagramIcon, FacebookIcon, CleanIcon, DeleteIcon, EditIcon, AddIcon } from 'assets/icons';
import { Globals, Constants, Colors, checkPermission, Permissions } from 'utils';
import { SurgerieService } from 'services';
import { WhiteImage } from 'assets/img';
import { Input, Tooltip, Button, Table, Pagination, Modal } from 'components';
import ModalCreate from './modal-create';

interface Props extends RootState, RouteComponentProps {
	dispatch: any
}

const INITIAL_STATE = {
	search: ''
}

class AdminSurgeries extends React.Component<Props> {

	timer: any = undefined;

	state = {
		data: [],
		page: 1,
		last_page: 1,
		header: [
			'Nombre Cirugía/Procedimiento',
			'Costo',
			'Acción'
		],
		form: INITIAL_STATE,
		visible: false,
		item: null,
	}

	componentDidMount() {
		this.props.dispatch({
			type: 'SET_HEADER',
			payload: {
				title: 'Cirugías o Procedimientos',
				onBack: null
			}
		});

		this.props.dispatch({
			type: 'SET_SUBHEADER',
			payload: {
				title: ''
			}
		});

		this.load();
	}

	load = async (withoutLoading: boolean = false) => {
		const res: any = await SurgerieService.admin.get({
			withoutLoading,
			page: this.state.page,
			...this.state.form,
		});
		this.setState({
			data: res.surgeries.rows,
			last_page: res.surgeries.count
		});
	}

	change = (e: any,callback = () => {}) => {
		this.setState({
			page: 1,
			form: {
				...this.state.form,
				[e.target.name]: e.target.value
			}
		},callback);
	}

	reset = () => {
		this.setState({
			form: INITIAL_STATE,
			page: 1
		},() => this.load(true));
	}

	onClose = () => {
		this.setState({
			visible: false,
			item: null
		},() => this.load(true));
	}

	add = () => {
		this.setState({
			visible: true
		});
	}

	delete = async (e: any, item: any) => {
		e.preventDefault();
		Globals.confirm('¿Desea eliminar la cirugía/procedimiento?',async () => {
			await SurgerieService.admin.delete({
				surgerie_id: item.id
			});
			this.load(true);
		});
	}

	edit = (e: any, item: any) => {
		e.preventDefault();
		this.setState({
			visible: true,
			item
		});	
	}
	
	render() {
		const { visible } = this.state;

		return (
			<div id="admin-surgeries">
				<Modal
		          className="modal-create-surgeries"
		          visible={ visible }
		          title={ `${ this.state.item != null ? 'Editar' : 'Crear' } Cirugía o Procedimiento` }
		          onClose={ () => this.onClose() }
		        >
		          <ModalCreate
		          	item={ this.state.item }
		            onClose={ () => this.onClose() } />
		      	</Modal>

				<div className="row row-filter">
					<div className="col-md-3">
						<Input
							value={ this.state.form.search }
							name="search"
							label="Nombre de la Cirugía/Procedimiento"
							placeholder="Buscar"
							className="input-table"
							onChange={ (e: any) => {
								clearTimeout(this.timer);
								this.timer = setTimeout(() => {
									this.load(true);
								},1000);
								this.change(e); 
							} } />
					</div>
					<div className="col-md-3">
						{
							this.state.form != INITIAL_STATE && (
								<button className="btn btn-clean" onClick={ () => this.reset() }>
									<img src={ CleanIcon } />
								</button>
							)
						}
					</div>
				</div>

				<Table title="Cirugías o Procedimientos Registrados" data={ this.state.data.length } rightAlign={[ 2 ]} header={ this.state.header } titleRight={
					<React.Fragment>
						{
							checkPermission(Constants.MODULES.FINANCING,'write',this.props.user) && (
								<Button className="btn-add" onClick={ () => this.add() }>
									<img src={ AddIcon } />
									Nuevo
								</Button>
							)
						}
					</React.Fragment>
				}>
					{ this.state.data.map((i: any,index: number) => {
						return (
							<tr key={ index }>
								<td>{ i.name }</td>
								<td>{ Globals.formatMiles(i.price) }</td>
								<td className="vertical-center text-right">
									{
										checkPermission(Constants.MODULES.FINANCING,'write',this.props.user) && (
											<Tooltip title="Editar">
												<a className="link-icon" href="#" onClick={ (e: any) => this.edit(e,i) }>
													<img src={ EditIcon } />
												</a>
											</Tooltip>
										)
									}
									{
										checkPermission(Constants.MODULES.FINANCING,'delete',this.props.user) && (
											<Tooltip title="Eliminar">
												<a className="link-icon" href="#" onClick={ (e: any) => this.delete(e,i) }>
													<img src={ DeleteIcon } />
												</a>
											</Tooltip>
										)
									}
								</td>
							</tr>
						)
					}) }
				</Table>

				<Pagination 
					pages={ this.state.last_page } 
					active={ this.state.page }
					onChange={ async (page: number) => {
						await this.setState({
							page: page
						});
						this.load();
					} } />
			</div>
		)
	}
}

export default connect((state: RootState) => {
	return {
		user: state.user
	}
})(AdminSurgeries);