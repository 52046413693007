import React from 'react';
import { connect } from 'react-redux';
import { RootState } from 'reducers';
import { RouteComponentProps, Link } from 'react-router-dom';
import { ArrowRight, AddIcon, DeleteIcon, EditIcon, IconUsers } from 'assets/icons';
import { Globals, Constants, Colors, checkPermission, Permissions } from 'utils';
import { HomeService } from 'services';
import { WhiteImage } from 'assets/img';
import { Table, Button, Tooltip } from 'components';
import moment from 'moment';

declare const Chart: any;

interface Props extends RootState, RouteComponentProps {
	dispatch: any
}

class Home extends React.Component<Props> {

	state = {
		pieChart: null,
		barChart: null,
		pending: 0,
		paid: 0,
		services: []
	}

	componentDidMount() {
		this.props.dispatch({
			type: 'SET_HEADER',
			payload: {
				title: 'Inicio',
				onBack: null
			}
		});

		this.props.dispatch({
			type: 'SET_SUBHEADER',
			payload: {
				title: ''
			}
		});

		this.load();
	}

	load = async () => {
		const res: any = await HomeService.practicing.get({
			user_id: this.props.user?.id
		});

		let n = 1;
		for (let i = 0; i < res.services.length; i++) {
			switch(n) {
				case 1:
					res.services[i].color = Colors.chartGreen;
				break;

				case 2:
					res.services[i].color = Colors.chartLeaf;
				break;

				case 3:
					res.services[i].color = Colors.chartBlue;
				break;

				case 4:
					res.services[i].color = Colors.chartMarine;
				break;
			}

			n++;
			if (n > 4) {
				n = 1;
			}
		}
		this.setState({
			pending: res.pending || 0,
			paid: res.paid || 0,
			services: res.services || []
		},() => {
			this.loadPieChart();
			this.loadBarChart();
		});
	}

	loadPieChart = () => {
		if (this.state.pieChart) {
			// @ts-ignore
			this.state.pieChart.destroy();
		}

		const ctx: any = document.getElementById('pie-chart');

		this.setState({
			pieChart: new Chart(ctx, {
			    type: 'pie',
			    data: {
				  labels: this.state.services.map((item: any) => item.service?.name),
				  datasets: [{
				    data: this.state.services.map((item: any) => item.count),
				    backgroundColor: [
				    	Colors.chartGreen,
				    	Colors.chartLeaf,
				    	Colors.chartBlue,
				    	Colors.chartMarine
				    ]
				  }]
				},
				options: {
					responsive: false,
					plugins: {
				      legend: false
				    }
				}
			})
		});
	}

	loadBarChart = () => {
		if (this.state.barChart) {
			// @ts-ignore
			this.state.barChart.destroy();
		}

		const ctx = document.getElementById('bar-chart');

		this.setState({
			pieChart: new Chart(ctx, {
			  type: 'bar',
			  data: {
				  labels: [
				  	'Monto pendiente $',
				  	'Monto pagado $'
				  ],
				  datasets: [{
				  	data: [this.state.pending,this.state.paid],
				  	backgroundColor: [Colors.chartRed, Colors.chartGreen]
				  }]
			  },
			  options: {
			    indexAxis: 'y',
			    elements: {
			      bar: {
			        borderWidth: 0,
			      }
			    },
			    plugins: {
			      legend: false
			    }
			  },
			})
		});
	}
	
	render() {
		const total = this.state.services.map((item: any) => item.count).reduce((a: number, b: number) => a + b,0);

		return (
			<div id="practicing-home">
				<div className="row">
					<div className="col-md-6">
						<h3>Cantidad de servicios prestados</h3>
						<div className="container-white">
							<p className="text-center">({ moment().startOf('month').format('DD') } al { moment().endOf('month').format('DD') } { moment().format('MMM YYYY') })</p>
							<canvas id="pie-chart" width="300" height="300"></canvas>
							<div className="wrapper-legends">
								{
									this.state.services.map((item: any) => {
										return (
											<div className="container-legend">
												<div style={{
													backgroundColor: item.color
												}}></div>
												<p>{ item.service?.name } - { Globals.formatMiles((item.count * 100) / total,true,'') }%</p>
											</div>
										)
									})
								}
							</div>
						</div>
					</div>
					<div className="col-md-6">
						<h3>Pagos por Servicios Prestados</h3>
						<div className="container-white">
							<p>($ Totales - { moment().format('MMM YYYY') })</p>
							<canvas id="bar-chart"></canvas>
						</div>
					</div>
				</div>
			</div>
		)
	}
}

export default connect((state: RootState) => {
	return {
		user: state.user
	}
})(Home);