import { Api } from 'utils';

class ConsultationTypeService {
	
	static admin = {
		get: async (params?: any) => Api.createResponse('admin/consultation-types/get',params),
		delete: async (params?: any) => Api.createResponse('admin/consultation-types/delete',params),
		update: async (params?: any) => Api.createResponse('admin/consultation-types/update',params),
		create: async (params?: any) => Api.createResponse('admin/consultation-types/create',params)
	}

}

export default ConsultationTypeService;