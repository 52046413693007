import React from 'react';
import { Input, Button, PaperClip, Select, CheckboxTable, DatePicker, Modal } from 'components';
import { PracticingService } from 'services';
import { RootState } from 'reducers';
import { RouteComponentProps } from 'react-router-dom';
import { Globals, Constants, Socket, SocketEvents } from 'utils';
import { WhiteImage } from 'assets/img';
import { TimeIcon, AddIcon, TrashIcon } from 'assets/icons';
import moment from 'moment';
import ModalSpecialities from 'screens/admin/services/modal-specialities';
import ModalTreatments from 'screens/admin/financing/modal-treatment';
import ModalStudies from 'screens/admin/services/modal-medical-studies';
import ModalImages from 'screens/admin/services/modal-medical-images';

interface Props {
	onClose?: any;
	item?: any;
}

class ModalCreate extends React.Component<Props> {

	state = {
		form: {
			name: '',
			lastname: '',
	        email: '',
	        phone: '',
	        password: '',
	        password_confirmation: '',
	        photo: '',
	        service_id: '',
	        treatment_id: '',
	        medical_study_id: '',
	        medical_image_id: '',
	        speciality_id: ''
		},
		medical_images_selected: [],
		medical_studies_selected: [],
		treatments_selected: [],
		specialities_selected: [],
		treatments: [],
		specialities: [],
		medical_studies: [],
		medical_images: [],
		visible: false,
		visible_treatments: false,
		visible_medical_studies: false,
		visible_medical_images: false,
		user_schedules: [{
			since: '',
			until: ''
		}],
		services: []
	}

	componentDidMount() {
		if (this.props.item) {
			this.setState({
				form: {
					...this.state.form,
					name: this.props.item?.person?.name || '',
					phone: this.props.item?.person?.phone || '',
					lastname: this.props.item?.person?.lastname || '',
			        email: this.props.item?.email || '',
			        schedule_since: this.props.item?.person?.schedule_since || '',
			        schedule_until: this.props.item?.person?.schedule_until || '',
				},
				user_schedules: this.props.item?.schedules?.map((i: any) => {
					return {
						since: moment(i.since,'HH:mm:ss').toDate(),
						until: moment(i.until,'HH:mm:ss').toDate(),
					}
				}) || [],
				specialities_selected: this.props.item?.services || []
			},async () => {
				const specialities_selected = [...this.state.specialities_selected];

				const res: any = await PracticingService.admin.checkValues({
					data: JSON.stringify(specialities_selected)
				});

				this.setState({
					specialities_selected: res.data
				});
			});
		}

		this.loadServices();
	}

	loadServices = async (withoutLoading = false) => {
		const res: any = await PracticingService.admin.getServices({
			withoutLoading
		});
		this.setState({
			services: res.services,
			specialities: res.specialities || [],
			treatments: res.treatments || [],
			medical_studies: res.medical_studies || [],
			medical_images: res.medical_images || [],
		});
	}

	submit = async (e: any) => {
		e.preventDefault();

		if (this.props.item != null) {
			await PracticingService.admin.update({
				user_id: this.props.item?.id,
				...this.state.form,
				hasFile: true,
				// @ts-ignore,
				photo: this.state.form.photo?.value || '',
				user_services: JSON.stringify(this.state.specialities_selected.map((item: any) => {
					return {
						...item
					}
				})),
				user_schedules: JSON.stringify(this.state.user_schedules.map((i: any) => {
					return {
						since: i.since && moment(i.since).format('HH:mm'),
						until: i.until && moment(i.until).format('HH:mm'),
					}
				}))
			});
			Globals.showSuccess("Se ha modificado el practicante correctamente");
			Socket.emit(SocketEvents.UPDATE_USER,{
				user_id: this.props.item?.id
			});
			this.props.onClose();
		}
		else {
			await PracticingService.admin.create({
				...this.state.form,
				hasFile: true,
				// @ts-ignore,
				photo: this.state.form.photo?.value || '',
				user_services: JSON.stringify(this.state.specialities_selected.map((item: any) => {
					return {
						...item
					}
				})),
				user_schedules: JSON.stringify(this.state.user_schedules.map((i: any) => {
					return {
						since: i.since && moment(i.since).format('HH:mm'),
						until: i.until && moment(i.until).format('HH:mm'),
					}
				}))
			});
			Globals.showSuccess("Se ha creado el practicante correctamente");
			this.props.onClose();
		}
	}

	change = (e: any,callback = () => {}) => {
		this.setState({
			form: {
				...this.state.form,
				[e.target.name]: e.target.value
			}
		},callback);
	}

	addSchedule = () => {
		let user_schedules: any = [...this.state.user_schedules];
		user_schedules.push({
			since: '',
			until: ''
		});
		this.setState({
			user_schedules
		});
	}

	changeSchedule = (value: string,target: string,index: number) => {
		let user_schedules: any = [...this.state.user_schedules];
		user_schedules[index][target] = value;
		this.setState({
			user_schedules
		});
	}

	deleteSchedule = (index: number) => {
		let user_schedules: any = [...this.state.user_schedules];
		user_schedules.splice(index,1);
		this.setState({
			user_schedules
		});
	}

	deleteMedicalStudy = (index: number) => {
		let medical_studies_selected: any = [...this.state.medical_studies_selected];
		medical_studies_selected.splice(index,1);
		this.setState({
			medical_studies_selected
		});
	}

	deleteMedicalImages = (index: number) => {
		let medical_images_selected: any = [...this.state.medical_images_selected];
		medical_images_selected.splice(index,1);
		this.setState({
			medical_images_selected
		});
	}

	deleteTreatments = (index: number) => {
		let treatments_selected: any = [...this.state.treatments_selected];
		treatments_selected.splice(index,1);
		this.setState({
			treatments_selected
		});
	}

	deleteSpeciality = (index: number) => {
		let specialities_selected: any = [...this.state.specialities_selected];
		specialities_selected.splice(index,1);
		this.setState({
			specialities_selected
		});
	}

	openSpecialities = () => {
		this.setState({
			visible: true
		});
	}

	openMedicalStudies = () => {
		this.setState({
			visible_medical_studies: true
		});
	}

	openMedicalImages = () => {
		this.setState({
			visible_medical_images: true
		});
	}

	openTreatments = () => {
		this.setState({
			visible_treatments: true
		});
	}

	onClose = () => {
		this.setState({
			visible: false
		},() => this.loadServices(true));
	}

	onCloseImages = () => {
		this.setState({
			visible_medical_images: false
		},() => this.loadServices(true));
	}

	onCloseStudies = () => {
		this.setState({
			visible_medical_studies: false
		},() => this.loadServices(true));
	}

	onCloseTreatments = () => {
		this.setState({
			visible_treatments: false
		},() => this.loadServices(true));
	}

	add = async () => {
		if (!this.state.form.service_id) {
			Globals.showError("Por favor, ingrese el servicio");
			return;
		}

		const service: any = await this.state.services.find((item: any) => item.id == this.state.form.service_id);

		if (!service?.use_price && !service?.require_form && this.state.treatments_selected.length == 0 && this.state.medical_studies_selected.length == 0 && this.state.medical_images_selected.length == 0) {
			Globals.showError("Por favor, ingrese un tratamiento/estudio/imagen");
			return;
		}

		let specialities_selected: any = [...this.state.specialities_selected];

		if (service?.use_price || service?.require_form) {
			const item = specialities_selected.find((i: any) => i.service_id == this.state.form.service_id);

			if (!item) {
				specialities_selected.push({
					speciality_id: null,
					treatment_id: null,
					price: '',
					price_app: '',
					percentage: '',
					service_id: this.state.form.service_id,
					index: specialities_selected.length
				});	
			}
		}

		for (let i = 0; i < this.state.treatments_selected.length; i++) {
			const treatment_id = this.state.treatments_selected[i];

			const item = specialities_selected.find((i: any) => i.speciality_id == this.state.form.speciality_id && i.service_id == this.state.form.service_id && i.treatment_id == treatment_id);

			if (!item) {
				specialities_selected.push({
					speciality_id: this.state.form.speciality_id || null,
					treatment_id,
					price: '',
					price_app: '',
					percentage: '',
					service_id: this.state.form.service_id,
					index: specialities_selected.length
				});	
			}
		}
 
		for (let i = 0; i < this.state.medical_images_selected.length; i++) {
			const medical_image_id = this.state.medical_images_selected[i];

			const item = specialities_selected.find((i: any) => i.speciality_id == this.state.form.speciality_id && i.service_id == this.state.form.service_id && i.medical_image_id == medical_image_id);

			if (!item) {
				specialities_selected.push({
					speciality_id: this.state.form.speciality_id || null,
					medical_image_id,
					price: '',
					price_app: '',
					percentage: '',
					service_id: this.state.form.service_id,
					index: specialities_selected.length
				});	
			}
		}

		for (let i = 0; i < this.state.medical_studies_selected.length; i++) {
			const medical_study_id = this.state.medical_studies_selected[i];

			const item = specialities_selected.find((i: any) => i.speciality_id == this.state.form.speciality_id && i.service_id == this.state.form.service_id && i.medical_study_id == medical_study_id);
			
			if (!item) {
				specialities_selected.push({
					speciality_id: this.state.form.speciality_id || null,
					medical_study_id,
					price: '',
					price_app: '',
					percentage: '',
					service_id: this.state.form.service_id,
					index: specialities_selected.length
				});	
			}
		}

		const res: any = await PracticingService.admin.checkValues({
			data: JSON.stringify(specialities_selected.filter((i: any,index: number) => i.index !== null))
		});

		for (let i = 0; i < res.data.length; i++) {
			const item = res.data[i];
			if (specialities_selected[item.index]) {
				specialities_selected[item.index].price = item.price || 0;
				specialities_selected[item.index].price_app = item.price_app || 0;
				specialities_selected[item.index].percentage = item.percentage || 0;
			}
		}

		await this.setState({
			form: {
				...this.state.form,
				speciality_id: '',
				service_id: ''
			},
			specialities_selected,
			medical_images_selected: [],
			medical_studies_selected: [],
			treatments_selected: [],
		});
	}

	changeSpeciality = (value: string,target: string,index: number) => {
		let specialities_selected: any = [...this.state.specialities_selected];
		specialities_selected[index][target] = value;
		this.setState({
			specialities_selected
		});
	}
	
	render() {
		const medical_images_selected: any = this.state.medical_images_selected;
		const treatments_selected: any = this.state.treatments_selected;
		const medical_studies_selected: any = this.state.medical_studies_selected;
		const specialities_selected: any = this.state.specialities_selected;
		const { visible, visible_medical_images, visible_medical_studies, visible_treatments } = this.state
		// @ts-ignore
		const services_list: any = [...new Set(this.state.specialities_selected.map((i: any) => i.service_id))].filter((i: any) => i != '');
		const service: any = this.state.services.find((item: any) => item.id == this.state.form.service_id);

		return (
			<div id="modal-create-user">
				<Modal
		          className="modal-create-service"
		          visible={ visible }
		          title="Especialidades"
		          animation={ false }
		          onClose={ () => this.onClose() }
		        >
		          {/* 
		          // @ts-ignore */}
		          <ModalSpecialities
		            onClose={ () => this.onClose() } />
		      	</Modal>

		      	<Modal
		          className="modal-create-service"
		          visible={ visible_medical_studies }
		          title="Estudios"
		          animation={ false }
		          onClose={ () => this.onCloseStudies() }
		        >
		          {/* 
		          // @ts-ignore */}
		          <ModalStudies
		            onClose={ () => this.onCloseStudies() } />
		      	</Modal>

		      	<Modal
		          className="modal-create-service"
		          visible={ visible_medical_images }
		          title="Imagenes"
		          animation={ false }
		          onClose={ () => this.onCloseImages() }
		        >
		          {/* 
		          // @ts-ignore */}
		          <ModalImages
		            onClose={ () => this.onCloseImages() } />
		      	</Modal>

		      	<Modal
		          className="modal-create-service"
		          visible={ visible_treatments }
		          title="Tratamientos"
		          animation={ false }
		          onClose={ () => this.onCloseTreatments() }
		        >
		          {/* 
		          // @ts-ignore */}
		          <ModalTreatments
		            onClose={ () => this.onCloseTreatments() } />
		      	</Modal>

				<form className="row" onSubmit={ this.submit }>
					<div className="col-12">
						<div className="container-image">
							<PaperClip
								className="paperclip-image"
								value={ this.state.form.photo }
								onChange={ (e: any) => {
									this.setState({
										form: {
											...this.state.form,
											photo: e.target
										}
									})
								} }>
								{/*
								// @ts-ignore */}
								<img className={ `selected-image ${ (this.state.form.photo || this.props.item?.person?.photo) ? '' : 'inactive' }` } src={ this.state.form.photo?.dataURL || (this.props.item?.person?.photo ? (Constants.BASE_STORAGE + this.props.item?.person?.photo) : WhiteImage) } />
							</PaperClip>
						</div>
					</div>
					<div className="col-md-6">
						<Input
							color="white"
							value={ this.state.form.name }
							name="name" 
							placeholder="Nombre"
							onChange={ this.change } />
					</div>
					<div className="col-md-6">
						<Input
							color="white"
							value={ this.state.form.lastname }
							name="lastname" 
							placeholder="Apellido"
							onChange={ this.change } />
					</div>
					<div className="col-md-6">
						<Input
							color="white"
							value={ this.state.form.phone }
							name="phone" 
							placeholder="Teléfono"
							onChange={ this.change } />
					</div>
					<div className="col-md-6">
						<Input
							color="white"
							value={ this.state.form.email }
							name="email" 
							placeholder="Correo Electrónico"
							onChange={ this.change } />
					</div>
					<div className="col-md-6">
						<Input
							type="password"
							color="white"
							value={ this.state.form.password }
							name="password" 
							placeholder={ this.props.item != null ? "Cambiar Contraseña (Opcional)" : "Contraseña" }
							onChange={ this.change } />
					</div>
					<div className="col-md-6">
						<Input
							type="password"
							color="white"
							value={ this.state.form.password_confirmation }
							name="password_confirmation" 
							placeholder="Repetir contraseña"
							onChange={ this.change } />
					</div>
					<div className="col-md-12 container-select-services">
						<Select
							label="Asignar servicios (Obligatorio)"
							name="service_id"
							placeholder="Seleccione un servicio a la vez"
							onChange={ (e: any) => this.change(e) }
							value={ this.state.form.service_id }
							options={ this.state.services.map((item: any) => {
								return {
									value: item.id,
									label: item.name
								}
							}) } />
					</div>
					{
						!service?.use_price && (
							<React.Fragment>
								<div className="col-md-6">
									<div className="container-select">
										<Select
											placeholder="Seleccionar"
											label="Estudios (Opcional)"
											name="medical_study_id"
											onChange={ (e: any) => {
												let medical_studies_selected: any = [...this.state.medical_studies_selected];
												medical_studies_selected.push(parseInt(e.target.value));
												this.setState({
													medical_studies_selected
												});
											} }
											value={ this.state.form.medical_study_id }
											options={ this.state.medical_studies.filter((i: any) => medical_studies_selected.indexOf(i.id) == -1).map((item: any) => {
												return {
													value: item.id,
													label: item.name
												}
											}) } />
										<Button className="add-icon" type="button" onClick={ () => this.openMedicalStudies() }>
											<img src={ AddIcon } />
										</Button>
									</div>
									<div className="row row-schedules">
										{
											medical_studies_selected.map((item: any, index: number) => {
												const medical_study: any = this.state.medical_studies.find((i: any) => i.id == item);

												return (
													<div className="col-md-12">
														<div className="item-service">
															<p>{ medical_study?.name }</p>
															<a href="#" className="trash-container" onClick={ (e: any) => {
																e.preventDefault();
																this.deleteMedicalStudy(index);
															} }>
																<img src={ TrashIcon } />
															</a>
														</div>
													</div>
												)
											})
										}
									</div>
								</div>
								<div className="col-md-6">
									<div className="container-select">
										<Select
											placeholder="Seleccionar"
											label="Imagenes (Opcional)"
											name="medical_image_id"
											onChange={ (e: any) => {
												let medical_images_selected: any = [...this.state.medical_images_selected];
												medical_images_selected.push(parseInt(e.target.value));
												this.setState({
													medical_images_selected
												});
											} }
											value={ this.state.form.medical_image_id }
											options={ this.state.medical_images.filter((i: any) => medical_images_selected.indexOf(i.id) == -1).map((item: any) => {
												return {
													value: item.id,
													label: item.name
												}
											}) } />
										<Button className="add-icon" type="button" onClick={ () => this.openMedicalImages() }>
											<img src={ AddIcon } />
										</Button>
									</div>
									<div className="row row-schedules">
										{
											medical_images_selected.map((item: any, index: number) => {
												const medical_image: any = this.state.medical_images.find((i: any) => i.id == item);

												return (
													<div className="col-md-12">
														<div className="item-service">
															<p>{ medical_image?.name }</p>
															<a href="#" className="trash-container" onClick={ (e: any) => {
																e.preventDefault();
																this.deleteMedicalImages(index);
															} }>
																<img src={ TrashIcon } />
															</a>
														</div>
													</div>
												)
											})
										}
									</div>
								</div>
								<div className="col-md-6 container-select">
									<Select
										placeholder="Seleccionar"
										label="Especialidad (Opcional)"
										name="speciality_id"
										onChange={ this.change }
										value={ this.state.form.speciality_id }
										options={ this.state.specialities.map((item: any) => {
											return {
												value: item.id,
												label: item.name
											}
										}) } />
									<Button className="add-icon" type="button" onClick={ () => this.openSpecialities() }>
										<img src={ AddIcon } />
									</Button>
								</div>
								<div className="col-md-6">
									<div className="container-select">
										<Select
											placeholder="Seleccionar"
											label="Tratamientos (Opcional)"
											name="treatment_id"
											onChange={ (e: any) => {
												let treatments_selected: any = [...this.state.treatments_selected];
												treatments_selected.push(parseInt(e.target.value));
												this.setState({
													treatments_selected
												});
											} }
											value={ this.state.form.treatment_id }
											options={ this.state.treatments.filter((i: any) => treatments_selected.indexOf(i.id) == -1).map((item: any) => {
												return {
													value: item.id,
													label: item.name
												}
											}) } />
										<Button className="add-icon" type="button" onClick={ () => this.openTreatments() }>
											<img src={ AddIcon } />
										</Button>
									</div>
									<div className="row row-schedules">
										{
											treatments_selected.map((item: any, index: number) => {
												const treatment: any = this.state.treatments.find((i: any) => i.id == item);

												return (
													<div className="col-md-12">
														<div className="item-service">
															<p>{ treatment?.name }</p>
															<a href="#" className="trash-container" onClick={ (e: any) => {
																e.preventDefault();
																this.deleteTreatments(index);
															} }>
																<img src={ TrashIcon } />
															</a>
														</div>
													</div>
												)
											})
										}
									</div>
								</div>
							</React.Fragment>
						)
					}
					<div className="col-12 text-center">
						<Button type="button" className="btn-add" onClick={ () => this.add() }>
							Cargar
						</Button>
					</div>
					<div className="row row-schedules">
						{
							services_list.map((service_item: any) => {
								// @ts-ignore
								const specialities_list: any = [...new Set(this.state.specialities_selected.filter((i: any) => i.service_id == service_item).map((i: any) => i.speciality_id))].filter((i: any) => i != '');
								const service: any = this.state.services?.find((i: any) => i.id == parseInt(service_item));

								return (
									<React.Fragment>
										<p className="service-bold">{ service?.name }</p>
										{
											specialities_list.filter((item: any) => item != null).map((list_item: any) => {
												const speciality: any = this.state.specialities?.find((i: any) => i.id == parseInt(list_item));

												return (
													<div className="item-service-gray">
														<p className="bold">{ speciality?.name }</p>
														{
															specialities_selected.map((item: any, index: number) => {
																if (item.speciality_id == parseInt(list_item) && item.service_id == service_item) {
																	const speciality: any = this.state.specialities?.find((i: any) => i.id == item.speciality_id);
																	const treatment: any = this.state.treatments?.find((i: any) => i.id == item.treatment_id);
																	const medical_image: any = this.state.medical_images?.find((i: any) => i.id == item.medical_image_id);
																	const medical_study: any = this.state.medical_studies?.find((i: any) => i.id == item.medical_study_id);

																	return (
																		<div className="item-service">
																			<p style={{ marginTop: index == 0 ? '20px' : '0px' }}>{ treatment?.name || medical_image?.name || medical_study?.name || speciality?.name }</p>
																			<div className="container-service">
																				<Input
																					type="decimal"
																					color="white"
																					value={ item.price }
																					name="price" 
																					label={ 'Precio Practicante' }
																					onChange={ (e: any) => this.changeSpeciality(e.target.value,e.target.name,index) } />
																				<Input
																					disabled={ true }
																					type="decimal"
																					color="white"
																					value={ item.price_app }
																					name="price_app" 
																					label={ 'Precio App' }
																					onChange={ (e: any) => this.changeSpeciality(e.target.value,e.target.name,index) } />
																				<Input
																					disabled={ true }
																					type="percentage"
																					color="white"
																					value={ item.percentage }
																					name="percentage" 
																					label={ '% App' }
																					onChange={ (e: any) => this.changeSpeciality(e.target.value,e.target.name,index) } />
																			</div>
																			<a href="#" className="trash-container" style={{ marginTop: '20px' }} onClick={ (e: any) => {
																				e.preventDefault();
																				this.deleteSpeciality(index);
																			} }>
																				<img src={ TrashIcon } />
																			</a>
																		</div>
																	)
																}
															})
														}
													</div>
												)
											})
										}
										{
											specialities_selected.filter((item: any) => item.speciality_id == null && item.service_id == service_item).length > 0 && (
												<div className="item-service-gray">
													<p className="bold">{ service?.service_category?.name }</p>
													{
														specialities_selected.map((item: any, index: number) => {
															if (item.speciality_id == null && item.service_id == service_item) {
																const treatment: any = this.state.treatments?.find((i: any) => i.id == item.treatment_id);
																const medical_image: any = this.state.medical_images?.find((i: any) => i.id == item.medical_image_id);
																const medical_study: any = this.state.medical_studies?.find((i: any) => i.id == item.medical_study_id);

																return (
																	<div className="item-service">
																		<p style={{ marginTop: index == 0 ? '20px' : '0px' }}>{ treatment?.name || medical_image?.name || medical_study?.name }</p>
																		<div className="container-service">
																			<Input
																				type="decimal"
																				color="white"
																				value={ item.price }
																				name="price" 
																				label={ 'Precio Practicante' }
																				onChange={ (e: any) => this.changeSpeciality(e.target.value,e.target.name,index) } />
																			<Input
																				disabled={ true }
																				type="decimal"
																				color="white"
																				value={ item.price_app }
																				name="price_app" 
																				label={ 'Precio App' }
																				onChange={ (e: any) => this.changeSpeciality(e.target.value,e.target.name,index) } />
																			<Input
																				disabled={ true }
																				type="percentage"
																				color="white"
																				value={ item.percentage }
																				name="percentage" 
																				label={ '% App' }
																				onChange={ (e: any) => this.changeSpeciality(e.target.value,e.target.name,index) } />
																		</div>
																		<a href="#" className="trash-container" style={{ marginTop: '20px' }} onClick={ (e: any) => {
																			e.preventDefault();
																			this.deleteSpeciality(index);
																		} }>
																			<img src={ TrashIcon } />
																		</a>
																	</div>
																)
															}
														})
													}
												</div>
											)
										}
									</React.Fragment>		
								)
							})
						}
					</div>
					<div className="col-md-12">
						<div className="container-schedules">
							<h3>Horarios de atención</h3>
							<button type="button" onClick={ () => this.addSchedule() }>
								<img src={ AddIcon } />
							</button>
						</div>
					</div>
					<div className="row row-schedules">
						{
							this.state.user_schedules.map((item,index) => {
								return (
									<React.Fragment>
										<div className="col-md-5">
											<DatePicker
												placeholder="Desde"
												showTimeSelect={ true }
												showTimeSelectOnly={ true }
												timeCaption="Hora"
												calendarIcon={ TimeIcon }
												dateFormat="h:mm aa"
												onChange={ (text: string) => {
													this.changeSchedule(text,'since',index);
												} }
												value={ item.since }
											/>
										</div>
										<div className="col-md-5">
											<DatePicker
												placeholder="Hasta"
												showTimeSelect={ true }
												showTimeSelectOnly={ true }
												timeCaption="Hora"
												calendarIcon={ TimeIcon }
												dateFormat="h:mm aa"
												onChange={ (text: string) => {
													this.changeSchedule(text,'until',index);
												} }
												value={ item.until }
											/>
										</div>
										<div className="col-md-2">
											<a href="#" className="trash-container" onClick={ (e: any) => {
												e.preventDefault();
												this.deleteSchedule(index);
											} }>
												<img src={ TrashIcon } />
											</a>
										</div>
									</React.Fragment>
								)
							})
						}
					</div>
					<div className="col-12">
						<div className="row row-buttons">
							<div className="col-md-12 text-center">
								<Button type="submit">
									Guardar
								</Button>
							</div>
						</div>
					</div>
				</form>
			</div>
		)
	}
}

export default ModalCreate;