import { Api } from 'utils';

class ReportService {
	
	static admin = {
		getPets: async (params?: any) => Api.createResponse('admin/reports/pets',params),
		deletePets: async (params?: any) => Api.createResponse('admin/reports/delete-pet',params),
		getServices: async (params?: any) => Api.createResponse('admin/reports/services',params),
		getReportServices: async (params?: any) => Api.createResponse('admin/reports/report-services',params),
		printReportServices: async (params?: any) => Api.createResponse('admin/reports/report-services/print',params),
		printReportPlan: async (params?: any) => Api.createResponse('admin/reports/report-plans/print',params),
		printReportClub: async (params?: any) => Api.createResponse('admin/reports/club/print',params),
		printFinancing: async (params?: any) => Api.createResponse('admin/reports/financing/print',params),
		getPlans: async (params?: any) => Api.createResponse('admin/reports/plans',params),
		getReportPlans: async (params?: any) => Api.createResponse('admin/reports/report-plans',params),
		getClub: async (params?: any) => Api.createResponse('admin/reports/club',params),
		getFinancing: async (params?: any) => Api.createResponse('admin/reports/financing',params),
		getCodes: async (params?: any) => Api.createResponse('admin/reports/codes',params),
		printCodes: async (params?: any) => Api.createResponse('admin/reports/print-codes',params),
		changeStatusCodes: async (params?: any) => Api.createResponse('admin/reports/change-status-codes',params),
		printPayment: async (params?: any) => Api.createResponse('admin/reports/print-payment',params),
		getPayments: async (params?: any) => Api.createResponse('admin/reports/payments',params),
		changeStatusPayment: async (params?: any) => Api.createResponse('admin/reports/change-status-payment',params),
	}	
}

export default ReportService;