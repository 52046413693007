import React from 'react';
import { Input, Button, PaperClip, Select, CheckboxTable, DatePicker, Modal, Textarea, SelectSearch } from 'components';
import { RootState } from 'reducers';
import { RouteComponentProps } from 'react-router-dom';
import { Globals, Constants, Socket, SocketEvents } from 'utils';
import { WhiteImage2 } from 'assets/img';
import { DownloadIcon } from 'assets/icons';

interface Props {
	onClose?: any;
	item: any;
}

class ModalView extends React.Component<Props> {

	render() {
		const item: any = this.props.item;
		const user_service: any = item?.practicing?.services?.find((i: any) => i.service_id == item.service_id);

		return (
			<div id="modal-view-service-practicing">
				{
					item != null && (
						<div className="container-green">
							<p><strong>Nombre del Paciente:</strong> { item?.user?.person ? (item?.user?.person?.name + ' ' + item?.user?.person?.lastname) : '-' }</p>
							<p><strong>Cédula identidad:</strong> { item?.user?.person ? item?.user?.person?.document : '-' }</p>
							<p><strong>Teléfono Paciente:</strong> { item?.user?.person ? item?.user?.person?.phone : '-' }</p>
							<p><strong>Correo Paciente:</strong> { item?.user?.email }</p>
							{ item?.service && <p><strong>Servicio Solicitado:</strong> { item?.service?.name }</p> }
							{ item?.service_speciality && <p><strong>Especialidad o Estudio:</strong> { item?.service_speciality?.treatment?.name || item?.service_speciality?.medical_study?.name || item?.service_speciality?.medical_image?.name || item?.service_speciality?.speciality?.name }</p> }
							{ item?.practicing && <p><strong>Nombre del practicante:</strong> { item?.practicing?.person ? (item?.practicing?.person?.name + ' ' + item?.practicing?.person?.lastname) : '-' }</p> }
							{ item?.health_center && <p><strong>Unidad o Especialidad:</strong> { item?.health_center?.name }</p> }
							{ item?.consultation_type && <p><strong>Tipo de consulta:</strong> { item?.consultation_type?.name }</p> }
							{ user_service != null && <p><strong>Precio practicante:</strong> { Globals.formatMiles(item?.payment_practicing) }</p> }
							{ item?.comments && <p><strong>Observaciones:</strong> { item?.comments }</p> }
						</div>
					)
				}
			</div>
		)
	}
}

export default ModalView;