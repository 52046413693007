import React from 'react';
import { Input, Button, PaperClip, Select, CheckboxTable, DatePicker, Modal, Textarea, SelectSearch } from 'components';
import { RootState } from 'reducers';
import { RouteComponentProps } from 'react-router-dom';
import { Globals, Constants, Socket, SocketEvents } from 'utils';
import { WhiteImage2 } from 'assets/img';
import { DownloadIcon } from 'assets/icons';
import { PaymentManagementService } from 'services';
import moment from 'moment';

interface Props {
	onClose?: any;
	item: any;
}

class ModalChangeStatus extends React.Component<Props> {

	state = {
		payment_methods: [],
		form: {
			payment_method_id: '',
			reference: '',
			date: ''
		}
	}

	componentDidMount() {
		this.load();
	}

	load = async () => {
		const res: any = await PaymentManagementService.admin.getPaymentMethods();
		this.setState({
			payment_methods: res.payment_methods || []
		});
	}

	submit = async (e: any) => {
		e.preventDefault();
		await PaymentManagementService.admin.save({
			...this.state.form,
			service_id: this.props.item?.id,
			amount: this.props.item?.payment_practicing,
			date: this.state.form.date && moment(this.state.form.date).format('YYYY-MM-DD'),
		});
		this.props.onClose();
		Globals.showSuccess("Se han guardado los cambios");
	}

	change = (e: any,callback = () => {}) => {
		this.setState({
			form: {
				...this.state.form,
				[e.target.name]: e.target.value
			}
		},callback);
	}

	render() {
		return (
			<div id="modal-payment-management-change-status">
				<form className="row" onSubmit={ this.submit }>
					<div className="col-md-6">
						<DatePicker
							label="Fecha de pago"
							placeholder="Fecha"
							onChange={ (text: string) => {
								this.change({
									target: {
										value: text,
										name: 'date'
									}
								});
							} }
							maxDate={ moment().toDate() }
							value={ this.state.form.date }
						/>
					</div>
					<div className="col-md-6">
						<Select
							label="Método de Pago"
							name="payment_method_id"
							placeholder="Seleccionar"
							onChange={ this.change }
							value={ this.state.form.payment_method_id }
							options={ this.state.payment_methods.map((item: any) => {
								return {
									value: item.id,
									label: item.name
								}
							}) } />
					</div>
					<div className="col-md-6">
						<Input
							color="white"
							disabled={ true }
							value={ Globals.formatMiles(this.props.item?.payment_practicing) }
							name="amount" 
							label="Monto pagado"
							onChange={ this.change } />
					</div>
					<div className="col-md-6">
						<Input
							color="white"
							value={ this.state.form.reference }
							name="reference" 
							label="Número comprobante"
							onChange={ this.change } />
					</div>
					<div className="col-md-12">
						<div className="text-center">
							<Button type="submit">
								Guardar
							</Button>
						</div>
					</div>
				</form>
			</div>
		)
	}
}

export default ModalChangeStatus;