import { Api } from 'utils';

class OrderService {
	
	static admin = {
		get: async (params?: any) => Api.createResponse('admin/orders/',params),
		print: async (params?: any) => Api.createResponse('admin/orders/print',params),
		changeStatus: async (params?: any) => Api.createResponse('admin/orders/change-status',params),
		view: async (params?: any) => Api.createResponse('admin/orders/view',params),
	}

}

export default OrderService;