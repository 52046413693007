import React from 'react';
import { connect } from 'react-redux';
import { RootState } from 'reducers';
import { RouteComponentProps } from 'react-router-dom';
import { CleanIcon, DeleteIcon, EditIcon, AddIcon } from 'assets/icons';
import { Table, Pagination, DatePicker, Tooltip, Button, Input, Select, Modal } from 'components';
import { SellerService } from 'services';
import moment from 'moment';
import { Globals, Constants, Socket, SocketEvents, checkPermission, Permissions } from 'utils';
import ModalCreate from './modal-create';

const INITIAL_STATE = {
	since: '',
	until: '',
	search: ''
}

class AdminSellers extends React.Component<any> {

	timer: any = undefined;

	state = {
		data: [],
		page: 1,
		last_page: 1,
		header: [
			'Fecha de Registro',
			'Nombre y Apellido',
			'Correo',
			'Acción'
		],
		form: INITIAL_STATE,
		visible: false,
		item: null
	}

	componentDidMount() {
		this.load();
	}

	load = async (withoutLoading: boolean = false) => {
		const res: any = await SellerService.admin.get({
			withoutLoading,
			page: this.state.page,
			...this.state.form,
			since: this.state.form.since && moment(this.state.form.since).format('YYYY-MM-DD'),
			until: this.state.form.until && moment(this.state.form.until).format('YYYY-MM-DD'),
		});
		this.setState({
			data: res.sellers.rows,
			last_page: res.sellers.count
		});
	}

	reset = () => {
		this.setState({
			form: INITIAL_STATE,
			page: 1
		},() => this.load(true));
	}

	change = (e: any,callback = () => {}) => {
		this.setState({
			page: 1,
			form: {
				...this.state.form,
				[e.target.name]: e.target.value
			}
		},callback);
	}

	delete = async (e: any, item: any) => {
		e.preventDefault();
		Globals.confirm('¿Desea eliminar el vendedor?',async () => {
			await SellerService.admin.delete({
				user_id: item.id
			});
			this.load(true);
			Socket.emit(SocketEvents.UPDATE_USER,{
				user_id: item.id
			});
		});
	}

	edit = (e: any, item: any) => {
		e.preventDefault();
		this.setState({
			visible: true,
			item
		});	
	}

	onClose = () => {
		this.setState({
			visible: false,
			item: null
		},() => this.load(true));
	}

	add = () => {
		this.setState({
			visible: true
		});
	}
	
	render() {
		const { visible } = this.state;

		return (
			<div id="admin-register-user">
				<Modal
		          className="modal-create-user"
		          visible={ visible }
		          title={ `${ this.state.item != null ? 'Editar' : 'Crear' } Vendedor` }
		          onClose={ () => this.onClose() }
		        >
		          <ModalCreate
		          	item={ this.state.item }
		            onClose={ () => this.onClose() } />
		      	</Modal>

				<div className="row row-filter">
					<div className="col-md-3">
						<Input
							value={ this.state.form.search }
							name="search"
							label="Nombre del Usuario"
							placeholder="Buscar"
							className="input-table"
							onChange={ (e: any) => {
								clearTimeout(this.timer);
								this.timer = setTimeout(() => {
									this.load(true);
								},1000);
								this.change(e); 
							} } />
					</div>
					<div className="col-md-3">
						<DatePicker
							label="Desde"
							placeholder="Desde"
							onChange={ (text: string) => {
								this.change({
									target: {
										value: text,
										name: 'since'
									}
								},() => this.load(true));
							} }
							value={ this.state.form.since }
						/>
					</div>
					<div className="col-md-3">
						<DatePicker
							label="Hasta"
							placeholder="Hasta"
							onChange={ (text: string) => {
								this.change({
									target: {
										value: text,
										name: 'until'
									}
								},() => this.load(true));
							} }
							value={ this.state.form.until }
						/>
					</div>
					<div className="col-md-3">
						{
							this.state.form != INITIAL_STATE && (
								<button className="btn btn-clean" onClick={ () => this.reset() }>
									<img src={ CleanIcon } />
								</button>
							)
						}
					</div>
				</div>

				<Table title="Vendedores Registrados" data={ this.state.data.length } rightAlign={[ 3 ]} header={ this.state.header } titleRight={
					<React.Fragment>
						{
							checkPermission(Constants.MODULES.USERS,'write',this.props.user) && (
								<Button className="btn-add" onClick={ () => this.add() }>
									<img src={ AddIcon } />
									Nuevo
								</Button>
							)
						}
					</React.Fragment>
				}>
					{ this.state.data.map((i: any,index: number) => {
						return (
							<tr key={ index }>
								<td>{ moment(i.created_at).format('DD-MM-YYYY') }</td>
								<td>{ i.person?.fullName }</td>
								<td>{ i.email }</td>
								<td className="vertical-center text-right">
									{
										checkPermission(Constants.MODULES.USERS,'write',this.props.user) && (
											<Tooltip title="Editar">
												<a className="link-icon" href="#" onClick={ (e: any) => this.edit(e,i) }>
													<img src={ EditIcon } />
												</a>
											</Tooltip>
										)
									}
									{
										checkPermission(Constants.MODULES.USERS,'delete',this.props.user) && (
											<Tooltip title="Eliminar">
												<a className="link-icon" href="#" onClick={ (e: any) => this.delete(e,i) }>
													<img src={ DeleteIcon } />
												</a>
											</Tooltip>
										)
									}
								</td>
							</tr>
						)
					}) }
				</Table>

				<Pagination 
					pages={ this.state.last_page } 
					active={ this.state.page }
					onChange={ async (page: number) => {
						await this.setState({
							page: page
						});
						this.load();
					} } />
			</div>
		)
	}
}

export default connect((state: RootState) => {
	return {
		user: state.user
	}
})(AdminSellers);