import { Api } from 'utils';

class PlanService {
	
	static admin = {
		get: async (params?: any) => Api.createResponse('admin/plans/get',params),
		getAll: async (params?: any) => Api.createResponse('admin/plans/get-all',params),
		getServices: async (params?: any) => Api.createResponse('admin/plans/services',params),
		create: async (params?: any) => Api.createResponse('admin/plans/create',params),
		update: async (params?: any) => Api.createResponse('admin/plans/update',params),
		delete: async (params?: any) => Api.createResponse('admin/plans/delete',params)
	}	
}

export default PlanService;