import React from 'react';
import { Input, Button, PaperClip, Select, CheckboxTable, DatePicker } from 'components';
import { ModeratorService } from 'services';
import { RootState } from 'reducers';
import { RouteComponentProps } from 'react-router-dom';
import { Globals, Constants, Socket, SocketEvents } from 'utils';
import { WhiteImage } from 'assets/img';
import moment from 'moment';

interface Props {
	onClose?: any;
	item?: any;
	modules?: any;
}

class ModalCreate extends React.Component<Props> {

	state = {
		modules: [],
		form: {
			name: '',
			lastname: '',
	        email: '',
	        photo: '',
	        password: '',
	        password_confirmation: ''
		}
	}

	componentDidMount() {
		this.setState({
			modules: Globals.clone(this.props.modules),
		},() => {
			if (this.props.item) {
				this.setState({
					form: {
						...this.state.form,
						name: this.props.item?.person?.name || '',
						lastname: this.props.item?.person?.lastname || '',
				        email: this.props.item?.email || '',
					},
					modules: Globals.clone(this.props.modules).map((i: any) => {
						if (this.props.item?.module_permissions) {
							const permission = this.props.item?.module_permissions.find((item: any) => item.module_id == i.id);
							if (permission) {
								i.read = permission.read == true;
								i.write = permission.write == true;
								i.delete = permission.delete == true;
							}
						}
						return i;
					})
				});
			}
		});
	}

	submit = async (e: any) => {
		e.preventDefault();
		if (this.props.item != null) {
			await ModeratorService.admin.update({
				user_id: this.props.item?.id,
				...this.state.form,
				hasFile: true,
				// @ts-ignore,
				photo: this.state.form.photo?.value || '',
				module_permissions: JSON.stringify(this.state.modules.map((i: any) => {
					return {
						module_id: i.id,
						read: i.read,
						write: i.write,
						delete: i.delete
					}
				}))
			});
			Globals.showSuccess("Se ha modificado el moderador correctamente");
			Socket.emit(SocketEvents.UPDATE_USER,{
				user_id: this.props.item?.id
			});
			this.props.onClose();
		}
		else {
			await ModeratorService.admin.create({
				...this.state.form,
				hasFile: true,
				// @ts-ignore,
				photo: this.state.form.photo?.value || '',
				module_permissions: JSON.stringify(this.state.modules.map((i: any) => {
					return {
						module_id: i.id,
						read: i.read,
						write: i.write,
						delete: i.delete
					}
				}))
			});
			Globals.showSuccess("Se ha creado el moderador correctamente");
			this.props.onClose();
		}
	}

	change = (e: any,callback = () => {}) => {
		this.setState({
			form: {
				...this.state.form,
				[e.target.name]: e.target.value
			}
		},callback);
	}

	checkModule = (index: number, target: string) => {
		let modules: any = [...this.state.modules];
		if (target == 'active') {
			if (modules[index]['write'] && modules[index]['read'] && modules[index]['delete']) {
				modules[index]['read'] = false;
				modules[index]['write'] = false;
				modules[index]['delete'] = false;
			}
			else {
				modules[index]['read'] = true;
				modules[index]['write'] = true;
				modules[index]['delete'] = true;
			}
		}
		else {
			modules[index][target] = !modules[index][target];
		}
		this.setState({
			modules
		});
	}
	
	render() {
		return (
			<div id="modal-create-user">
				<form className="row" onSubmit={ this.submit }>
					<div className="col-12">
						<div className="container-image">
							<PaperClip
								className="paperclip-image"
								value={ this.state.form.photo }
								onChange={ (e: any) => {
									this.setState({
										form: {
											...this.state.form,
											photo: e.target
										}
									})
								} }>
								{/*
								// @ts-ignore */}
								<img className={ `selected-image ${ (this.state.form.photo || this.props.item?.person?.photo) ? '' : 'inactive' }` } src={ this.state.form.photo?.dataURL || (this.props.item?.person?.photo ? (Constants.BASE_STORAGE + this.props.item?.person?.photo) : WhiteImage) } />
							</PaperClip>
						</div>
					</div>
					<div className="col-md-6">
						<Input
							color="white"
							value={ this.state.form.name }
							name="name" 
							placeholder="Nombre"
							onChange={ this.change } />
					</div>
					<div className="col-md-6">
						<Input
							color="white"
							value={ this.state.form.lastname }
							name="lastname" 
							placeholder="Apellido"
							onChange={ this.change } />
					</div>
					<div className="col-md-6">
						<Input
							color="white"
							value={ this.state.form.email }
							name="email" 
							placeholder="Correo Electrónico"
							onChange={ this.change } />
					</div>
					<div className="col-md-6">
						<Input
							type="password"
							color="white"
							value={ this.state.form.password }
							name="password" 
							placeholder={ this.props.item != null ? "Cambiar Contraseña (Opcional)" : "Contraseña" }
							onChange={ this.change } />
					</div>
					<div className="col-md-6">
						<Input
							type="password"
							color="white"
							value={ this.state.form.password_confirmation }
							name="password_confirmation" 
							placeholder="Repetir contraseña"
							onChange={ this.change } />
					</div>
					<div className="col-12">
						<h3>Módulos Autorizados</h3>
						<table>
							<thead>
								<tr>
									<th style={{ paddingLeft: '10px' }}></th>
									<th className="text-center">Ver</th>
									<th className="text-center">Escribir</th>
									<th className="text-center" style={{ paddingRight: '10px' }}>Eliminar</th>
								</tr>
							</thead>
							<tbody>
								{
									(this.state.modules || []).map((item: any, index: number) => {
										return (
											<tr>
												<td style={{ paddingLeft: '10px' }}>
													<div className="td-check">
														<CheckboxTable
															size="small" 
															active={ item.read && item.write && item.delete } 
															onClick={ () => this.checkModule(index,'active') } />
														<p>{ item.name }</p>
													</div>
												</td>
												<td className="td-center">
													<CheckboxTable 
														size="small" 
														active={ item.read } 
														onClick={ () => this.checkModule(index,'read') } />
												</td>
												<td className="td-center">
													<CheckboxTable 
														size="small" 
														active={ item.write } 
														onClick={ () => this.checkModule(index,'write') } />
												</td>
												<td className="td-center" style={{ paddingRight: '10px' }}>
													<CheckboxTable 
														size="small" 
														active={ item.delete } 
														onClick={ () => this.checkModule(index,'delete') } />
												</td>
											</tr>
										)
									})
								}
							</tbody>
						</table>
					</div>
					<div className="col-12">
						<div className="row row-buttons">
							<div className="col-md-12 text-center">
								<Button type="submit">
									Guardar
								</Button>
							</div>
						</div>
					</div>
				</form>
			</div>
		)
	}
}

export default ModalCreate;