import { Api } from 'utils';

class ProductService {
	
	static admin = {
		get: async (params?: any) => Api.createResponse('admin/products',params),
		print: async (params?: any) => Api.createResponse('admin/products/print',params),
		save: async (params?: any) => Api.createResponse('admin/products/save',params),
		view: async (params?: any) => Api.createResponse('admin/products/view',params),
	}
}

export default ProductService;