import React from 'react';
import { connect } from 'react-redux';
import { RootState } from 'reducers';
import { RouteComponentProps, Link } from 'react-router-dom';
import { CleanIcon, PrintIcon, PasswordActive, AddIcon, EditIcon, DownloadExcelIcon } from 'assets/icons';
import { Globals, Constants, Colors, Print, checkPermission } from 'utils';
import { WhiteImage } from 'assets/img';
import { Table, Pagination, Input, Select, Tooltip, Modal, DatePicker, Button } from 'components';
import moment from 'moment';
import { ProductService } from 'services';
import ModalView from './modal-view';
import ModalEdit from './modal-edit';

interface Props extends RootState, RouteComponentProps {
	dispatch: any
}

const INITIAL_STATE = {
	search: '',
	type: '',
	category_id: ''
}

class AdminProducts extends React.Component<Props> {

	timer: any = undefined;

	state = {
		data: [],
		page: 1,
		last_page: 1,
		header: [
			'Nombre',
			'Precio',
			'Tipo',
			'Categoría',
			'Stock',
			'Acciones'
		],
		form: INITIAL_STATE,
		categories: [],
		item: null,
		visible: false,
		visible_edit: false
	}

	componentDidMount() {
		this.props.dispatch({
			type: 'SET_HEADER',
			payload: {
				title: 'Productos',
				onBack: null
			}
		});

		this.props.dispatch({
			type: 'SET_SUBHEADER',
			payload: {
				title: ''
			}
		});

		this.load();
	}

	load = async (withoutLoading: boolean = false) => {
		const res: any = await ProductService.admin.get({
			withoutLoading,
			page: this.state.page,
			...this.state.form,
		});
		this.setState({
			data: res.products.rows,
			last_page: res.products.count,
			categories: res.categories || []
		});
	}

	change = (e: any,callback = () => {}) => {
		this.setState({
			page: 1,
			form: {
				...this.state.form,
				[e.target.name]: e.target.value
			}
		},callback);
	}

	reset = () => {
		this.setState({
			form: INITIAL_STATE,
			page: 1
		},() => this.load(true));
	}

	edit = (e: any, item: any) => {
		e.preventDefault();
		this.setState({
			item,
			visible_edit: true
		});
	}

	view = (e: any, item: any) => {
		e.preventDefault();
		this.setState({
			item,
			visible: true
		});
	}

	download = async () => {
		const res: any = await ProductService.admin.print({
			...this.state.form,
		});
	    Print(Constants.BASE_STORAGE + res.url);
	}

	onClose = () => {
		this.setState({
			visible: false,
			item: null
		},() => this.load(true));
	}

	onCloseEdit = () => {
		this.setState({
			visible_edit: false,
			item: null
		},() => this.load(true));
	}

	render() {
		const { visible, visible_edit } = this.state; 

		return (
			<div id="admin-products">
				<Modal
		          className="modal-view-product"
		          visible={ visible }
		          title="Ver Producto"
		          onClose={ () => this.onClose() }
		        >
		          <ModalView
		          	item={ this.state.item }
		            onClose={ () => this.onClose() } />
		      	</Modal>

		      	<Modal
		          className="modal-edit-product"
		          visible={ visible_edit }
		          title="Editar Producto"
		          onClose={ () => this.onCloseEdit() }
		        >
		          <ModalEdit
		          	categories={ this.state.categories }
		          	item={ this.state.item }
		            onClose={ () => this.onCloseEdit() } />
		      	</Modal>

				<div className="row row-filter">
					<div className="col-md-9 row">
						<div className="col-md-4">
							<Input
								value={ this.state.form.search }
								name="search"
								label="Nombre del Producto"
								placeholder="Buscar"
								className="input-table"
								onChange={ (e: any) => {
									clearTimeout(this.timer);
									this.timer = setTimeout(() => {
										this.load(true);
									},1000);
									this.change(e); 
								} } />
						</div>
						<div className="col-md-4">
							<Select
								placeholder="Seleccionar"
								label="Categoría"
								name="category_id"
								onChange={ (e: any) => this.change(e,() => this.load(true)) }
								value={ this.state.form.category_id }
								options={ [
									{ value: 0, label: 'Sin asignar' },
									...this.state.categories.map((item: any) => {
										return {
											value: item.id,
											label: item.name
										}
									})
								] } />
						</div>
						<div className="col-md-4">
							<Select
								placeholder="Seleccionar"
								label="Tipo"
								name="type"
								onChange={ (e: any) => this.change(e,() => this.load(true)) }
								value={ this.state.form.type }
								options={ [
									{ value: Constants.PRODUCT_TYPES.NORMAL.toString(), label: 'Único' },
									{ value: Constants.PRODUCT_TYPES.SIZE_COLORS.toString(), label: 'Por tamaño/color' },
								] } />
						</div>
					</div>
					<div className="col-md-3">
						{
							this.state.form != INITIAL_STATE && (
								<button className="btn btn-clean" onClick={ () => this.reset() }>
									<img src={ CleanIcon } />
								</button>
							)
						}
					</div>
				</div>

				<Table title="Productos" data={ this.state.data.length } rightAlign={[ 5 ]} header={ this.state.header } titleRight={
					<React.Fragment>
						{
							this.state.data.length > 0 && (
								<div className="container-add-table">
									<p>Descargar Excel</p>
									<button className="btn add-btn-table" onClick={ this.download }>
										<img src={ DownloadExcelIcon } />
									</button>
								</div>
							)
						}
					</React.Fragment>
				}>
					{ this.state.data.map((i: any,index: number) => {
						return (
							<tr key={ index }>
								<td>{ i.name }</td>
								<td>{ i.type == Constants.PRODUCT_TYPES.NORMAL ? Globals.formatMiles(i.price) : Globals.formatMiles(Math.max(...i.size_colors?.map((i: any) => i.price)) || 0) }</td>
								<td>{ i.type_text }</td>
								<td>{ i.category?.name || '-' }</td>
								<td>{ i.type == Constants.PRODUCT_TYPES.NORMAL ? (i.stock || 0) : i.size_colors?.map((i: any) => i.stock).reduce((a: number, b: number) => a + b,0) }</td>
								<td className="vertical-center text-right" style={{ whiteSpace: 'nowrap' }}>
									<Tooltip title="Ver">
										<a className="link-icon link-view" href="#" onClick={ (e: any) => this.view(e,i) }>
											<img src={ PasswordActive } />
										</a>
									</Tooltip>
									<Tooltip title="Editar">
										<a className="link-icon link-view" href="#" onClick={ (e: any) => this.edit(e,i) }>
											<img src={ EditIcon } />
										</a>
									</Tooltip>
								</td>
							</tr>
						)
					}) }
				</Table>

				<Pagination 
					pages={ this.state.last_page } 
					active={ this.state.page }
					onChange={ async (page: number) => {
						await this.setState({
							page: page
						});
						this.load();
					} } />
			</div>
		)
	}
}

export default connect((state: RootState) => {
	return {
		user: state.user
	}
})(AdminProducts);