import React from 'react';
import { connect } from 'react-redux';
import { RootState } from 'reducers';
import { RouteComponentProps, Link } from 'react-router-dom';
import { CleanIcon, EditIcon } from 'assets/icons';
import { Globals, Constants, Colors, checkPermission } from 'utils';
import { PaymentManagementService } from 'services';
import { WhiteImage } from 'assets/img';
import { Table, Pagination, Input, Select, Tooltip, Modal } from 'components';
import ModalChangeStatus from './modal-change-status';

interface Props extends RootState, RouteComponentProps {
	dispatch: any
}

const INITIAL_STATE = {
	search: '',
	service_id: '',
	status: ''
}

class AdminPaymentManagment extends React.Component<Props> {

	timer: any = undefined;

	state = {
		data: [],
		page: 1,
		last_page: 1,
		header: [
			'Nombre del Practicante',
			'Unidad o Especialidad',
			'Servicio Solicitado',
			'Código de Solicitud',
			'Precio Regular',
			'Precio App',
			'Monto Pagado',
			'Monto Pendiente',
			'Estatus del Pago',
			'Acciones'
		],
		form: INITIAL_STATE,
		services: [],
		visible: false,
		item: null
	}

	componentDidMount() {
		this.props.dispatch({
			type: 'SET_HEADER',
			payload: {
				title: 'Gestión de Pagos',
				onBack: null
			}
		});

		this.props.dispatch({
			type: 'SET_SUBHEADER',
			payload: {
				title: ''
			}
		});

		this.load();
		this.loadServices();
	}

	load = async (withoutLoading: boolean = false) => {
		const res: any = await PaymentManagementService.admin.get({
			withoutLoading,
			page: this.state.page,
			...this.state.form
		});
		this.setState({
			data: res.services.rows,
			last_page: res.services.count
		});
	}

	loadServices = async () => {
		const res: any = await PaymentManagementService.practicing.getServices({
			withoutLoading: true
		});
		this.setState({
			services: res.services
		});
	}

	reset = () => {
		this.setState({
			form: INITIAL_STATE,
			page: 1
		},() => this.load(true));
	}

	change = (e: any,callback = () => {}) => {
		this.setState({
			page: 1,
			form: {
				...this.state.form,
				[e.target.name]: e.target.value
			}
		},callback);
	}

	edit = (e: any, item: any) => {
		e.preventDefault();
		this.setState({
			visible: true,
			item
		});
	}

	getColor = (item: any) => {
		switch(item.status_payment) {
			case Constants.PRACTICING_PAYMENT_STATUS.PENDING:
				return Colors.danger;
			break;

			case Constants.PRACTICING_PAYMENT_STATUS.FINISH:
				return Colors.green;
			break;
		}
	}

	onClose = () => {
		this.setState({
			visible: false
		},() => this.load(true));
	}
	
	render() {
		const { visible } = this.state;

		return (
			<div id="admin-payment-management">
				<Modal
		          className="modal-payment-management-change-status"
		          visible={ visible }
		          title="Registro de pago"
		          onClose={ () => this.onClose() }
		        >
		          <ModalChangeStatus
		          	item={ this.state.item }
		            onClose={ () => this.onClose() } />
		      	</Modal>

				<div className="row row-filter">
					<div className="row col-md-9">
						<div className="col-md-4">
							<Input
								value={ this.state.form.search }
								name="search"
								label="Nombre del Practicante"
								placeholder="Buscar"
								className="input-table"
								onChange={ (e: any) => {
									clearTimeout(this.timer);
									this.timer = setTimeout(() => {
										this.load(true);
									},1000);
									this.change(e); 
								} } />
						</div>
						<div className="col-md-4">
							<Select
								placeholder="Seleccionar"
								label="Servicio"
								name="service_id"
								onChange={ (e: any) => this.change(e,() => this.load(true)) }
								value={ this.state.form.service_id }
								options={ this.state.services.map((item: any) => {
									return {
										value: item.id,
										label: item.name
									}
								}) } />
						</div>
						<div className="col-md-4">
							<Select
								placeholder="Seleccionar"
								label="Estatus del Pago"
								name="status"
								onChange={ (e: any) => this.change(e,() => this.load(true)) }
								value={ this.state.form.status }
								options={ [
									{ value: Constants.PRACTICING_PAYMENT_STATUS.PENDING.toString(), label: 'Pendientes' },
									{ value: Constants.PRACTICING_PAYMENT_STATUS.FINISH.toString(), label: 'Pagados' },
								] } />
						</div>
					</div>
					<div className="col-md-3">
						{
							this.state.form != INITIAL_STATE && (
								<button className="btn btn-clean" onClick={ () => this.reset() }>
									<img src={ CleanIcon } />
								</button>
							)
						}
					</div>
				</div>

				<Table title="Registro de Pagos Servicios Prestados" data={ this.state.data.length } rightAlign={[ 9 ]} header={ this.state.header }>
					{ this.state.data.map((i: any,index: number) => {
						return (
							<tr key={ index }>
								<td>{ i.practicing?.person?.name + ' ' + i.practicing?.person?.lastname }</td>
								<td>{ i.health_center?.name }</td>
								<td>{ i.service?.name }</td>
								<td>{ i.number }</td>
								<td>{ i.price }</td>
								<td>{ i.price_app }</td>
								<td>{ i.status_payment == Constants.PRACTICING_PAYMENT_STATUS.PENDING ? 0 : i.payment_practicing }</td>
								<td>{ i.status_payment == Constants.PRACTICING_PAYMENT_STATUS.FINISH ? 0 : i.payment_practicing }</td>
								<td className="status" style={{
									color: this.getColor(i)
								}}>{ i.status_payment_text }</td>
								<td className="vertical-center text-right">
									{
										checkPermission(Constants.MODULES.PAYMENT_MANAGEMENT,'write',this.props.user) && (
											<Tooltip title="Editar">
												<a className="link-icon" href="#" onClick={ (e: any) => this.edit(e,i) }>
													<img src={ EditIcon } />
												</a>
											</Tooltip>
										)
									}
								</td>
							</tr>
						)
					}) }
				</Table>

				<Pagination 
					pages={ this.state.last_page } 
					active={ this.state.page }
					onChange={ async (page: number) => {
						await this.setState({
							page: page
						});
						this.load();
					} } />
			</div>
		)
	}
}

export default connect((state: RootState) => {
	return {
		user: state.user
	}
})(AdminPaymentManagment);