import React from 'react';
import { Input, Button, PaperClip, Select, CheckboxTable, DatePicker, Modal, Textarea } from 'components';
import { RootState } from 'reducers';
import { RouteComponentProps } from 'react-router-dom';
import { Globals, Constants, Socket, SocketEvents, Colors } from 'utils';
import { FinancingService } from 'services';
import moment from 'moment';

interface Props {
	onClose?: any;
	item?: any;
}

class ModalView extends React.Component<Props> {

	state = {
		item: null
	}

	componentDidMount() {
		this.load();
	}

	changeStatus = (status: number) => {
		Globals.confirm(`¿Desea ${ status == Constants.FINANCING_STATUS.APPROVED ? 'aprobar' : 'rechazar' } este financimiento?`,async () => {
			await FinancingService.admin.changeStatus({
				financing_id: this.props.item?.id,
				status
			});
			this.props.onClose();
		});
	}

	load = async () => {
		const res: any = await FinancingService.admin.view({
			financing_id: this.props.item?.id
		});
		this.setState({
			item: res.financing
		});
	}

	getColor = (item: any) => {
		switch(item.status) {
			case Constants.FINANCING_DATE_STATUS.PENDING:
				return Colors.danger;
			break;

			case Constants.FINANCING_DATE_STATUS.PAID:
				return Colors.green;
			break;
		}
	}
	
	render() {
		const item: any = this.state.item;
		const selfie_file = item?.files?.find((i: any) => i.type == Constants.FINANCING_FILES.SELFIE);
		const document_file = item?.files?.find((i: any) => i.type == Constants.FINANCING_FILES.DOCUMENT);
		const payment: any = item?.payment;

		return (
			<div id="modal-view-financing">
				<div className="container-dates">
					<div className="row">
						<div className="col-md-4">
							<p className="amount">{ Globals.formatMiles(item?.initial_payment) }</p>
						</div>
						<div className="col-md-4">
							<p style={{
								color: Colors.green
							}} className="status">Pago Inicial</p>
						</div>
						<div className="col-md-4">
							<p className="text-right">{ moment(item?.created_at).format('DD/MM/YYYY') }</p>
						</div>
					</div>
					{
						item?.dates?.map((item: any) => {
							return (
								<div className="row">
									<div className="col-md-4">
										<p className="amount">{ Globals.formatMiles(item?.amount) }</p>
									</div>
									<div className="col-md-4">
										<p style={{
											color: this.getColor(item)
										}} className="status">{ item?.status_text }</p>
									</div>
									<div className="col-md-4">
										<p className="text-right">{ moment(item?.date).format('DD/MM/YYYY') }</p>
									</div>
								</div>
							)
						})
					}
				</div>
				<div className="data-container">
					<h3 className="title-payment-init">Pago Inicial:</h3>
					{ payment?.created_at && <p><strong>Fecha:</strong> { moment(payment.created_at).format('DD/MM/YYYY') }</p> }
					{ payment?.payment_method && <p><strong>Método de Pago:</strong> { payment?.payment_method?.name }</p> }
					{ payment?.phone && <p><strong>Teléfono:</strong> { payment?.phone }</p> }
					{ payment?.document && <p><strong>Documento de Identidad:</strong> { payment?.document }</p> }
					{ payment?.bank && <p><strong>Banco:</strong> { payment?.bank?.name }</p> }
					{ payment?.name_zelle && <p><strong>Nombre (Zelle):</strong> { payment?.name_zelle }</p> }
					{ payment?.lastname_zelle && <p><strong>Apellido (Zelle):</strong> { payment?.lastname_zelle }</p> }
					{ payment?.reference && <p><strong>Referencia:</strong> { payment?.reference }</p> }
					{ payment?.payment_date && moment(payment?.payment_date).isValid() && <p><strong>Fecha del pago:</strong> { moment(payment?.payment_date).format('DD/MM/YYYY') }</p> }
					{ payment?.ticket_amount && <p><strong>Tickets:</strong> { payment.ticket_amount }</p> }
					{ payment?.amount && <p><strong>Monto:</strong> { Globals.formatMiles(payment?.amount) }</p> }
					{ payment?.quantity_cash && <p><strong>Efectivo:</strong> { Globals.formatMiles(payment?.quantity_cash) }</p> }
				</div>
				{/*<div className="row row-buttons row-buttons-doc">
					{
						document_file != null && (
							<div className="col-md-12">
				      			<a href={ Constants.BASE_STORAGE + document_file?.file } target="_blank">
					      			<Button className="btn-doc" type="button">
										Ver foto de documento
									</Button>
								</a>
				      		</div>
						)
					}
		      		{
		      			selfie_file != null && (
		      				<div className="col-md-12">
				      			<a href={ Constants.BASE_STORAGE + selfie_file?.file } target="_blank">
					      			<Button className="btn-doc" type="button">
										Ver Selfie
									</Button>
								</a>
				      		</div>
		      			)
		      		}
		      	</div>*/}
		      	{
		      		item?.status == Constants.FINANCING_STATUS.PENDING && (
		      			<div className="row row-buttons">
				      		<div className="col-md-6">
				      			<Button className="btn-reject" type="button" onClick={ () => this.changeStatus(Constants.FINANCING_STATUS.REJECTED) }>
									Rechazar
								</Button>
				      		</div>
				      		<div className="col-md-6">
				      			<Button className="btn-accept" type="button" onClick={ () => this.changeStatus(Constants.FINANCING_STATUS.APPROVED) }>
									Aprobar
								</Button>
				      		</div>
				      	</div>
		      		)
		      	}
			</div>
		)
	}
}

export default ModalView;