import HoldOn from 'react-hold-on';
import Swal from 'sweetalert2';
import moment from 'moment';
import $ from 'jquery';
import colors from './colors';

class Globals {

	hold_on_present = false;

	setLoading = () => {
		if (!this.hold_on_present) {
			this.hold_on_present = true;
			HoldOn.open({
			     theme: "sk-bounce"
			});
		}
	}

	quitLoading = () => {
		HoldOn.close();
		setTimeout(() => {
			this.hold_on_present = false;
		},500);
	}

	zeroPad = (num: any) => {
	  return num.toString().padStart(6, "0");
	}

	formatMiles = (n: any, decimals: boolean = true, currency: string = '$',invertPoints = false) => {
		var c: any = isNaN(c = Math.abs(c)) ? 2 : c,
			d: any = d == undefined ? invertPoints ? "." : "," : d,
			t: any = t == undefined ? invertPoints ? "," : "." : t,
			s: any = n < 0 ? "-" : "",
			i: any = String(parseInt(n = Math.abs(Number(n) || 0).toFixed(c))),
			j: any = (j = i.length) > 3 ? j % 3 : 0;

		return currency + (currency != '' ? ' ' : '') + s + (j ? i.substr(0, j) + t : "") + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + t) + (c ? d + Math.abs(n - i).toFixed(c).slice(2) : "");
	}

	hideTooltip = () => {
		$('button').blur();
		$('a').blur();
    }

	showSuccess = (message: string) => {
		Swal.fire({
            title: "",
            text: message,
            type: "success",
			showCancelButton: false,
			confirmButtonColor: colors.swal,
			onClose: () => {
				this.hideTooltip()
			}
        });
	}

	showHtml = (message: string) => {
		Swal.fire({
            title: "",
            html: message,
            type: "success",
			showCancelButton: false,
			confirmButtonColor: colors.swal,
			onClose: () => {
				this.hideTooltip()
			}
        });
	}

	showWarning = (message: string) => {
		Swal.fire({
            title: "",
            text: message,
            type: "warning",
			showCancelButton: false,
			confirmButtonColor: colors.swal,
			onClose: () => {
				this.hideTooltip()
			}
        });
	}

	showError = (message?: string) => {
		Swal.fire({
            title: "",
            text: message ? message : "Por favor, revise su conexión",
            type: "error",
			showCancelButton: false,
			confirmButtonColor: colors.swal,
			onClose: () => {
				this.hideTooltip()
			}
        });
	}

	confirm = (message: string,callback: any) => {
		this.hideTooltip();
		Swal.fire({
            title: "",
            text: message,
            type: "warning",
			showCancelButton: true,
            confirmButtonText: 'Aceptar',
			cancelButtonText: 'Cancelar',
			confirmButtonColor: colors.swal,
			onClose: () => {
				this.hideTooltip()
			}
        }).then((confirm: any) => {
        	if (confirm.value) {
        		callback();
        	}
        });
	}

	clone = (data: any) => {
		return JSON.parse(JSON.stringify(data));
	}

	getDate = (date: string, to: string = 'DD/MM/YYYY', from: string = 'YYYY-MM-DD HH:mm:ss') => {
		return moment(date,from).format(to);
	}

	capitalize = (text: string) => {
		return text.charAt(0).toUpperCase() + text.slice(1);
	}

	isPdf(file: string) {
		return file.substr(file.lastIndexOf('.') + 1) == 'pdf';
	}

	isImage(file: string) {
		const formats = ['png','jpg','jpeg'];
		return formats.indexOf(file.substr(file.lastIndexOf('.') + 1)) != -1;
	}

	formatPercentage(amount: number) {
		return amount.toFixed(2) + '%';
	}

	pad(num: number | string, size = 4) {
	    num = num.toString();
	    while (num.length < size) num = "0" + num;
	    return num;
	}
}

export default new Globals();