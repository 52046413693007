import React from 'react';
import { Input, Button, PaperClip, Select, CheckboxTable, DatePicker } from 'components';
import { PracticingService } from 'services';
import { RootState } from 'reducers';
import { RouteComponentProps } from 'react-router-dom';
import { Globals, Constants, Socket, SocketEvents } from 'utils';
import { WhiteImage } from 'assets/img';

interface Props {
	onClose?: any;
	item?: any;
}

class ModalServices extends React.Component<Props> {

	state = {
		services: []
	}

	componentDidMount() {
		this.loadServices();
	}

	loadServices = async () => {
		const res: any = await PracticingService.admin.getServices();
		this.setState({
			services: res.services
		});
	}
	
	render() {
		return (
			<div id="modal-create-user">
				<div className="row">
					{
						this.props.item?.services?.map((item: any) => {
							const service: any = this.state.services.find((i: any) => i.id == item.service_id);

							return (
								<div className="col-md-12">
									<div className="item-service">
										<p>{ service?.name }</p>
										<div className="container-service">
											<Input
												type="decimal"
												color="white"
												value={ item.price }
												name="price" 
												label="Precio practicante"
												disabled={ true }
												onChange={ (e: any) => {} } />
											<Input
												type="percentage"
												color="white"
												value={ item.percentage }
												name="percentage" 
												label="% Cobro de App"
												disabled={ true }
												onChange={ (e: any) => {} } />
											<Input
												type="decimal"
												color="white"
												value={ item.price_app }
												name="price_app" 
												label="Precio App"
												disabled={ true }
												onChange={ (e: any) => {} } />
										</div>
									</div>
								</div>
							)
						})
					}
				</div>
			</div>
		)
	}
}

export default ModalServices;