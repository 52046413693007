import React from 'react';
import { connect } from 'react-redux';
import { RootState } from 'reducers';
import { RouteComponentProps, Link } from 'react-router-dom';
import { CleanIcon, PrintIcon, DownloadExcelIcon, CancelIcon, CheckCircleIcon } from 'assets/icons';
import { Globals, Constants, Colors, Print } from 'utils';
import { ReportService } from 'services';
import { WhiteImage } from 'assets/img';
import { Input, Pagination, Table, DatePicker, Tooltip, Select } from 'components';
import moment from 'moment';

interface Props extends RootState, RouteComponentProps {
	dispatch: any
}

const INITIAL_STATE = {
	search: '',
	type: '',
	status: ''
}

class AdminGeneratedCodes extends React.Component<Props> {

	timer: any = undefined;

	state = {
		data: [],
		page: 1,
		last_page: 1,
		header: [
			'Fecha',
			'Código',
			'Plan',
			'Tutor',
			'Mascota',
			'Microchip',
			'Estatus',
			'Acciones'
		],
		form: INITIAL_STATE
	}

	componentDidMount() {
		this.props.dispatch({
			type: 'SET_HEADER',
			payload: {
				title: 'Códigos Generados',
				onBack: null
			}
		});

		this.props.dispatch({
			type: 'SET_SUBHEADER',
			payload: {
				title: ''
			}
		});

		this.load();
	}

	load = async (withoutLoading: boolean = false) => {
		const res: any = await ReportService.admin.getCodes({
			withoutLoading,
			page: this.state.page,
			...this.state.form,
			user_id: this.props.user?.id
		});
		this.setState({
			data: res.codes.rows,
			last_page: res.codes.count
		});
	}

	reset = () => {
		this.setState({
			form: INITIAL_STATE,
			page: 1
		},() => this.load(true));
	}

	change = (e: any,callback = () => {}) => {
		this.setState({
			page: 1,
			form: {
				...this.state.form,
				[e.target.name]: e.target.value
			}
		},callback);
	}

	download = async () => {
		const res: any = await ReportService.admin.printCodes({
			...this.state.form,
			user_id: this.props.user?.id
		});
	  Print(Constants.BASE_STORAGE + res.url);
	}

	changeStatus = async (e: any, item: any, status: number) => {
		e.preventDefault();
		Globals.confirm(status == Constants.GIFT_CARD_STATUS.CANCELLED ? '¿Alunar el código?' : '¿Marcar como vendido?',async () => {
			const res: any = await ReportService.admin.changeStatusCodes({
				card_id: item.id,
				status
			});
		    this.load(true);
		    Globals.showSuccess("Se ha actualizado el estatus del código");
		});
	}

	getColor = (item: any) => {
		let color = '';
		switch(item.status) {
        case Constants.GIFT_CARD_STATUS.PENDING:
          color = Colors.yellow;
        break;

        case Constants.GIFT_CARD_STATUS.SELL:
          color = Colors.blue;
        break;

        case Constants.GIFT_CARD_STATUS.USED:
          color = Colors.green;
        break;

        case Constants.GIFT_CARD_STATUS.CANCELLED:
          color = Colors.danger;
        break;
    }
		return color;
	}
	
	render() {
		return (
			<div id="admin-report-codes">
				<div className="row row-filter">
					<div className="col-md-9 row">
						<div className="col-md-4">
							<Input
								value={ this.state.form.search }
								name="search"
								label="Buscar"
								placeholder="Código/Tutor/Mascota/Microchip"
								className="input-table"
								onChange={ (e: any) => {
									clearTimeout(this.timer);
									this.timer = setTimeout(() => {
										this.load(true);
									},1000);
									this.change(e); 
								} } />
						</div>
						<div className="col-md-4">
							<Select
								placeholder="Seleccionar"
								label="Estatus"
								name="status"
								onChange={ (e: any) => this.change(e,() => this.load(true)) }
								value={ this.state.form.status }
								options={ [
									{ value: Constants.GIFT_CARD_STATUS.PENDING.toString(), label: 'Inactivos' },
									{ value: Constants.GIFT_CARD_STATUS.SELL.toString(), label: 'Activos' },
									{ value: Constants.GIFT_CARD_STATUS.USED.toString(), label: 'Canjeados' },
									{ value: Constants.GIFT_CARD_STATUS.CANCELLED.toString(), label: 'Anulados' },
								] } />
						</div>
					</div>
					<div className="col-md-3">
						{
							this.state.form != INITIAL_STATE && (
								<button className="btn btn-clean" onClick={ () => this.reset() }>
									<img src={ CleanIcon } />
								</button>
							)
						}
					</div>
				</div>

				<Table title="Códigos Generados" data={ this.state.data.length } rightAlign={[ 7 ]} header={ this.state.header } titleRight={
					<React.Fragment>
						{
							this.state.data.length > 0 && (
								<div className="container-add-table">
									<p>Descargar Excel</p>
									<button className="btn add-btn-table" onClick={ this.download }>
										<img src={ DownloadExcelIcon } />
									</button>
								</div>
							)
						}
					</React.Fragment>
				}>
					{ this.state.data.map((i: any,index: number) => {
						return (
							<tr key={ index }>
								<td>{ moment(i.created_at).format('DD/MM/YYYY') }</td>
								<td>{ i.code }</td>
								<td>{ i.plan?.name }</td>
								<td>{ i.pet?.user?.person ? (i.pet?.user?.person?.name + ' ' + i.pet?.user?.person?.lastname) : '-' }</td>
								<td>{ i.pet?.name || '-' }</td>
								<td>{ i.pet?.chip_code || '-' }</td>
								<td>
									<p className="status" style={{
										color: this.getColor(i)
									}}>{ i.status_text }</p>
								</td>
								<td className="vertical-center text-right">
									{
										i.status == Constants.GIFT_CARD_STATUS.PENDING && (
											<Tooltip title="Marcar como vendido">
												<a className="link-icon" href="#" onClick={ (e: any) => this.changeStatus(e,i,Constants.GIFT_CARD_STATUS.SELL) }>
													<img src={ CheckCircleIcon } />
												</a>
											</Tooltip>
										)
									}
									{
										(i.status == Constants.GIFT_CARD_STATUS.PENDING || i.status == Constants.GIFT_CARD_STATUS.SELL) && (
											<Tooltip title="Anular">
												<a className="link-icon" href="#" onClick={ (e: any) => this.changeStatus(e,i,Constants.GIFT_CARD_STATUS.CANCELLED) }>
													<img src={ CancelIcon } />
												</a>
											</Tooltip>
										)
									}
								</td>
							</tr>
						)
					}) }
				</Table>

				<Pagination 
					pages={ this.state.last_page } 
					active={ this.state.page }
					onChange={ async (page: number) => {
						await this.setState({
							page: page
						});
						this.load();
					} } />
			</div>
		)
	}
}

export default connect((state: RootState) => {
	return {
		user: state.user
	}
})(AdminGeneratedCodes);