import React from 'react';
import { connect } from 'react-redux';
import { RootState } from 'reducers';
import { RouteComponentProps, Link } from 'react-router-dom';
import { CleanIcon, PrintIcon } from 'assets/icons';
import { Globals, Constants, Colors, Print } from 'utils';
import { ServiceManagementService } from 'services';
import { WhiteImage } from 'assets/img';
import { Table, Pagination, Input, Select, Tooltip, Modal } from 'components';
import ModalView from './modal-view';

interface Props extends RootState, RouteComponentProps {
	dispatch: any
}

const INITIAL_STATE = {
	search: '',
	service_id: '',
	status: ''
}

class Home extends React.Component<Props> {

	timer: any = undefined;

	state = {
		data: [],
		page: 1,
		last_page: 1,
		header: [
			'Nombre del Paciente',
			'Teléfono del Paciente',
			'Nº Solicitud',
			'Servicio Solicitado',
			'Especialidad o Estudio',
			'Estatus del Servicio',
			'Acciones'
		],
		form: INITIAL_STATE,
		services: [],
		visible_view: false,
		item: null
	}

	componentDidMount() {
		this.props.dispatch({
			type: 'SET_HEADER',
			payload: {
				title: 'Gestión de Servicios',
				onBack: null
			}
		});

		this.props.dispatch({
			type: 'SET_SUBHEADER',
			payload: {
				title: ''
			}
		});

		this.load();
		this.loadServices();
	}

	load = async (withoutLoading: boolean = false) => {
		const res: any = await ServiceManagementService.practicing.get({
			withoutLoading,
			page: this.state.page,
			...this.state.form,
			user_id: this.props.user?.id
		});
		this.setState({
			data: res.services.rows,
			last_page: res.services.count
		});
	}

	loadServices = async () => {
		const res: any = await ServiceManagementService.admin.getServices({
			withoutLoading: true
		});
		this.setState({
			services: res.services,
		});
	}

	change = (e: any,callback = () => {}) => {
		this.setState({
			page: 1,
			form: {
				...this.state.form,
				[e.target.name]: e.target.value
			}
		},callback);
	}

	reset = () => {
		this.setState({
			form: INITIAL_STATE,
			page: 1
		},() => this.load(true));
	}

	print = async (e: any,item: any) => {
		e.preventDefault();
		const res: any = await ServiceManagementService.practicing.print({
			purchased_service_id: item.id
		});
		Print(Constants.BASE_STORAGE + res.url);
	}

	getColor = (item: any) => {
		switch(item.status) {
			case Constants.PURCHASED_SERVICES_STATUS.PENDING:
				return Colors.danger;
			break;

			case Constants.PURCHASED_SERVICES_STATUS.ACTIVE:
				return Colors.danger;
			break;

			case Constants.PURCHASED_SERVICES_STATUS.FINISH:
				return Colors.green;
			break;
		}
	}

	changeStatus = (item: any) => {
		Globals.confirm('¿Desea finalizar el servicio?',async () => {
			await ServiceManagementService.practicing.changeStatus({
				purchased_service_id: item.id
			});
			this.load(true);
		});
	}

	view = (item: any) => {
		this.setState({
			visible_view: true,
			item
		});
	}

	onCloseView = () => {
		this.setState({
			visible_view: false,
			item: null
		});
	}
	
	render() {
		const { visible_view } = this.state;
		const item: any = this.state.item;

		return (
			<div id="practicing-services">
				<Modal
		          className="modal-view-service-practicing"
		          visible={ visible_view }
		          title={ `Solicitud Nº ` + (item?.number || '') }
		          onClose={ () => this.onCloseView() }
		        >
		          <ModalView
		          	item={ this.state.item }
		            onClose={ () => this.onCloseView() } />
		      	</Modal>

				<div className="row row-filter">
					<div className="col-md-3">
						<Input
							value={ this.state.form.search }
							name="search"
							label="Nombre del Paciente"
							placeholder="Buscar"
							className="input-table"
							onChange={ (e: any) => {
								clearTimeout(this.timer);
								this.timer = setTimeout(() => {
									this.load(true);
								},1000);
								this.change(e); 
							} } />
					</div>
					<div className="col-md-3">
						<Select
							placeholder="Seleccionar"
							label="Servicio"
							name="service_id"
							onChange={ (e: any) => this.change(e,() => this.load(true)) }
							value={ this.state.form.service_id }
							options={ this.state.services.map((item: any) => {
								return {
									value: item.id,
									label: item.name
								}
							}) } />
					</div>
					<div className="col-md-3">
						<Select
							placeholder="Seleccionar"
							label="Estatus del Servicio"
							name="status"
							onChange={ (e: any) => this.change(e,() => this.load(true)) }
							value={ this.state.form.status }
							options={ [
								{ value: Constants.PURCHASED_SERVICES_STATUS.ACTIVE.toString(), label: 'Pendientes' },
								{ value: Constants.PURCHASED_SERVICES_STATUS.FINISH.toString(), label: 'Realizados' }
							] } />
					</div>
					<div className="col-md-3">
						{
							this.state.form != INITIAL_STATE && (
								<button className="btn btn-clean" onClick={ () => this.reset() }>
									<img src={ CleanIcon } />
								</button>
							)
						}
					</div>
				</div>

				<Table title="Servicios Solicitados" data={ this.state.data.length } rightAlign={[ 6 ]} header={ this.state.header }>
					{ this.state.data.map((i: any,index: number) => {
						return (
							<tr key={ index }>
								<td>{ i.user?.person?.name + ' ' + i.user?.person?.lastname }</td>
								<td>{ i.user?.person?.phone }</td>
								<td className="number" onClick={ () => this.view(i) }>{ i.number }</td>
								<td>{ i.service?.name }</td>
								<td>{ i.service_speciality?.treatment?.name || i.service_speciality?.medical_study?.name || i.service_speciality?.medical_image?.name || i.service_speciality?.speciality?.name || '-' }</td>
								<td className="status" onClick={ () => {
									if (i.status == Constants.PURCHASED_SERVICES_STATUS.ACTIVE) {
										this.changeStatus(i);
									}
								} } style={{
									color: this.getColor(i),
									cursor: i.status == Constants.PURCHASED_SERVICES_STATUS.ACTIVE ? 'pointer' : 'normal'
								}}>{ i.status_text }</td>
								<td className="vertical-center text-right">
									<Tooltip title="Descargar">
										<a className="link-icon" href="#" onClick={ (e: any) => this.print(e,i) }>
											<img src={ PrintIcon } />
										</a>
									</Tooltip>
								</td>
							</tr>
						)
					}) }
				</Table>

				<Pagination 
					pages={ this.state.last_page } 
					active={ this.state.page }
					onChange={ async (page: number) => {
						await this.setState({
							page: page
						});
						this.load();
					} } />
			</div>
		)
	}
}

export default connect((state: RootState) => {
	return {
		user: state.user
	}
})(Home);