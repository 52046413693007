import React from 'react';
import { Input, Button, PaperClip, Select, CheckboxTable, DatePicker, Modal, Textarea } from 'components';
import { SurgerieService } from 'services';
import { RootState } from 'reducers';
import { RouteComponentProps } from 'react-router-dom';
import { Globals, Constants, Socket, SocketEvents } from 'utils';
import { WhiteImage2 } from 'assets/img';
import moment from 'moment';
import { AddIcon, TrashIcon } from 'assets/icons';
import ModalTreatments from './modal-treatment';

interface Props {
	onClose?: any;
	item?: any;
}

class ModalCreate extends React.Component<Props> {

	state = {
		form: {
			name: '',
			photo: '',
			description: '',
			price: ''
		},
		treatment_selected: [],
		modalities: [],
		treatments: [],
		visible: false
	}

	componentDidMount() {
		if (this.props.item) {
			this.setState({
				form: {
					...this.state.form,
					name: this.props.item?.name || '',
					description: this.props.item?.description || '',
					price: this.props.item?.price || ''
				},
			});
		}
	}

	submit = async (e: any) => {
		e.preventDefault();
		if (this.props.item != null) {
			await SurgerieService.admin.update({
				surgerie_id: this.props.item?.id,
				...this.state.form,
				hasFile: true,
				// @ts-ignore,
				photo: this.state.form.photo?.value || '',
			});
			Globals.showSuccess("Se ha modificado la cirugía/procedimiento correctamente");
			this.props.onClose();
		}
		else {
			await SurgerieService.admin.create({
				...this.state.form,
				hasFile: true,
				// @ts-ignore,
				photo: this.state.form.photo?.value || '',
			});
			Globals.showSuccess("Se ha creado la cirugía/procedimiento correctamente");
			this.props.onClose();
		}
	}

	change = (e: any,callback = () => {}) => {
		this.setState({
			form: {
				...this.state.form,
				[e.target.name]: e.target.value
			}
		},callback);
	}
	
	render() {
		return (
			<div id="modal-create-surgeries">
		      	<form className="row" onSubmit={ this.submit }>
		      		<div className="col-4">
						<div className="container-image">
							<PaperClip
								className="paperclip-image"
								value={ this.state.form.photo }
								width={ 1080 }
								height={ 1080 }
								onChange={ (e: any) => {
									this.setState({
										form: {
											...this.state.form,
											photo: e.target
										}
									})
								} }>
								{/*
								// @ts-ignore */}
								<img className={ `selected-image ${ (this.state.form.photo || this.props.item?.photo) ? '' : 'inactive' }` } src={ this.state.form.photo?.dataURL || (this.props.item?.photo ? (Constants.BASE_STORAGE + this.props.item?.photo) : WhiteImage2) } />
							</PaperClip>
						</div>
					</div>
					<div className="col-8 container-text-image">
						<h3>Subir Imagen</h3>
						<p>Tamaño máximo 1080px por 1080px</p>
					</div>
					<div className="col-md-12">
						<Input
							color="white"
							value={ this.state.form.name }
							name="name" 
							label="Nombre"
							onChange={ this.change } />
					</div>
					<div className="col-md-12">
						<Textarea
							rows={ 3 }
							color="white"
							value={ this.state.form.description }
							name="description" 
							label="Descripción"
							onChange={ this.change } />
					</div>
					<div className="col-md-12">
						<Input
							type="decimal"
							color="white"
							value={ this.state.form.price }
							name="price" 
							label="Costo"
							onChange={ this.change } />
					</div>
					<div className="row row-schedules">
						<div className="row row-buttons">
							<div className="col-md-12 text-center">
								<Button type="submit">
									Guardar
								</Button>
							</div>
						</div>
					</div>
				</form>
			</div>
		)
	}
}

export default ModalCreate;