import React from 'react';
import { connect } from 'react-redux';
import { RootState } from 'reducers';
import { RouteComponentProps, Link } from 'react-router-dom';
import { CleanIcon, PrintIcon, ViewIcon, DownloadExcelIcon } from 'assets/icons';
import { Globals, Constants, Colors, Print } from 'utils';
import { ReportService, MicrochipService } from 'services';
import { WhiteImage } from 'assets/img';
import { Input, Pagination, Table, DatePicker, Tooltip, Select, Modal } from 'components';
import moment from 'moment';

interface Props extends RootState, RouteComponentProps {
	dispatch: any
}

const INITIAL_STATE = {
	since: '',
	until: '',
	search: ''
}

class AdminMicropchip extends React.Component<Props> {

	timer: any = undefined;

	state = {
		data: [],
		page: 1,
		last_page: 1,
		header: [
			'Nº de Cita',
			'Tutor',
			'Mascota',
			'Fecha',
			'Hora',
			'Teléfono'
		],
		form: INITIAL_STATE,
		visible: false,
	}

	componentDidMount() {
		this.props.dispatch({
			type: 'SET_HEADER',
			payload: {
				title: 'Citas de Microchip',
				onBack: null
			}
		});

		this.props.dispatch({
			type: 'SET_SUBHEADER',
			payload: {
				title: ''
			}
		});

		this.load();
	}

	load = async (withoutLoading: boolean = false) => {
		const res: any = await MicrochipService.admin.get({
			withoutLoading,
			page: this.state.page,
			...this.state.form,
		});
		this.setState({
			data: res.quotes.rows,
			last_page: res.quotes.count
		});
	}

	reset = () => {
		this.setState({
			form: INITIAL_STATE,
			page: 1
		},() => this.load(true));
	}

	change = (e: any,callback = () => {}) => {
		this.setState({
			page: 1,
			form: {
				...this.state.form,
				[e.target.name]: e.target.value
			}
		},callback);
	}

	download = async () => {
		const res: any = await MicrochipService.admin.print({
			...this.state.form,
		});
	    Print(Constants.BASE_STORAGE + res.url);
	}
	
	render() {
		const { visible } = this.state;

		return (
			<div id="admin-microchip">
				<div className="row row-filter">
					<div className="col-md-11 row">
						<div className="col-md-4">
							<Input
								value={ this.state.form.search }
								name="search"
								label="Buscar"
								placeholder="Tutor/Mascota/Nº de Cita"
								className="input-table"
								onChange={ (e: any) => {
									clearTimeout(this.timer);
									this.timer = setTimeout(() => {
										this.load(true);
									},1000);
									this.change(e); 
								} } />
						</div>
						<div className="col-md-4">
							<DatePicker
								label="Desde"
								placeholder="Desde"
								onChange={ (text: string) => {
									this.change({
										target: {
											value: text,
											name: 'since'
										}
									},() => this.load(true));
								} }
								value={ this.state.form.since }
							/>
						</div>
						<div className="col-md-4">
							<DatePicker
								label="Hasta"
								placeholder="Hasta"
								onChange={ (text: string) => {
									this.change({
										target: {
											value: text,
											name: 'until'
										}
									},() => this.load(true));
								} }
								value={ this.state.form.until }
							/>
						</div>
					</div>
					<div className="col-md-1">
						{
							this.state.form != INITIAL_STATE && (
								<button className="btn btn-clean" onClick={ () => this.reset() }>
									<img src={ CleanIcon } />
								</button>
							)
						}
					</div>
				</div>

				<Table title="Citas de Microchip" data={ this.state.data.length } rightAlign={[ 7 ]} header={ this.state.header } titleRight={
					<React.Fragment>
						{
							this.state.data.length > 0 && (
								<div className="container-add-table">
									<p>Descargar Excel</p>
									<button className="btn add-btn-table" onClick={ this.download }>
										<img src={ DownloadExcelIcon } />
									</button>
								</div>
							)
						}
					</React.Fragment>
				}>
					{ this.state.data.map((i: any,index: number) => {
						return (
							<tr key={ index }>
								<td>{ i?.number }</td>
								<td>{ i?.pet?.user?.person != null ? (i?.pet?.user?.person?.name + ' ' + i?.pet?.user?.person?.lastname) : '-' }</td>
								<td>{ i?.pet?.name || '-' }</td>
								<td>{ moment(i?.date).format('DD/MM/YYYY') }</td>
								<td>{ moment(i?.hour,'HH:mm:ss').format('hh:mm A') }</td>
								<td>{ i?.pet?.user?.person?.phone }</td>
							</tr>
						)
					}) }
				</Table>

				<Pagination 
					pages={ this.state.last_page } 
					active={ this.state.page }
					onChange={ async (page: number) => {
						await this.setState({
							page: page
						});
						this.load();
					} } />
			</div>
		)
	}
}

export default connect((state: RootState) => {
	return {
		user: state.user
	}
})(AdminMicropchip);