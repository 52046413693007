import { Api } from 'utils';

class SpecialityService {
	
	static admin = {
		get: async (params?: any) => Api.createResponse('admin/specialities/get',params),
		delete: async (params?: any) => Api.createResponse('admin/specialities/delete',params),
		update: async (params?: any) => Api.createResponse('admin/specialities/update',params),
		create: async (params?: any) => Api.createResponse('admin/specialities/create',params)
	}

}

export default SpecialityService;