import React from 'react';
import { connect } from 'react-redux';
import { RootState } from 'reducers';
import { RouteComponentProps, Link } from 'react-router-dom';
import { CleanIcon, PrintIcon } from 'assets/icons';
import { Globals, Constants, Colors, Print } from 'utils';
import { PaymentManagementService } from 'services';
import { WhiteImage } from 'assets/img';
import { Pagination, Table, Input, Select, DatePicker, Tooltip } from 'components';
import moment from 'moment';

interface Props extends RootState, RouteComponentProps {
	dispatch: any
}

const INITIAL_STATE = {
	search: '',
	service_id: '',
	since: '',
	until: ''
}

class Payments extends React.Component<Props> {

	timer: any = undefined;

	state = {
		data: [],
		page: 1,
		last_page: 1,
		header: [
			'Fecha de Servicio',
			'Nombre del Paciente',
			'Servicio Prestado',
			'Código de Solicitud',
			// 'Precio regular',
			'Monto a recibir',
			'Estatus del pago',
			'Acción'
		],
		form: INITIAL_STATE,
		pending: 0,
		paid: 0,
		services: []
	}

	componentDidMount() {
		this.props.dispatch({
			type: 'SET_HEADER',
			payload: {
				title: 'Reporte de Pagos',
				onBack: null
			}
		});

		this.props.dispatch({
			type: 'SET_SUBHEADER',
			payload: {
				title: ''
			}
		});

		this.load();
		this.loadServices();
	}

	load = async (withoutLoading: boolean = false) => {
		const res: any = await PaymentManagementService.practicing.get({
			withoutLoading,
			page: this.state.page,
			...this.state.form,
			since: this.state.form.since && moment(this.state.form.since).format('YYYY-MM-DD'),
			until: this.state.form.until && moment(this.state.form.until).format('YYYY-MM-DD'),
			user_id: this.props.user?.id
		});
		this.setState({
			data: res.services.rows,
			last_page: res.services.count,
			pending: res.pending || 0,
			paid: res.paid || 0
		});
	}

	loadServices = async () => {
		const res: any = await PaymentManagementService.practicing.getServices({
			withoutLoading: true
		});
		this.setState({
			services: res.services
		});
	}

	change = (e: any,callback = () => {}) => {
		this.setState({
			page: 1,
			form: {
				...this.state.form,
				[e.target.name]: e.target.value
			}
		},callback);
	}

	reset = () => {
		this.setState({
			form: INITIAL_STATE,
			page: 1
		},() => this.load(true));
	}

	print = async (e: any, item: any) => {
		e.preventDefault();
		const res: any = await PaymentManagementService.practicing.print({
			service_id: item.id
		});
		Print(Constants.BASE_STORAGE + res.url);
	}

	getColor = (item: any) => {
		switch(item.status_payment) {
			case Constants.PRACTICING_PAYMENT_STATUS.PENDING:
				return Colors.danger;
			break;

			case Constants.PRACTICING_PAYMENT_STATUS.FINISH:
				return Colors.green;
			break;
		}
	}
	
	render() {
		return (
			<div id="practicing-payments">
				<div className="row row-filter">
					<div className="col-md-9 row">
						<div className="col-md-3">
							<Input
								value={ this.state.form.search }
								name="search"
								label="Nombre del Paciente"
								placeholder="Buscar"
								className="input-table"
								onChange={ (e: any) => {
									clearTimeout(this.timer);
									this.timer = setTimeout(() => {
										this.load(true);
									},1000);
									this.change(e); 
								} } />
						</div>
						<div className="col-md-3">
							<Select
								placeholder="Seleccionar"
								label="Servicio"
								name="service_id"
								onChange={ (e: any) => this.change(e,() => this.load(true)) }
								value={ this.state.form.service_id }
								options={ this.state.services.map((item: any) => {
									return {
										value: item.id,
										label: item.name
									}
								}) } />
						</div>
						<div className="col-md-3">
							<DatePicker
								label="Desde"
								placeholder="Desde"
								onChange={ (text: string) => {
									this.change({
										target: {
											value: text,
											name: 'since'
										}
									},() => this.load(true));
								} }
								value={ this.state.form.since }
							/>
						</div>
						<div className="col-md-3">
							<DatePicker
								label="Hasta"
								placeholder="Hasta"
								onChange={ (text: string) => {
									this.change({
										target: {
											value: text,
											name: 'until'
										}
									},() => this.load(true));
								} }
								value={ this.state.form.until }
							/>
						</div>
					</div>
					<div className="col-md-3">
						{
							this.state.form != INITIAL_STATE && (
								<button className="btn btn-clean" onClick={ () => this.reset() }>
									<img src={ CleanIcon } />
								</button>
							)
						}
					</div>
				</div>

				<div className="row row-top-color">
					<div className="col-md-6">
						<div className="item-color-top red">
							<p>Pendiente</p>
							<p className="amount">{ Globals.formatMiles(this.state.pending) }</p>
						</div>
					</div>
					<div className="col-md-6">
						<div className="item-color-top green">
							<p>Pagado</p>
							<p className="amount">{ Globals.formatMiles(this.state.paid) }</p>
						</div>
					</div>
				</div>

				<Table title="Registro de Pagos Sevicios Prestados" data={ this.state.data.length } rightAlign={[ 7 ]} header={ this.state.header }>
					{ this.state.data.map((i: any,index: number) => {
						return (
							<tr key={ index }>
								<td>{ i.created_at ? moment(i.created_at).format('DD/MM/YYYY') : '' }</td>
								<td>{ i.user?.person?.name + ' ' + i.user?.person?.lastname }</td>
								<td>{ i.service?.name }</td>
								<td>{ i.number }</td>
								{/*<td>{ Globals.formatMiles(i.price) }</td>*/}
								<td>{ Globals.formatMiles(i.payment_practicing) }</td>
								<td className="status" style={{
									color: this.getColor(i)
								}}>{ i.status_payment_text }</td>
								<td className="vertical-center text-right">
									<Tooltip title="Descargar">
										<a className="link-icon" href="#" onClick={ (e: any) => this.print(e,i) }>
											<img src={ PrintIcon } />
										</a>
									</Tooltip>
								</td>
							</tr>
						)
					}) }
				</Table>

				<Pagination 
					pages={ this.state.last_page } 
					active={ this.state.page }
					onChange={ async (page: number) => {
						await this.setState({
							page: page
						});
						this.load();
					} } />
			</div>
		)
	}
}

export default connect((state: RootState) => {
	return {
		user: state.user
	}
})(Payments);