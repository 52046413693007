import { Api } from 'utils';

class PracticingService {
	
	static admin = {
		get: async (params?: any) => Api.createResponse('admin/practicing/get',params),
		getServices: async (params?: any) => Api.createResponse('admin/practicing/services',params),
		delete: async (params?: any) => Api.createResponse('admin/practicing/delete',params),
		update: async (params?: any) => Api.createResponse('admin/practicing/update',params),
		create: async (params?: any) => Api.createResponse('admin/practicing/create',params),
		checkValues: async (params?: any) => Api.createResponse('admin/practicing/check-values',params)
	}

}

export default PracticingService;