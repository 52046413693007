import React from 'react';
import { Input, Button, PaperClip, Select, CheckboxTable, DatePicker, Modal, Textarea } from 'components';
import { RootState } from 'reducers';
import { RouteComponentProps } from 'react-router-dom';
import { Globals, Constants, Socket, SocketEvents, Colors } from 'utils';
import moment from 'moment';
import { ProductService } from 'services';
import { DefaultImage } from 'assets/img';

interface Props {
	onClose?: any;
	item?: any;
}

class ModalView extends React.Component<Props> {

	state = {
		product: null,
		image: ''
	}

	componentDidMount() {
		this.load();
	}

	load = async () => {
		const res: any = await ProductService.admin.view({
			product_id: this.props.item?.id
		});
		this.setState({
			product: res.product,
			image: res.product?.images?.length > 0 ? (Constants.BASE_STORAGE + res.product?.images[0].file) : ''
		});
	}
	
	render() {
		const product: any = this.state.product;

		return (
			<div id="modal-view-product">
				<div className="text-center">
					{
						product?.images?.length == 0 && (
							<img src={ DefaultImage } className="image-product" />
						)
					}

					{
						product?.images?.length > 0 && (
							<a href={ this.state.image } target="_blank">
								<img src={ this.state.image } className="image-product" />
							</a>
						)
					}
				</div>

				<div className="container-images">
					{
						product?.images?.map((item: any, index: number) => {
							return (
								<div className="container-image">
									<img src={ Constants.BASE_STORAGE + item?.file } onClick={ () => {
										this.setState({
											image: Constants.BASE_STORAGE + item?.file
										});
									} } />
								</div>
							)
						})
					}
				</div>

				<div className="container-data">
					<p><strong>Nombre:</strong> { product?.name }</p>
					{
						product?.type == Constants.PRODUCT_TYPES.NORMAL && (
							<p><strong>Precio:</strong> { Globals.formatMiles(product?.price) }</p>
						)
					}
					<p><strong>Tipo:</strong> { product?.type_text }</p>
					<p><strong>Categoría:</strong> { product?.category?.name }</p>
					{
						product?.type == Constants.PRODUCT_TYPES.NORMAL && (
							<p><strong>Stock:</strong> { product?.stock }</p>
						)
					}
				</div>

				{
					product?.size_colors?.length > 0 && (
						<div className="row row-size-color">
							{
								product?.size_colors?.map((item: any) => {
									return (
										<div className="col-md-4">
											<div className="item-size-color">
												{
													item?.color != null && (
														<p><strong>Color:</strong> { item?.color?.name }</p>
													)
												}
												{
													item?.size != null && (
														<p><strong>Tamaño:</strong> { item?.size?.name }</p>
													)
												}
												<p><strong>Stock:</strong> { item?.stock }</p>
												<p><strong>Precio:</strong> { Globals.formatMiles(item?.price) }</p>
											</div>
										</div>
									)
								})
							}
						</div>
					)
				}
			</div>
		)
	}
}

export default ModalView;