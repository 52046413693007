import React from 'react';
import { Input, Button, PaperClip, Select, CheckboxTable, DatePicker, Modal, Textarea } from 'components';
import { RootState } from 'reducers';
import { RouteComponentProps } from 'react-router-dom';
import { Globals, Constants, Socket, SocketEvents, Colors } from 'utils';
import { FinancingService } from 'services';
import moment from 'moment';

interface Props {
	onClose?: any;
	item?: any;
}

class ModalViewDocuments extends React.Component<Props> {

	changeStatus = (status: number) => {
		Globals.confirm(`¿Desea ${ status == Constants.FINANCING_STATUS.APPROVED ? 'aprobar' : 'rechazar' } estos documentos?`,async () => {
			await FinancingService.admin.changeDocument({
				document_id: this.props.item?.id,
				status
			});
			this.props.onClose();
		});
	}

	render() {
		const selfie_file = this.props.item?.selfie;
		const document_file = this.props.item?.document;

		return (
			<div id="modal-view-financing">
				<div className="container-dates">
					<p><strong>Fecha:</strong> { moment(this.props.item?.created_at).format('DD/MM/YYYY') }</p>
					<p><strong>Nombre:</strong> { this.props.item?.user?.person?.name + ' ' + this.props.item?.user?.person?.lastname }</p>
					<p><strong>Correo:</strong> { this.props.item?.user?.email }</p>
				</div>
				<div className="row row-buttons row-buttons-doc">
					{
						document_file != null && (
							<div className="col-md-12">
				      			<a href={ Constants.BASE_STORAGE + document_file } target="_blank">
					      			<Button className="btn-doc" type="button">
										Ver foto de documento
									</Button>
								</a>
				      		</div>
						)
					}
		      		{
		      			selfie_file != null && (
		      				<div className="col-md-12">
				      			<a href={ Constants.BASE_STORAGE + selfie_file } target="_blank">
					      			<Button className="btn-doc" type="button">
										Ver Selfie
									</Button>
								</a>
				      		</div>
		      			)
		      		}
		      	</div>
		      	{
		      		this.props.item?.status == Constants.FINANCING_STATUS.PENDING && (
		      			<div className="row row-buttons">
				      		<div className="col-md-6">
				      			<Button className="btn-reject" type="button" onClick={ () => this.changeStatus(Constants.FINANCING_STATUS.REJECTED) }>
									Rechazar
								</Button>
				      		</div>
				      		<div className="col-md-6">
				      			<Button className="btn-accept" type="button" onClick={ () => this.changeStatus(Constants.FINANCING_STATUS.APPROVED) }>
									Aprobar
								</Button>
				      		</div>
				      	</div>
		      		)
		      	}
			</div>
		)
	}
}

export default ModalViewDocuments;