import { Api } from 'utils';

class SurgerieService {
	
	static admin = {
		get: async (params?: any) => Api.createResponse('admin/surgeries/get',params),
		getModalities: async (params?: any) => Api.createResponse('admin/surgeries/modalities',params),
		create: async (params?: any) => Api.createResponse('admin/surgeries/create',params),
		update: async (params?: any) => Api.createResponse('admin/surgeries/update',params),
		delete: async (params?: any) => Api.createResponse('admin/surgeries/delete',params)
	}

}

export default SurgerieService;