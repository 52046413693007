import React from 'react';
import { Input, Button } from 'components';
import { connect } from 'react-redux';
import { AuthService } from 'services';
import { RootState } from 'reducers';
import { RouteComponentProps } from 'react-router';
import { BgLogin } from 'assets/img';
import { ArrowLeft } from 'assets/icons';
import { Link } from 'react-router-dom';
import { Globals } from 'utils';

interface Props extends RootState, RouteComponentProps {
	dispatch: any
}

class Recover extends React.Component<Props> {

	state = {
		email: ''
	}

	componentDidMount() {
		if (this.props.user) {
			this.props.history.replace('/');
		}

		this.props.dispatch({
			type: 'SET_HEADER',
			payload: {
				title: 'Recuperación de Contraseña',
				onBack: null
			}
		});
	}

	submit = async (e: any) => {
		e.preventDefault();
		await AuthService.recover({
			email: this.state.email
		});
		Globals.showSuccess("Se le ha enviado un correo electrónico");
		this.props.history.push('/login');
	}
	
	render() {
		return (
			<div id="recover">
				<div className="row container-form">
					<div className="col-lg-6 container-bg" style={{ backgroundImage: `url(${ BgLogin })` }}></div>
					<div className="col-lg-6" onSubmit={ this.submit }>
						<div className="row row-title">
							<div className="col-2">
								<Link to="/login">
									<img src={ ArrowLeft } className="arrow-left-green" />
								</Link>
							</div>
							<div className="col-8">
								<h3 className="title-green">Recuperar Contraseña</h3>
							</div>
						</div>
						<form className="container-input">
							<p>Ingrese su correo electrónico para iniciar la recuperación de su contraseña</p>
							<Input
								type="text" 
								name="email"
								onChange={ (e: any) => {
									this.setState({
										email: e.target.value
									});
								} } />
							<div className="text-center">
								<Button type="submit" disabled={ this.state.email == '' || this.state.email == null }>
									Enviar
								</Button>
							</div>
						</form>
					</div>
				</div>
			</div>
		)
	}
}

export default connect((state: RootState) => {
	return {
		user: state.user
	}
})(Recover);