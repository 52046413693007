import React from 'react';
import { connect } from 'react-redux';
import { RootState } from 'reducers';
import { RouteComponentProps, Link } from 'react-router-dom';
import { CleanIcon, PrintIcon, PasswordActive } from 'assets/icons';
import { Globals, Constants, Colors, Print, checkPermission } from 'utils';
import { FinancingService } from 'services';
import { WhiteImage } from 'assets/img';
import { Table, Pagination, Input, Select, Tooltip, Modal } from 'components';
import moment from 'moment';
import ModalViewDocuments from './modal-view-documents';

interface Props extends RootState, RouteComponentProps {
	dispatch: any
}

const INITIAL_STATE = {
	search: '',
	status: ''
}

class AdminDocumentVerification extends React.Component<Props> {

	timer: any = undefined;

	state = {
		data: [],
		page: 1,
		last_page: 1,
		header: [
			'Fecha',
			'Nombre',
			'Correo',
			'Estatus',
			'Acción'
		],
		form: INITIAL_STATE,
		visible: false,
		item: null
	}

	componentDidMount() {
		this.props.dispatch({
			type: 'SET_HEADER',
			payload: {
				title: 'Verificación de Documentos',
				onBack: null
			}
		});

		this.props.dispatch({
			type: 'SET_SUBHEADER',
			payload: {
				title: ''
			}
		});

		this.load();
	}

	load = async (withoutLoading: boolean = false) => {
		const res: any = await FinancingService.admin.getDocuments({
			withoutLoading,
			page: this.state.page,
			...this.state.form,
		});
		this.setState({
			data: res.documents.rows,
			last_page: res.documents.count
		});
	}

	change = (e: any,callback = () => {}) => {
		this.setState({
			page: 1,
			form: {
				...this.state.form,
				[e.target.name]: e.target.value
			}
		},callback);
	}

	reset = () => {
		this.setState({
			form: INITIAL_STATE,
			page: 1
		},() => this.load(true));
	}

	onClose = () => {
		this.setState({
			visible: false
		},() => this.load(true));
	}

	openView = (e: any, item: any) => {
		e.preventDefault();
		this.setState({
			visible: true,
			item
		});
	}
	
	render() {
		const { visible } = this.state;

		return (
			<div id="admin-financing">
				<Modal
		          className="modal-view-financing"
		          visible={ visible }
		          title="Ver Documentos"
		          onClose={ () => this.onClose() }
		        >
		          <ModalViewDocuments
		          	item={ this.state.item }
		            onClose={ () => this.onClose() } />
		      	</Modal>

				<div className="row row-filter">
					<div className="col-md-3">
						<Input
							value={ this.state.form.search }
							name="search"
							label="Nombre del Usuario"
							placeholder="Buscar"
							className="input-table"
							onChange={ (e: any) => {
								clearTimeout(this.timer);
								this.timer = setTimeout(() => {
									this.load(true);
								},1000);
								this.change(e); 
							} } />
					</div>
					<div className="col-md-3">
						<Select
							placeholder="Seleccionar"
							label="Estatus"
							name="status"
							onChange={ (e: any) => this.change(e,() => this.load(true)) }
							value={ this.state.form.status }
							options={ [
								{ value: Constants.FINANCING_STATUS.PENDING.toString(), label: 'Por Validar' },
								{ value: Constants.FINANCING_STATUS.APPROVED.toString(), label: 'Aprobados' },
								{ value: Constants.FINANCING_STATUS.REJECTED.toString(), label: 'Rechazados' },
							] } />
					</div>
					<div className="col-md-3">
						{
							this.state.form != INITIAL_STATE && (
								<button className="btn btn-clean" onClick={ () => this.reset() }>
									<img src={ CleanIcon } />
								</button>
							)
						}
					</div>
				</div>

				<Table title="Verificación de Documentos" data={ this.state.data.length } rightAlign={[ 4 ]} header={ this.state.header }>
					{ this.state.data.map((i: any,index: number) => {
						return (
							<tr key={ index }>
								<td>{ moment(i.created_at).format('DD/MM/YYYY') }</td>
								<td>{ i.user?.person?.name + ' ' + i.user?.person?.lastname }</td>
								<td>{ i.user?.email }</td>
								<td>{ i.status_text }</td>
								<td className="vertical-center text-right" style={{ whiteSpace: 'nowrap' }}>
									<Tooltip title="Ver">
										<a className="link-icon link-view" href="#" onClick={ (e: any) => this.openView(e,i) }>
											<img src={ PasswordActive } />
										</a>
									</Tooltip>
								</td>
							</tr>
						)
					}) }
				</Table>

				<Pagination 
					pages={ this.state.last_page } 
					active={ this.state.page }
					onChange={ async (page: number) => {
						await this.setState({
							page: page
						});
						this.load();
					} } />
			</div>
		)
	}
}

export default connect((state: RootState) => {
	return {
		user: state.user
	}
})(AdminDocumentVerification);