import { Api } from 'utils';

class MedicalStudyService {
	
	static admin = {
		get: async (params?: any) => Api.createResponse('admin/medical-studies/get',params),
		delete: async (params?: any) => Api.createResponse('admin/medical-studies/delete',params),
		update: async (params?: any) => Api.createResponse('admin/medical-studies/update',params),
		create: async (params?: any) => Api.createResponse('admin/medical-studies/create',params)
	}

}

export default MedicalStudyService;