import React from 'react';
import { connect } from 'react-redux';
import { RootState } from 'reducers';
import { RouteComponentProps, Link } from 'react-router-dom';
import { CleanIcon, PrintIcon, ViewIcon } from 'assets/icons';
import { Globals, Constants, Colors, Print } from 'utils';
import { ReportService } from 'services';
import { WhiteImage } from 'assets/img';
import { Input, Pagination, Table, DatePicker, Tooltip, Select, Modal } from 'components';
import moment from 'moment';
import ModalView from './modal-view-payment';

interface Props extends RootState, RouteComponentProps {
	dispatch: any
}

const INITIAL_STATE = {
	since: '',
	until: '',
	payment_method_id: '',
	status: ''
}

class AdminPaymentReport extends React.Component<Props> {

	timer: any = undefined;

	state = {
		data: [],
		page: 1,
		last_page: 1,
		header: [
			'Fecha',
			'Método de Pago',
			'Usuario',
			'Mascota',
			'Monto',
			'Motivo',
			'Estatus',
			'Acciones'
		],
		form: INITIAL_STATE,
		visible: false,
		item: null,
		payment_methods: []
	}

	componentDidMount() {
		this.props.dispatch({
			type: 'SET_HEADER',
			payload: {
				title: 'Reporte de Pagos',
				onBack: null
			}
		});

		this.props.dispatch({
			type: 'SET_SUBHEADER',
			payload: {
				title: ''
			}
		});

		this.load();
	}

	load = async (withoutLoading: boolean = false) => {
		const res: any = await ReportService.admin.getPayments({
			withoutLoading,
			page: this.state.page,
			...this.state.form,
		});
		this.setState({
			data: res.payments.rows,
			last_page: res.payments.count,
			payment_methods: res.payment_methods || []
		});
	}

	reset = () => {
		this.setState({
			form: INITIAL_STATE,
			page: 1
		},() => this.load(true));
	}

	change = (e: any,callback = () => {}) => {
		this.setState({
			page: 1,
			form: {
				...this.state.form,
				[e.target.name]: e.target.value
			}
		},callback);
	}

	onCloseView = () => {
		this.setState({
			visible: false
		},() => this.load(true));
	}

	view = (e: any, item: any) => {
		e.preventDefault();
		this.setState({
			visible: true,
			item
		});
	}

	print = async (e: any, item: any) => {
		e.preventDefault();
		const res: any = await ReportService.admin.printPayment({
			payment_id: item.id
		});
		Print(Constants.BASE_STORAGE + res.url);
	}

	getColor = (item: any) => {
	    let color = '';

	    switch(item.status) {
	      case Constants.PAYMENT_REFERENCES.PENDING:
	        color = Colors.yellow;
	      break;

	      case Constants.PAYMENT_REFERENCES.APPROVED:
	        color = Colors.green;
	      break;

	      case Constants.PAYMENT_REFERENCES.REJECTED:
	        color = Colors.danger;
	      break;
	    }

	    return color;
	}
	
	render() {
		const { visible } = this.state;

		return (
			<div id="admin-report-payments">
				<Modal
		          className="modal-view-payment"
		          visible={ visible }
		          title="Ver Pago"
		          onClose={ () => this.onCloseView() }
		        >
		          <ModalView
		          	item={ this.state.item }
		            onClose={ () => this.onCloseView() } />
		      	</Modal>

				<div className="row row-filter">
					<div className="col-md-11 row">
						<div className="col-md-3">
							<DatePicker
								label="Desde"
								placeholder="Desde"
								onChange={ (text: string) => {
									this.change({
										target: {
											value: text,
											name: 'since'
										}
									},() => this.load(true));
								} }
								value={ this.state.form.since }
							/>
						</div>
						<div className="col-md-3">
							<DatePicker
								label="Hasta"
								placeholder="Hasta"
								onChange={ (text: string) => {
									this.change({
										target: {
											value: text,
											name: 'until'
										}
									},() => this.load(true));
								} }
								value={ this.state.form.until }
							/>
						</div>
						<div className="col-md-3">
							<Select
								placeholder="Seleccionar"
								label="Método de Pago"
								name="payment_method_id"
								onChange={ (e: any) => this.change(e,() => this.load(true)) }
								value={ this.state.form.payment_method_id }
								options={ this.state.payment_methods.map((item: any) => {
									return {
										value: item.id,
										label: item.name
									}
								}) } />
						</div>
						<div className="col-md-3">
							<Select
								placeholder="Seleccionar"
								label="Estatus"
								name="status"
								onChange={ (e: any) => this.change(e,() => this.load(true)) }
								value={ this.state.form.status }
								options={ [
									{ value: Constants.PAYMENT_REFERENCES.PENDING.toString(), label: 'Por Validar' },
									{ value: Constants.PAYMENT_REFERENCES.APPROVED.toString(), label: 'Aprobados' },
									{ value: Constants.PAYMENT_REFERENCES.REJECTED.toString(), label: 'Rechazados' },
								] } />
						</div>
					</div>
					<div className="col-md-1">
						{
							this.state.form != INITIAL_STATE && (
								<button className="btn btn-clean" onClick={ () => this.reset() }>
									<img src={ CleanIcon } />
								</button>
							)
						}
					</div>
				</div>

				<Table title="Reporte de Pagos" data={ this.state.data.length } rightAlign={[ 7 ]} header={ this.state.header }>
					{ this.state.data.map((i: any,index: number) => {
						return (
							<tr key={ index }>
								<td>{ moment(i.created_at).format('DD/MM/YYYY') }</td>
								<td>{ i?.payment_method?.name }</td>
								<td>{ i?.user?.person != null ? (i?.user?.person?.name + ' ' + i?.user?.person?.lastname) : '' }</td>
								<td>{ i?.pet?.name || '-' }</td>
								<td>{ Globals.formatMiles(i.amount) }</td>
								<td>{ i?.reason_text }</td>
								<td style={{ color: this.getColor(i) }} className="status">{ i?.status_text }</td>
								<td className="vertical-center text-right" style={{ whiteSpace: 'nowrap' }}>
									<Tooltip title="Ver">
										<a className="link-icon" href="#" onClick={ (e: any) => this.view(e,i) }>
											<img src={ ViewIcon } />
										</a>
									</Tooltip>
									<Tooltip title="Descargar">
										<a className="link-icon" href="#" onClick={ (e: any) => this.print(e,i) }>
											<img src={ PrintIcon } />
										</a>
									</Tooltip>
								</td>
							</tr>
						)
					}) }
				</Table>

				<Pagination 
					pages={ this.state.last_page } 
					active={ this.state.page }
					onChange={ async (page: number) => {
						await this.setState({
							page: page
						});
						this.load();
					} } />
			</div>
		)
	}
}

export default connect((state: RootState) => {
	return {
		user: state.user
	}
})(AdminPaymentReport);