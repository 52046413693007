import React from 'react';
import { connect } from 'react-redux';
import { RootState } from 'reducers';
import { RouteComponentProps, Link } from 'react-router-dom';
import { CleanIcon, EditIcon, DeleteIcon, PasswordActive, AssignIcon } from 'assets/icons';
import { Globals, Constants, Colors, checkPermission, Permissions } from 'utils';
import { ServiceManagementService } from 'services';
import { WhiteImage } from 'assets/img';
import { Table, Pagination, Input, Select, Tooltip, Button, Modal } from 'components';
import ModalSearchPatient from './modal-search-patient';
import ModalView from './modal-view';

interface Props extends RootState, RouteComponentProps {
	dispatch: any
}

const INITIAL_STATE = {
	search: '',
	practicing: '',
	service_id: '',
	health_center_id: ''
}

class AdminServiceManagment extends React.Component<Props> {

	timer: any = undefined;

	state = {
		data: [],
		page: 1,
		last_page: 1,
		header: [
			'Mascota',
			'Microchip',
			'Tutor',
			'Servicio Solicitado',
			'Unidad',
			'Nombre del Practicante',
			'Estatus del Servicio',
			'Formulario',
			'Acciones'
		],
		form: INITIAL_STATE,
		services: [],
		health_centers: [],
		visible: false,
		service: null,
		visible_view: false,
		item: null
	}

	componentDidMount() {
		this.props.dispatch({
			type: 'SET_HEADER',
			payload: {
				title: 'Gestión Servicios',
				onBack: null
			}
		});

		this.props.dispatch({
			type: 'SET_SUBHEADER',
			payload: {
				title: ''
			}
		});

		this.load();
		this.loadServices();
	}

	load = async (withoutLoading: boolean = false) => {
		const res: any = await ServiceManagementService.admin.get({
			withoutLoading,
			page: this.state.page,
			...this.state.form,
		});
		this.setState({
			data: res.services.rows,
			last_page: res.services.count
		});
	}

	loadServices = async () => {
		const res: any = await ServiceManagementService.admin.getServices({
			withoutLoading: true
		});
		this.setState({
			services: res.services,
			health_centers: res.health_centers
		});
	}

	reset = () => {
		this.setState({
			form: INITIAL_STATE,
			page: 1
		},() => this.load(true));
	}

	change = (e: any,callback = () => {}) => {
		this.setState({
			page: 1,
			form: {
				...this.state.form,
				[e.target.name]: e.target.value
			}
		},callback);
	}

	searchPatient = () => {
		this.setState({
			visible: true
		});
	}

	onClose = () => {
		this.setState({
			visible: false,
			service: null
		},() => this.load(true));
	}

	delete = async (e: any, item: any) => {
		e.preventDefault();
		Globals.confirm('¿Desea eliminar el servicio?',async () => {
			await ServiceManagementService.admin.delete({
				purchased_service_id: item.id
			});
			this.load(true);
		});
	}

	edit = (e: any, item: any) => {
		e.preventDefault();
		this.setState({
			service: item,
			visible: true
		});
	}

	getColor = (item: any) => {
		switch(item.status) {
			case Constants.PURCHASED_SERVICES_STATUS.PENDING:
				return Colors.danger;
			break;

			case Constants.PURCHASED_SERVICES_STATUS.ACTIVE:
				return Colors.danger;
			break;

			case Constants.PURCHASED_SERVICES_STATUS.FINISH:
				return Colors.green;
			break;
		}
	}

	setService = (service: any) => {
		this.setState({
			service
		});
	}

	view = (e: any, item: any) => {
		e.preventDefault();
		this.setState({
			visible_view: true,
			item
		});
	}

	assign = (e: any, item: any) => {
		e.preventDefault();
		this.setState({
			service: item,
			visible: true
		});
	}

	onCloseView = () => {
		this.setState({
			visible_view: false,
			item: null
		});
	}

	changeStatus = (item: any) => {
		Globals.confirm('¿Desea finalizar el servicio?',async () => {
			await ServiceManagementService.practicing.changeStatus({
				purchased_service_id: item.id
			});
			this.load(true);
		});
	}
	
	render() {
		const { visible, visible_view } = this.state;
		const service: any = this.state.service;

		return (
			<div id="admin-service-management">
				<Modal
		          className="modal-search-patient"
		          visible={ visible }
		          title={ service != null ? 'Solicitud de Servicio' : 'Buscar mascota' }
		          onClose={ () => this.onClose() }
		        >
		          <ModalSearchPatient
		          	service={ this.state.service }
		          	setService={ this.setService }
		            onClose={ () => this.onClose() } />
		      	</Modal>

		      	<Modal
		          className="modal-view-service-management"
		          visible={ visible_view }
		          title="Formulario"
		          onClose={ () => this.onCloseView() }
		        >
		          <ModalView
		          	item={ this.state.item }
		            onClose={ () => this.onCloseView() } />
		      	</Modal>

				<div className="row row-filter">
					<div className="row col-md-12">
						<div className="col-md-5">
							<Input
								value={ this.state.form.search }
								name="search"
								label="Filtrar"
								placeholder="Buscar por mascota/tutor/microchip"
								className="input-table"
								onChange={ (e: any) => {
									clearTimeout(this.timer);
									this.timer = setTimeout(() => {
										this.load(true);
									},1000);
									this.change(e); 
								} } />
						</div>
						<div className="col-md-5">
							<Input
								value={ this.state.form.practicing }
								name="practicing"
								label="Practicante"
								placeholder="Buscar"
								className="input-table"
								onChange={ (e: any) => {
									clearTimeout(this.timer);
									this.timer = setTimeout(() => {
										this.load(true);
									},1000);
									this.change(e); 
								} } />
						</div>
						<div className="col-md-4">
							<Select
								placeholder="Seleccionar"
								label="Servicio"
								name="service_id"
								onChange={ (e: any) => this.change(e,() => this.load(true)) }
								value={ this.state.form.service_id }
								options={ this.state.services.map((item: any) => {
									return {
										value: item.id,
										label: item.name
									}
								}) } />
						</div>
						<div className="col-md-4">
							<Select
								placeholder="Seleccionar"
								label="Unidad"
								name="health_center_id"
								onChange={ (e: any) => this.change(e,() => this.load(true)) }
								value={ this.state.form.health_center_id }
								options={ this.state.health_centers.map((item: any) => {
									return {
										value: item.id,
										label: item.name
									}
								}) } />
						</div>
						<div className="col-md-3">
							{
								this.state.form != INITIAL_STATE && (
									<button className="btn btn-clean" onClick={ () => this.reset() }>
										<img src={ CleanIcon } />
									</button>
								)
							}
						</div>
					</div>
				</div>

				<Table title="Servicios Solicitados" data={ this.state.data.length } rightAlign={[ 8 ]} header={ this.state.header } titleRight={
					<React.Fragment>
						{
							checkPermission(Constants.MODULES.SERVICE_MANAGEMENT,'write',this.props.user) && (
								<Button className="btn-add" onClick={ () => this.searchPatient() }>
									Buscar mascota
								</Button>
							)
						}
					</React.Fragment>
				}>
					{ this.state.data.map((i: any,index: number) => {
						return (
							<tr key={ index }>
								<td>{ i.pet?.name }</td>
								<td>{ i.pet?.chip_code || '-' }</td>
								<td>{ i.user?.person?.name + ' ' + i.user?.person?.lastname }</td>
								<td>
									<p className="service-name">{ i.service?.name }</p>
									{ (i?.service_speciality?.treatment != null || i?.service_speciality?.medical_study != null || i?.service_speciality?.medical_image != null) && <p className="speciality-name">{ i?.service_speciality?.treatment?.name || i?.service_speciality?.medical_study?.name || i?.service_speciality?.medical_image?.name }</p> }
								</td>
								<td>{ i.health_center?.name || '-' }</td>
								<td>{ i.practicing?.person ? (i.practicing?.person?.name + ' ' + i.practicing?.person?.lastname) : '-' }</td>
								<td className="status" onClick={ () => {
									if (i.status == Constants.PURCHASED_SERVICES_STATUS.ACTIVE) {
										this.changeStatus(i);
									}
								} } style={{
									color: this.getColor(i),
									cursor: i.status == Constants.PURCHASED_SERVICES_STATUS.ACTIVE ? 'pointer' : 'normal'
								}}>{ i.status_text }</td>
								<td>
									{
										i.is_quote ? (
											<Tooltip title="Ver">
												<a className="link-icon" href="#" onClick={ (e: any) => this.view(e,i) }>
													<img src={ PasswordActive } />
												</a>
											</Tooltip>
										) : (
											<React.Fragment>-</React.Fragment>
										)
									}
								</td>
								<td className="vertical-center text-right">
									{
										i.status == Constants.PURCHASED_SERVICES_STATUS.ACTIVE && checkPermission(Constants.MODULES.SERVICE_MANAGEMENT,'write',this.props.user) && (
											<Tooltip title="Editar">
												<a className="link-icon" href="#" onClick={ (e: any) => this.edit(e,i) }>
													<img src={ EditIcon } />
												</a>
											</Tooltip>
										)
									}
									{
										i.status == Constants.PURCHASED_SERVICES_STATUS.PENDING && checkPermission(Constants.MODULES.SERVICE_MANAGEMENT,'write',this.props.user) && (
											<Tooltip title="Asignar">
												<a className="link-icon" href="#" onClick={ (e: any) => this.assign(e,i) }>
													<img src={ AssignIcon } />
												</a>
											</Tooltip>
										)
									}
									{
										checkPermission(Constants.MODULES.SERVICE_MANAGEMENT,'delete',this.props.user) && (
											<Tooltip title="Eliminar">
												<a className="link-icon" href="#" onClick={ (e: any) => this.delete(e,i) }>
													<img src={ DeleteIcon } />
												</a>
											</Tooltip>
										)
									}
								</td>
							</tr>
						)
					}) }
				</Table>

				<Pagination 
					pages={ this.state.last_page } 
					active={ this.state.page }
					onChange={ async (page: number) => {
						await this.setState({
							page: page
						});
						this.load();
					} } />
			</div>
		)
	}
}

export default connect((state: RootState) => {
	return {
		user: state.user
	}
})(AdminServiceManagment);