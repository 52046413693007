import React from 'react';
import { connect } from 'react-redux';
import { RootState } from 'reducers';
import { RouteComponentProps, Link } from 'react-router-dom';
import { CleanIcon, PrintIcon, PasswordActive, AddIcon, EditIcon, DownloadExcelIcon, CheckCircleIcon, CancelIcon } from 'assets/icons';
import { Globals, Constants, Colors, Print, checkPermission } from 'utils';
import { WhiteImage } from 'assets/img';
import { Table, Pagination, Input, Select, Tooltip, Modal, DatePicker, Button } from 'components';
import moment from 'moment';
import { OrderService } from 'services';
import ModalView from './modal-view';

interface Props extends RootState, RouteComponentProps {
	dispatch: any
}

const INITIAL_STATE = {
	search: '',
	status: ''
}

class AdminOrders extends React.Component<Props> {

	timer: any = undefined;

	state = {
		data: [],
		page: 1,
		last_page: 1,
		header: [
			'Nº',
			'Usuario',
			'Productos',
			'Total',
			'Estatus',
			'Acciones'
		],
		form: INITIAL_STATE,
		visible: false,
		item: null
	}

	componentDidMount() {
		this.props.dispatch({
			type: 'SET_HEADER',
			payload: {
				title: 'Ordenes',
				onBack: null
			}
		});

		this.props.dispatch({
			type: 'SET_SUBHEADER',
			payload: {
				title: ''
			}
		});

		this.load();
	}

	load = async (withoutLoading: boolean = false) => {
		const res: any = await OrderService.admin.get({
			withoutLoading,
			page: this.state.page,
			...this.state.form,
		});
		this.setState({
			data: res.orders.rows,
			last_page: res.orders.count
		});
	}

	change = (e: any,callback = () => {}) => {
		this.setState({
			page: 1,
			form: {
				...this.state.form,
				[e.target.name]: e.target.value
			}
		},callback);
	}

	reset = () => {
		this.setState({
			form: INITIAL_STATE,
			page: 1
		},() => this.load(true));
	}

	download = async () => {
		const res: any = await OrderService.admin.print({
			...this.state.form,
		});
	    Print(Constants.BASE_STORAGE + res.url);
	}

	view = (e: any, item: any) => {
		e.preventDefault();
		this.setState({
			visible: true,
			item
		});
	}

	onClose = () => {
		this.setState({
			visible: false,
			item: null
		},() => this.load(true));
	}

	getColor = (item: any) => {
		let color = '';

		switch(item?.status) {
			case Constants.ORDERS_STATUS.PENDING:
				color = Colors.yellow;
			break;

			case Constants.ORDERS_STATUS.APPROVED:
				color = Colors.blue;
			break;

			case Constants.ORDERS_STATUS.FINISH:
				color = Colors.green;
			break;

			case Constants.ORDERS_STATUS.REJECTED:
				color = Colors.danger;
			break;
		}

		return color;
	}

	changeStatus = async (e: any, item: any, status: number) => {
		e.preventDefault();
		let message = '';

		switch(status) {
			case Constants.ORDERS_STATUS.APPROVED:
				message = '¿Desea APROBAR el pedido?';
			break;

			case Constants.ORDERS_STATUS.FINISH:
				message = '¿Desea FINALIZAR el pedido?';
			break;

			case Constants.ORDERS_STATUS.REJECTED:
				message = '¿Desea RECHAZAR el pedido?';
			break;
		}

		Globals.confirm(message,async () => {
			await OrderService.admin.changeStatus({
				order_id: item?.id,
				status
			});
			this.load(true);
			Globals.showSuccess("Se ha cambiado el estatus del pedido correctamente");
		});
	}

	render() {
		const { visible } = this.state;

		return (
			<div id="admin-orders">
				<Modal
		          className="modal-view-order"
		          visible={ visible }
		          title="Ver Pedido"
		          onClose={ () => this.onClose() }
		        >
		          <ModalView
		          	item={ this.state.item }
		            onClose={ () => this.onClose() } />
		      	</Modal>

				<div className="row row-filter">
					<div className="col-md-9 row">
						<div className="col-md-6">
							<Input
								value={ this.state.form.search }
								name="search"
								label="Nº de Pedido"
								placeholder="Buscar"
								className="input-table"
								onChange={ (e: any) => {
									clearTimeout(this.timer);
									this.timer = setTimeout(() => {
										this.load(true);
									},1000);
									this.change(e); 
								} } />
						</div>
						<div className="col-md-6">
							<Select
								placeholder="Seleccionar"
								label="Estatus"
								name="status"
								onChange={ (e: any) => this.change(e,() => this.load(true)) }
								value={ this.state.form.status }
								options={ [
									{ value: Constants.ORDERS_STATUS.PENDING.toString(), label: 'Pendientes' },
									{ value: Constants.ORDERS_STATUS.APPROVED.toString(), label: 'Aceptados' },
									{ value: Constants.ORDERS_STATUS.FINISH.toString(), label: 'Finalizados' },
									{ value: Constants.ORDERS_STATUS.REJECTED.toString(), label: 'Rechazados' },
								] } />
						</div>
					</div>
					<div className="col-md-3">
						{
							this.state.form != INITIAL_STATE && (
								<button className="btn btn-clean" onClick={ () => this.reset() }>
									<img src={ CleanIcon } />
								</button>
							)
						}
					</div>
				</div>

				<Table title="Ordenes" data={ this.state.data.length } rightAlign={[ 5 ]} header={ this.state.header } titleRight={
					<React.Fragment>
						{
							this.state.data.length > 0 && (
								<div className="container-add-table">
									<p>Descargar Excel</p>
									<button className="btn add-btn-table" onClick={ this.download }>
										<img src={ DownloadExcelIcon } />
									</button>
								</div>
							)
						}
					</React.Fragment>
				}>
					{ this.state.data.map((i: any,index: number) => {
						return (
							<tr key={ index }>
								<td>{ i.number }</td>
								<td>{ i.user?.person != null ? (i.user?.person?.name + ' ' + i.user?.person?.lastname) : '' }</td>
								<td>{ i.details?.length }</td>
								<td>{ Globals.formatMiles(i.total) }</td>
								<td className="status" style={{
									color: this.getColor(i)
								}}>{ i.status_text }</td>
								<td className="vertical-center text-right" style={{ whiteSpace: 'nowrap' }}>
									<Tooltip title="Ver">
										<a className="link-icon link-view" href="#" onClick={ (e: any) => this.view(e,i) }>
											<img src={ PasswordActive } />
										</a>
									</Tooltip>
									{
										i.status == Constants.ORDERS_STATUS.PENDING && (
											<>
												<Tooltip title="Aprobar">
													<a className="link-icon link-view" href="#" onClick={ (e: any) => this.changeStatus(e,i,Constants.ORDERS_STATUS.APPROVED) }>
														<img src={ CheckCircleIcon } />
													</a>
												</Tooltip>
												<Tooltip title="Rechazar">
													<a className="link-icon link-view" href="#" onClick={ (e: any) => this.changeStatus(e,i,Constants.ORDERS_STATUS.REJECTED) }>
														<img src={ CancelIcon } />
													</a>
												</Tooltip>
											</>
										)
									}
									{
										i.status == Constants.ORDERS_STATUS.APPROVED && (
											<Tooltip title="Finalizar">
												<a className="link-icon link-view" href="#" onClick={ (e: any) => this.changeStatus(e,i,Constants.ORDERS_STATUS.FINISH) }>
													<img src={ CheckCircleIcon } />
												</a>
											</Tooltip>
										)
									}
								</td>
							</tr>
						)
					}) }
				</Table>

				<Pagination 
					pages={ this.state.last_page } 
					active={ this.state.page }
					onChange={ async (page: number) => {
						await this.setState({
							page: page
						});
						this.load();
					} } />
			</div>
		)
	}
}

export default connect((state: RootState) => {
	return {
		user: state.user
	}
})(AdminOrders);