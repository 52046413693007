import { Api } from 'utils';

class ServiceService {
	
	static admin = {
		get: async (params?: any) => Api.createResponse('admin/services/get',params),
		getSpecialities: async (params?: any) => Api.createResponse('admin/services/specialities',params),
		create: async (params?: any) => Api.createResponse('admin/services/create',params),
		update: async (params?: any) => Api.createResponse('admin/services/update',params),
		delete: async (params?: any) => Api.createResponse('admin/services/delete',params)
	}
}

export default ServiceService;