import React from 'react';
import { connect } from 'react-redux';
import { RootState } from 'reducers';
import { RouteComponentProps, Link } from 'react-router-dom';
import { DeleteIcon, EditIcon, AddIcon, CleanIcon } from 'assets/icons';
import { Globals, Constants, Colors, checkPermission, Permissions } from 'utils';
import { ConversionService } from 'services';
import { WhiteImage } from 'assets/img';
import { Tooltip, Button, Table, Pagination, Modal, Input } from 'components';

interface Props extends RootState, RouteComponentProps {
	dispatch: any
}

const INITIAL_STATE = {
	search: ''
}

class Conversion extends React.Component<Props> {

	state = {
		form: {
			amount: ''
		}
	}

	componentDidMount() {
		this.props.dispatch({
			type: 'SET_HEADER',
			payload: {
				title: 'Tasa de Cambio',
				onBack: null
			}
		});

		this.props.dispatch({
			type: 'SET_SUBHEADER',
			payload: {
				title: ''
			}
		});

		this.load();
	}

	load = async (withoutLoading: boolean = false) => {
		const res: any = await ConversionService.admin.get();
		this.setState({
			form: {
				...this.state.form,
				amount: res.conversion?.amount || ''
			}
		});
	}

	submit = async (e: any) => {
		e.preventDefault();
		await ConversionService.admin.save({
			amount: this.state.form.amount
		});
		Globals.showSuccess("Se ha guardado la tasa de cambio correctamente");
	}
	
	change = (e: any,callback = () => {}) => {
		this.setState({
			page: 1,
			form: {
				...this.state.form,
				[e.target.name]: e.target.value
			}
		},callback);
	}

	render() {
		return (
			<div id="admin-conversion">
				<form onSubmit={ this.submit }>
					<Input
						type="decimal"
						color="white"
						value={ this.state.form.amount }
						name="amount"
						label="Indique la tasa de conversión Bs. por dolar" 
						placeholder="$0.00"
						onChange={ this.change } />
					<div className="text-center">
						<Button type="submit">
							Guardar
						</Button>
					</div>
				</form>
			</div>
		)
	}
}

export default connect()(Conversion);