import React from 'react';
import { Input, Button, PaperClip, Select, CheckboxTable, DatePicker, Modal, Textarea } from 'components';
import { RootState } from 'reducers';
import { RouteComponentProps } from 'react-router-dom';
import { Globals, Constants, Socket, SocketEvents, Colors } from 'utils';
import moment from 'moment';
import { CleanIcon } from 'assets/icons';
import { CardService } from 'services';

interface Props {
	onClose?: any;
	item?: any;
}

const INITIAL_STATE = {
	search: ''
}

class ModalView extends React.Component<Props> {

	timer: any = undefined;

	state = {
		cards: [],
		form: INITIAL_STATE
	}

	componentDidMount() {
		this.load();
	}

	change = (e: any,callback = () => {}) => {
		this.setState({
			form: {
				...this.state.form,
				[e.target.name]: e.target.value
			}
		},callback);
	}

	load = async (withoutLoading = false) => {
		const res: any = await CardService.admin.view({
			lot_id: this.props.item?.id,
			withoutLoading,
			...this.state.form
		});
		this.setState({
			cards: res.cards || []
		});
	}

	reset = () => {
		this.setState({
			form: INITIAL_STATE,
			page: 1
		},() => this.load(true));
	}
	
	render() {
		return (
			<div id="modal-view-cards">
				<div className="container-search">
					<div className="row">
						<div className="col-md-6">
							<Input
								value={ this.state.form.search }
								name="search"
								label="Buscar código"
								placeholder="Buscar"
								className="input-table"
								onChange={ (e: any) => {
									clearTimeout(this.timer);
									this.timer = setTimeout(() => {
										this.load(true);
									},1000);
									this.change(e); 
								} } />
						</div>
						<div className="col-md-6">
							{
								this.state.form != INITIAL_STATE && (
									<button className="btn btn-clean" onClick={ () => this.reset() }>
										<img src={ CleanIcon } />
									</button>
								)
							}
						</div>
					</div>
				</div>
				<div className="row">
					{
						this.state.cards.map((item: any) => {
							return (
								<div className="col-md-4">
									<p>{ item.code }</p>
								</div>
							)
						})
					}
				</div>
			</div>
		)
	}
}

export default ModalView;